import React, { useEffect, useState } from 'react';
import { useFormik, FormikProvider, FieldArray } from "formik";
import * as Yup from "yup";
import { useParams } from 'react-router-dom';
import {
    Button
    , TextField
    , Autocomplete
    , FormLabel,
    Typography,
    Card,
    Divider,
    CircularProgress,
    Box
} from '@mui/material';
import * as CommonMethods from "../../../../utills/CommonMethods";
import { AUTO_COMPLETE_CONFIG } from '../../../../utills/constants/FormFieldFormats';
import * as API from '../../../../utills/API';
import { IoCloseSharp } from "react-icons/io5";
// import { RiStarFill, RiStarLine } from "react-icons/ri";
import { confirmation_dialog, simple_dialog, status_dialog } from "../../../../utills/Alerts";
import * as Messages from '../../../../utills/constants/Messages';
import { MdArrowBack } from 'react-icons/md';
import useFormData from '../../../../utills/hooks/useFormData';
import useDeviceProperties from '../../../../utills/hooks/useDeviceProperties';
import EventEmitter from '../../../../utills/EventEmitter';

function RolesToBeConsideredForm(props) {
    const formData = useFormData();
    let deviceProperties = useDeviceProperties();

    const BUTTON_VALUE = props?.id ? 'Shortlist' : 'Submit';
    const considered_role_form = useFormik({
        initialValues: {
            _id: null,
            considered_roles_List: [],
            role: null,
        },
        validationSchema: Yup.object().shape({
            _id: Yup.string().nullable()
            , considered_roles_List: Yup.array().nullable()
                .min(1, "Please add at least one role")
            , role: Yup.mixed().nullable()
                .when('considered_roles_List', {
                    is: considered_roles_List => considered_roles_List.length === 0,
                    then: Yup.mixed().required(Messages?.REQUIRED_FIELD_ERROR)
                })
        }),
        onSubmit: (values) => {
            setLoading(true);
            let event_info = CommonMethods.getEventInfo(formData.form_id, formData.base_path, BUTTON_VALUE);
            let payLoad = values?.considered_roles_List
            confirmation_dialog(Messages?.confirmation_messages?.considered_roles_submit, () => {
                API.PostMethod('additionalDetails/rolesToBeConsidered/' + (params?.id ? params?.id : props.id), payLoad, event_info).then((res) => {
                    let status_details = {
                        ...CommonMethods.STATUS_POPUP_DETAILS.success,
                        message: res.message,
                    };
                    status_dialog(status_details, () => {
                        props.onChange(true);
                        EventEmitter.emit('refreshHeader', { refresh: true })
                    });

                    // simple_dialog(res.message, () => {
                    //     props.onChange(true);
                    //     EventEmitter.emit('refreshHeader', { refresh: true })
                    // }
                    // );
                }).catch((error)=>{setLoading(false)})
            },null,null,()=>{setLoading(false);})
        },
    });
    const [rolesList, setRolesList] = useState([])
    const [selectedRole, setSelectedRole] = useState(null);
    const [is_already_selected, setIsAlreadySelected] = useState(false);
    const [loading, setLoading] = useState(false);
    const params = useParams();

    useEffect(() => {
        considered_role_form.setFieldValue('_id', (params?.id ? params?.id : props.id));
        fetchConsideredRoles();
        fetchDesignationsList();
    }, [])
    function fetchConsideredRoles() {
        API.GetMethod('candidateDetails/rolesToBeConsidered/' + (params?.id ? params?.id : props.id)).then((resp) => {
            let roles_to_be_considered = CommonMethods.getProperArrayFormat(CommonMethods.isNullOrUndefined(resp?.data[0]?.roles_to_be_considered) ? [] : resp?.data[0]?.roles_to_be_considered);
            considered_role_form.setFieldValue('considered_roles_List', roles_to_be_considered);
        }, (error) => {
            considered_role_form.setFieldValue('considered_roles_List', [])
        })
    }
    function fetchDesignationsList() {
        // if (!CommonMethods.isNullOrUndefined(searchKey) && searchKey !== '') {
        API.GetMethod('designation').then(result => {
            result.data.designations = CommonMethods.customSort(result.data.designations,'role_name','asc'); 
            setRolesList(result.data.designations);
        }, (error) => {
            setRolesList([])
        });
        // }
        // else {
        //     setRolesList([])
        // }
    }
    return (
        <div className="d-flex mer-width-percentage-100 flex-grow-1 mer-overflow">
            <form className='d-grid flex-grow-1 mer-form' onSubmit={considered_role_form.handleSubmit}>
                <div className='d-flex flex-column flex-grow-1'>
                    <Card className="d-flex flex-column">
                        {params?.id ?
                            <div className="d-flex align-items-center mer-margin-top-16 mer-margin-left-16">
                                <MdArrowBack
                                    className="mer-width-20 mer-height-20 mer-cursor-pointer mer-margin-right-8"
                                    onClick={() => {
                                        props.onChange();
                                    }}
                                />
                                <Typography variant="h6">Edit Role to be considered</Typography>
                            </div> : null}
                        <Card className='d-flex flex-row align-items-center mer-margin-16 secondary'>
                            <FormikProvider value={considered_role_form}>
                                <FieldArray name="considered_roles_List">
                                    {({ insert, remove, push }) => (
                                        <div className='d-flex flex-column flex-grow-1'>
                                            <div className='d-flex flex-column'>
                                                <div className='mer-font-bold mer-margin-bottom-12'>Role to be considered</div>
                                                <div className='d-flex col-12 flex-wrap align-items-start'>
                                                    <div className={params?.id ?'d-flex col-md-6 col-lg-4 col-sm-12 col-xs-12 col-12' : 'd-flex col-md-8 col-lg-6 col-sm-12 col-xs-12 col-12'}>
                                                        <div className='d-flex flex-grow-1 flex-column mer-margin-right-8 mer-margin-bottom-8'>
                                                            <FormLabel id='considered-role'>Add Role<sup className="mer-color-red">*</sup></FormLabel>
                                                            <Autocomplete
                                                                name='role'
                                                                aria-labelledby='considered-role'
                                                                className='mer-theme-auto-specialIconalign mer-autoComplete'
                                                                disablePortal disableClearable
                                                                // onInputChange={(e) => {
                                                                //     fetchDesignationsList(e?.target?.value)
                                                                //     setIsAlreadySelected(false)
                                                                // }}
                                                                {...AUTO_COMPLETE_CONFIG.OUTLINED}
                                                                options={!CommonMethods.isNullOrUndefined(rolesList) && rolesList.length > 0 ? rolesList : []}
                                                                value={selectedRole}
                                                                // noOptionsText={rolesList?.length === 0 && (CommonMethods.isNullOrUndefined(considered_role_form.values?.role) || considered_role_form.values?.role === '') ? 'Type in considered role' : 'No data found'}
                                                                // getOptionLabel={(option) => (option.designation_name + ' - ' + option.sub_department_name)}
                                                                getOptionLabel={(option) => (option.role_name)}
                                                                renderOption={(props, option) => {
                                                                    return (
                                                                        <li {...props} key={option['_id']}>
                                                                            <div className="d-flex flex-column">
                                                                                <div>{option['role_name']}</div>
                                                                                {/* <div>{option['designation_name']}&nbsp;-&nbsp;{option['sub_department_name']}</div> */}
                                                                            </div>
                                                                        </li>
                                                                    );
                                                                }}
                                                                key={option => option._id}
                                                                onChange={
                                                                    (event, value) => {
                                                                        setIsAlreadySelected(false)
                                                                        setSelectedRole(value);
                                                                        considered_role_form.setFieldTouched('role', true, true);
                                                                        considered_role_form.setFieldValue('role', value);
                                                                    }
                                                                }
                                                                renderInput={(params) =>
                                                                    <TextField name='role'

                                                                        // value={considered_role_form.values.role}
                                                                        {...params} placeholder="Search or type role"
                                                                        error={(considered_role_form.touched.role && considered_role_form.errors.role) ? true : false}
                                                                        // onChange={considered_role_form.handleChange}
                                                                        onBlur={considered_role_form.handleBlur}
                                                                    />}
                                                            />
                                                            <div className='mer-error'>{considered_role_form.touched.role && considered_role_form.errors.role ?
                                                                considered_role_form.errors.role : null}</div>
                                                            <div className='mer-error'>{considered_role_form.touched.considered_roles_List && considered_role_form.errors.considered_roles_List ?
                                                                considered_role_form.errors.considered_roles_List : null}</div>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex flex-grow-1 justify-content-end'>
                                                        <Button variant="contained" className='mer-margin-left-12 mer-margin-top-18'
                                                            disabled={CommonMethods.isNullOrUndefined(considered_role_form.values.role) || (typeof (considered_role_form.values.role) !== 'object')}
                                                            onClick={() => {
                                                                let filtered_roles_list = considered_role_form.values.considered_roles_List.filter((role) => {
                                                                    return role.role_id === considered_role_form.values.role._id
                                                                })
                                                                if (filtered_roles_list.length === 0) {
                                                                    setIsAlreadySelected(false);
                                                                    push({
                                                                        role_id: considered_role_form.values.role._id,
                                                                        role_name: considered_role_form.values.role.role_name,
                                                                        //  sub_department_name: considered_role_form.values.role.sub_department_name 
                                                                    });
                                                                    setSelectedRole(null);
                                                                    considered_role_form.setFieldValue('role', null);
                                                                    considered_role_form.setFieldTouched('role', false, false);
                                                                }
                                                                else {
                                                                    setIsAlreadySelected(true);
                                                                }
                                                            }}
                                                        > Add </Button>
                                                    </div>
                                                </div>
                                                <div className='mer-error'>{is_already_selected ? 'Selected role is already added' : null}</div>
                                            </div>
                                            <Divider />
                                            <div className='d-flex flex-row flex-wrap'>
                                                {considered_role_form.values.considered_roles_List?.length > 0 &&
                                                    considered_role_form.values.considered_roles_List.map(
                                                        (role, index) => (
                                                            <Card key={index} className='d-flex flex-row align-items-center mer-margin-right-8 mer-margin-bottom-8 mer-margin-left-0 mer-margin-top-0 mer-padding-6 chipsTheme'>
                                                                {/* <div>{role.role_name + (role.sub_department_name ? (' - ' + role.sub_department_name) : '')}</div> */}
                                                                <div>{role.role_name}</div>
                                                                <IoCloseSharp className="mer-width-20 mer-height-20 mer-margin-left-8 mer-cursor-pointer" onClick={() => { remove(index) }} />
                                                            </Card>
                                                        ))}
                                            </div>
                                        </div>
                                    )}
                                </FieldArray>
                            </FormikProvider>
                        </Card>
                        {/* <fieldset className='mer-margin-bottom-8 form-fieldset'>
                            <div>{JSON.stringify(considered_role_form.values, null, 4)}</div>
                        </fieldset> */}
                    </Card>
                </div>
                <div className={deviceProperties.isMobileDevice ? 'd-flex align-self-end  flex-column mer-padding-16'
                    : 'd-flex justify-content-between align-items-center align-self-end  mer-padding-16'}>
                    <div className="mer-color-red mer-margin-8">
                        Note: All the fields marked as ‘*’ are mandatory fields.
                    </div>
                    <div className='d-flex justify-content-end'>
                        <Button variant="outlined" className='mer-margin-right-8 outlinedcls'
                            onClick={() => { props.onChange(false) }} disabled={loading}>Discard</Button>
                        <Button variant="contained" className='' type="submit">
                           {!loading ? 
                             (BUTTON_VALUE) : 
                             (
                               <Box className="d-flex">
                               <CircularProgress size="1.3rem"/>
                               </Box>
                             )}
                        </Button>
                    </div>
                </div>
            </form >
        </div >
    )
}

export default RolesToBeConsideredForm
