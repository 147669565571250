import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { SideMenuContext } from "../../App";
import {
  isNullOrUndefined,
  removeEmptyObjectFromArray,
} from "../../utills/CommonMethods";
import * as sideMenuService from "../../utills/constants/SideMenu";
import useDeviceProperties from "./useDeviceProperties";

const useSideMenu = () => {
  const { sideMenu, setSideMenu } = useContext(SideMenuContext);
  const location = useLocation();
  let deviceProperties = useDeviceProperties();

  const updateSideMenu = (
    side_menu_api_params,
    url_params,
    is_candidate_details,
    callback
  ) => {
    updateSMenu(side_menu_api_params, is_candidate_details);

    async function updateSMenu(side_menu_api_params, is_candidate_details) {
      
      
      let side_menu_response = await sideMenuService.GetForms(
        side_menu_api_params
      );
      let disabled_forms = [];
      if (is_candidate_details) {
        disabled_forms = await sideMenuService.GetDisabledForms(url_params);
      }

      if (isNullOrUndefined(disabled_forms)) {
        disabled_forms = [];
      }

      let disabled_form_ids = disabled_forms.map((form) => form["_id"]);

      let side_menu_data =
        side_menu_response["data"].length > 0 ? side_menu_response["data"] : [];

      let forms = removeEmptyObjectFromArray(side_menu_data);

      for (let form of forms) {
        let disabled_form_index = disabled_form_ids.indexOf(form["form_id"]);
        form["isDisabled"] = disabled_form_index > -1 ? true : false;

        if (!isNullOrUndefined(form.children)) {
          for (let sub_form of form.children) {
            let disabled_subform_index = disabled_form_ids.indexOf(
              sub_form["form_id"]
            );
            sub_form["isDisabled"] = disabled_subform_index > -1 ? true : false;
            for (let key in url_params) {
              if (!isNullOrUndefined(sub_form.path)) {
                sub_form.path = sub_form.path.replace(
                  `:${key}`,
                  url_params[key]
                );

                if (
                  location.pathname.toString().startsWith(sub_form.base_path)
                ) {
                  form["expanded"] = true;
                }
              } else {
                sub_form.path = "";
              }
            }
          }
        }

        for (let key in url_params) {
          if (!isNullOrUndefined(form.path)) {
            form.path = form.path.replace(`:${key}`, url_params[key]);
          } else {
            form.path = "";
          }
        }
      }

      let sideMenuItems = forms.length > 0 ? forms : [];

      setSideMenu({
        open:
          sideMenuItems.length > 0 && !deviceProperties.isMobileDevice
            ? true
            : false,
        sideMenuItems: sideMenuItems,
      });
      if (!isNullOrUndefined(callback)) {
        callback({
          open:
            sideMenuItems.length > 0 && !deviceProperties.isMobileDevice
              ? true
              : false,
          sideMenuItems: sideMenuItems,
        });
      }
    }
  };



  return { updateSideMenu };
};
export default useSideMenu;
