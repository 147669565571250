import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import PageAccessGuard from "./components/guards/PageAccessGuard";
import AuthenticatedGuard from "./components/guards/AuthenticatedGuard";
import ReferralAuthenticationGuard from "./components/guards/ReferralAuthenticationGuard";
import Loading from "./components/Loading";
import SourcedProfilesTable from "./pages/candidates/SourcedProfilesTable";
import GlobalSettingsView from "./pages/configuration/global-settings/GlobalSettingsView";
import CandidateAccessGaurd from "./components/guards/CandidateAccessGaurd";
import Pincodes from "./pages/configuration/pincodes/Pincodes";
import PincodeForm from "./pages/configuration/pincodes/PincodeForm";
import PincodeView from "./pages/configuration/pincodes/PincodeView";
import CandidateReferral from "./pages/candidate-referral/CandidateReferral";
import CandidateReferralForm from "./pages/candidate-referral/CandidateReferralForm";
const CandidateFacing = React.lazy(() =>
  import("./pages/candidate-facing/CandidateFacing")
);
const CandidateFacingRouting = React.lazy(() =>
  import("./pages/candidate-facing/components/CandidateFacingRouting")
);
const RegistrationStepper = React.lazy(() =>
  import(
    "./pages/candidate-facing/components/registration-components/RegistrationStepper"
  )
);
const WebRegistrationStepper = React.lazy(() =>
  import(
    "./pages/candidate-facing/components/web-register/WebRegistrationStepper"
  )
);
const CandidateFacingDocuments = React.lazy(() =>
  import(
    "./pages/candidate-facing/components/documents-upload/CandidateFacingDocuments"
  )
);
const Login = React.lazy(() => import("./pages/Login"));
const Profile = React.lazy(() => import("./pages/candidates/Profile/Profile"));
const Configuration = React.lazy(() =>
  import("./pages/configuration/Configuration")
);
const Users = React.lazy(() => import("./pages/configuration/users/Users"));
const UserForm = React.lazy(() =>
  import("./pages/configuration/users/UserForm")
);
const UserRoles = React.lazy(() =>
  import("./pages/configuration/userRoles/UserRoles")
);
const SMTPView = React.lazy(() =>
  import("./pages/configuration/global-settings/GlobalSettingsView")
);
const EmailsTemplates = React.lazy(() =>
  import("./pages/configuration/templates/EmailsTemplates")
);
const Recruitee = React.lazy(() => import("./pages/Recruitee"));
const EmailTemplatesForm = React.lazy(() =>
  import("./pages/configuration/templates/EmailTemplatesForm")
);
const UserRoleForm = React.lazy(() =>
  import("./pages/configuration/userRoles/UserRoleForm")
);
const DomainForm = React.lazy(() =>
  import("./pages/configuration/domains/DomainForm")
);
const Domains = React.lazy(() =>
  import("./pages/configuration/domains/Domains")
);
const DesignationForm = React.lazy(() =>
  import("./pages/configuration/designations/DesignationForm")
);
const Designations = React.lazy(() =>
  import("./pages/configuration/designations/Designations")
);
const Candidates = React.lazy(() => import("./pages/candidates/Candidates"));
const Archive = React.lazy(() => import("./pages/archive/Archive"));
const IsLoginGuard = React.lazy(() =>
  import("./components/guards/IsLoginGuard")
);
const Departments = React.lazy(() =>
  import("./pages/configuration/departments/Departments")
);
const DepartmentForm = React.lazy(() =>
  import("./pages/configuration/departments/DepartmentForm")
);
const SubDepartments = React.lazy(() =>
  import("./pages/configuration/sub-departments/SubDepartments")
);
const SubDepartmentForm = React.lazy(() =>
  import("./pages/configuration/sub-departments/SubDepartmentForm")
);
const ResumeSources = React.lazy(() =>
  import("./pages/configuration/resume-sources/ResumeSources")
);
const ResumeSourceForm = React.lazy(() =>
  import("./pages/configuration/resume-sources/ResumeSourceForm")
);
const Colleges = React.lazy(() =>
  import("./pages/configuration/colleges/Colleges")
);
const CollegeForm = React.lazy(() =>
  import("./pages/configuration/colleges/CollegeForm")
);
const Degrees = React.lazy(() =>
  import("./pages/configuration/degrees/Degrees")
);
const EntranceExams = React.lazy(() =>
  import("./pages/configuration/entrance-exams/EntranceExams")
);
const EntranceExamsView = React.lazy(() =>
  import("./pages/configuration/entrance-exams/EntranceExamsView")
);
const EntranceExamsForm = React.lazy(() =>
  import("./pages/configuration/entrance-exams/EntranceExamsForm")
);
const DegreeForm = React.lazy(() =>
  import("./pages/configuration/degrees/DegreeForm")
);
const Branches = React.lazy(() =>
  import("./pages/configuration/branches/Branches")
);
const BranchForm = React.lazy(() =>
  import("./pages/configuration/branches/BranchForm")
);
const BasicDetailsForm = React.lazy(() =>
  import("./pages/candidates/BasicDetailsForm")
);
// const AdditionalDetailsForm = React.lazy(() => import('./pages/candidates/AdditionalDetailsForm'));
const Summary = React.lazy(() => import("./pages/candidates/Summary"));
const Timeline = React.lazy(() =>
  import("./pages/candidates/Profile/Timeline")
);
const Comments = React.lazy(() =>
  import("./pages/candidates/interviews/my-comments/Comments")
);
const Proposal = React.lazy(() =>
  import("./pages/candidates/proposal/Proposal")
);
const OnePager = React.lazy(() =>
  import("./pages/candidates/one-pager/OnePager")
);
const PersonalDetails = React.lazy(() =>
  import("./pages/candidates/Profile/personal-details/PersonalDetails")
);
const BGVDetails = React.lazy(() =>
  import("./pages/candidates/Profile/bgv/BGVDetails")
);
const Skills = React.lazy(() =>
  import("./pages/candidates/Profile/skills/Skills")
);
const Documents = React.lazy(() =>
  import("./pages/candidates/documents/Documents")
);
const Preferences = React.lazy(() =>
  import("./pages/candidates/preferences/Preferences")
);
const Review = React.lazy(() => import("./pages/candidates/review/Review"));
const OnlineTests = React.lazy(() =>
  import("./pages/candidates/review/OnlineTests")
);
const Interviews = React.lazy(() =>
  import("./pages/candidates/review/Interviews")
);
const HistoryStepper = React.lazy(() =>
  import("./pages/candidates/Profile/history/History_stepper")
);
const Events = React.lazy(() => import("./pages/configuration/events/Events"));
const EventsForm = React.lazy(() =>
  import("./pages/configuration/events/EventForm")
);
const Stages = React.lazy(() => import("./pages/configuration/stages/Stages"));
const StageForm = React.lazy(() =>
  import("./pages/configuration/stages/StageForm")
);
const Status = React.lazy(() => import("./pages/configuration/status/Status"));
const StatusForm = React.lazy(() =>
  import("./pages/configuration/status/StatusForm")
);
const RegistrationDetails = React.lazy(() =>
  import("./pages/candidates/registration-details/RegistrationDetails")
);
const Tests = React.lazy(() => import("./pages/candidates/tests/Tests"));
const ScheduleInterviews = React.lazy(() =>
  import("./pages/candidates/interviews/schedule-interviews/ScheduleInterview")
);
const CandidateRouting = React.lazy(() =>
  import("./pages/candidates/CandidateRouting")
);
const LoggedInProfile = React.lazy(() => import("./pages/LoggedInProfile"));
const CommentsForm = React.lazy(() =>
  import("./pages/candidates/interviews/my-comments/CommentsForm")
);
// const WebsiteRegistrationForm = React.lazy(() => import('./pages/website-registration/WebsiteRegistrationForm'));
const Campus = React.lazy(() => import("./pages/campus/Campus"));
const Home = React.lazy(() => import("./pages/home/Home"));
const Analytics = React.lazy(() => import("./pages/analytics/Analytics"));
const AccessDeniedPage = React.lazy(() =>
  import("./components/AccessDeniedPage")
);
const SearchPage = React.lazy(() => import("./components/SearchPage"));
const CandidateRegistration = React.lazy(() =>
  import("./pages/candidate-registration/CandidateRegistration")
);
const RolesToBeConsidered = React.lazy(() =>
  import(
    "./pages/candidates/interviews/role-to-be-considered/RolesToBeConsidered"
  )
);
const UserRoleView = React.lazy(() =>
  import("./pages/configuration/userRoles/UserRoleView")
);
const DomainView = React.lazy(() =>
  import("./pages/configuration/domains/DomainView")
);
const DepartmentView = React.lazy(() =>
  import("./pages/configuration/departments/DepartmentView")
);
const SubDepartmentView = React.lazy(() =>
  import("./pages/configuration/sub-departments/SubDepartmentView")
);
const DesignationView = React.lazy(() =>
  import("./pages/configuration/designations/DesignationView")
);
const ResumeSourceView = React.lazy(() =>
  import("./pages/configuration/resume-sources/ResumeSourceView")
);
const CollegeView = React.lazy(() =>
  import("./pages/configuration/colleges/CollegeView")
);
const DegreeView = React.lazy(() =>
  import("./pages/configuration/degrees/DegreeView")
);
const BranchView = React.lazy(() =>
  import("./pages/configuration/branches/BranchView")
);
const StageView = React.lazy(() =>
  import("./pages/configuration/stages/StageView")
);
const ViewAllComments = React.lazy(() =>
  import("./pages/candidates/interviews/ViewAllComments")
);
const StatusView = React.lazy(() =>
  import("./pages/configuration/status/StatusView")
);
const EventView = React.lazy(() =>
  import("./pages/configuration/events/EventView")
);
const EmailTemplateView = React.lazy(() =>
  import("./pages/configuration/templates/EmailTemplateView")
);
const CandidatesBulkUploadDragDrop = React.lazy(() =>
  import("./pages/candidate-registration/CandidatesBulkUploadDragDrop")
);
const UserView = React.lazy(() =>
  import("./pages/configuration/users/UserView")
);
const Cases = React.lazy(() => import("./pages/configuration/cases/Cases"));
const CaseView = React.lazy(() =>
  import("./pages/configuration/cases/CaseView")
);
const CaseForm = React.lazy(() =>
  import("./pages/configuration/cases/CaseForm")
);
const Actions = React.lazy(() =>
  import("./pages/configuration/actions/Actions")
);
const ActionView = React.lazy(() =>
  import("./pages/configuration/actions/ActionView")
);
const ActionForm = React.lazy(() =>
  import("./pages/configuration/actions/ActionForm")
);
const SourcedProfileRegistrationDetails = React.lazy(() =>
  import(
    "./pages/candidates/sourced-profile-view-screens/SourcedProfileRegistrationDetails"
  )
);
const SourcedProfileBasicDetails = React.lazy(() =>
  import(
    "./pages/candidates/sourced-profile-view-screens/SourcedProfileBasicDetails"
  )
);
const SourcedProfileAdditionalDetails = React.lazy(() =>
  import(
    "./pages/candidates/sourced-profile-view-screens/SourcedProfileAdditionalDetails"
  )
);
const InterviewerCandidates = React.lazy(() =>
  import("./pages/interviewer-screens/Candidates")
);
const InterviewsBulkUpload = React.lazy(() =>
  import("./pages/InterviewsBulkUpload")
);
const Tags = React.lazy(() => import("./pages/configuration/tags/Tags"));
const TagForm = React.lazy(() => import("./pages/configuration/tags/TagForm"));
const TagView = React.lazy(() => import("./pages/configuration/tags/TagView"));

function Routing() {
  return (
    <React.Suspense fallback={<Loading />}>
      <Routes>
        {/* <Route path="/register" element={<WebsiteRegistrationForm />} /> */}

        {/* referal */}
        <Route exact path="referral" element={<Navigate to="login" />} />
        <Route path="referral/login" element={<Login />} />

        <Route exact path="" element={<Navigate to="/login" />} />
        <Route element={<IsLoginGuard />}>
          <Route path="/login" element={<Login />} />
        </Route>
        <Route element={<AuthenticatedGuard />}>
          <Route path="recruitee" element={<Recruitee />}>
            <Route exact path="" element={<Navigate to="candidates" />} />
            <Route path="access-denied" element={<AccessDeniedPage />} />
            <Route path="home" element={<Home />} />
            <Route path="candidates/:stage_id" element={<Candidates />} />
            <Route path="campus" element={<Campus />} />
            <Route path="analytics" element={<Analytics />} />
            <Route path="profile" element={<LoggedInProfile />} />
            <Route path="globalsearch" element={<SearchPage />} />

            <Route element={<PageAccessGuard />}>
              <Route
                path="sourced-profiles"
                element={<SourcedProfilesTable />}
              />
              <Route
                path="view-registration-details/:id"
                element={<SourcedProfileRegistrationDetails />}
              />
              <Route
                path="view-basic-details/:id"
                element={<SourcedProfileBasicDetails />}
              />
              <Route
                path="view-additional-details/:id"
                element={<SourcedProfileAdditionalDetails />}
              />
              <Route path="one-pager/:id" element={<OnePager />}></Route>
              <Route path="candidates" element={<Candidates />} />
              <Route
                path="interviewer/candidates"
                element={<InterviewerCandidates />}
              />
              <Route
                path="interviewer/candidates/:stage_id"
                element={<InterviewerCandidates />}
              />
              <Route path="archive" element={<Archive />} />
              <Route path="archive/:tab_id" element={<Archive />} />
              <Route path="registration" element={<CandidateRegistration />} />
              <Route
                path="bulk-upload/:type"
                element={<CandidatesBulkUploadDragDrop />}
              />
              <Route
                path="bulk-upload/interviews"
                element={<InterviewsBulkUpload />}
              />
              <Route path="candidate" element={<CandidateAccessGaurd />}>
                <Route path="basic-details" element={<BasicDetailsForm />} />
                <Route
                  path="basic-details/:_id"
                  element={<BasicDetailsForm />}
                />
                {/* <Route path='additional-details/:_id' element={<AdditionalDetailsForm />} /> */}
                <Route path="summary/:id" element={<Summary />} />
                <Route
                  path="registration-details"
                  element={<RegistrationDetails />}
                />
                <Route
                  path="registration-details/:id"
                  element={<RegistrationDetails />}
                />
                <Route path="one-pager/:id" element={<OnePager />}></Route>
                <Route path="tests/:id" element={<Tests />} />
                <Route path="tests/:test_id/:id" element={<Tests />} />
                <Route
                  path="schedule-interviews/:id"
                  element={<ScheduleInterviews />}
                />
                <Route
                  path="schedule-interviews/:interview_id/:id"
                  element={<ScheduleInterviews />}
                />
                <Route
                  path="roles-to-be-considered/:id"
                  element={<RolesToBeConsidered />}
                />
                <Route path="documents/:id" element={<Documents />} />
                <Route path="time-line/:id" element={<Timeline />} />
                <Route path="comments/:id" element={<Comments />} />
                <Route
                  path="comments-form/:id/:interview_id"
                  element={<CommentsForm />}
                />
                <Route
                  path="view-all-comments/:id"
                  element={<ViewAllComments />}
                />
                <Route path="proposal/:id" element={<Proposal />} />
                <Route path="preferences/:id" element={<Preferences />} />
                <Route path="profile" element={<Profile />}>
                  <Route
                    path="personal-details/:id"
                    element={<PersonalDetails />}
                  />
                  <Route path="history/:id" element={<HistoryStepper />} />
                  <Route
                    path="history/:step_id/:id"
                    element={<HistoryStepper />}
                  />
                  <Route path="skills/:id" element={<Skills />} />
                  <Route path="bgv-details/:id" element={<BGVDetails />} />
                </Route>
                <Route path="review" element={<Review />}>
                  <Route path="online-tests/:id" element={<OnlineTests />} />
                  <Route path="interviews/:id" element={<Interviews />} />
                </Route>
              </Route>
            </Route>

            <Route element={<PageAccessGuard />}>
              <Route path="admin" element={<Configuration />}>
                <Route path="users" element={<Users />} />
                <Route path="user" element={<UserForm />} />
                <Route path="user/:user_id" element={<UserView />} />
                <Route path="roles" element={<UserRoles />} />
                <Route
                  path="global-settings"
                  element={<GlobalSettingsView />}
                />
                <Route path="templates" element={<EmailsTemplates />} />
                <Route
                  path="template/:template_id"
                  element={<EmailTemplateView />}
                />
                <Route path="template" element={<EmailTemplatesForm />} />
                <Route path="role" element={<UserRoleForm />} />
                <Route path="role/:roleId" element={<UserRoleView />} />
                <Route path="designations" element={<Designations />} />
                <Route path="designation" element={<DesignationForm />} />
                <Route
                  path="designation/:role_id"
                  element={<DesignationView />}
                />
                <Route path="domains" element={<Domains />} />
                <Route path="domain" element={<DomainForm />} />
                <Route path="domain/:domain_id" element={<DomainView />} />
                <Route path="departments" element={<Departments />} />
                <Route path="department" element={<DepartmentForm />} />
                <Route path="department/:_id" element={<DepartmentView />} />
                <Route path="sub-departments" element={<SubDepartments />} />
                <Route path="sub-department" element={<SubDepartmentForm />} />
                <Route
                  path="sub-department/:_id"
                  element={<SubDepartmentView />}
                />
                <Route path="resume-sources" element={<ResumeSources />} />
                <Route path="resume-source" element={<ResumeSourceForm />} />
                <Route
                  path="resume-source/:resume_source_id"
                  element={<ResumeSourceView />}
                />
                <Route path="colleges" element={<Colleges />} />
                <Route path="college" element={<CollegeForm />} />
                <Route path="college/:college_id" element={<CollegeView />} />
                <Route path="degrees" element={<Degrees />} />
                <Route path="degree" element={<DegreeForm />} />
                <Route path="degree/:degree_id" element={<DegreeView />} />
                <Route path="branches" element={<Branches />} />
                <Route path="branch" element={<BranchForm />} />
                <Route path="branch/:branch_id" element={<BranchView />} />
                <Route path="entrance-exams" element={<EntranceExams />} />
                <Route path="entrance-exam" element={<EntranceExamsForm />} />
                <Route
                  path="entrance-exam/:entrance_id"
                  element={<EntranceExamsView />}
                />
                <Route path="events" element={<Events />} />
                <Route path="event" element={<EventsForm />} />
                <Route path="event/:event_id" element={<EventView />} />
                <Route path="stages" element={<Stages />} />
                <Route path="stage" element={<StageForm />} />
                <Route path="stage/:stage_id" element={<StageView />} />
                <Route path="statuses" element={<Status />} />
                <Route path="status" element={<StatusForm />} />
                <Route path="status/:status_id" element={<StatusView />} />
                <Route path="cases" element={<Cases />} />
                <Route path="case" element={<CaseForm />} />
                <Route path="case/:case_id" element={<CaseView />} />
                <Route path="actions" element={<Actions />} />
                <Route path="action" element={<ActionForm />} />
                <Route path="action/:action_id" element={<ActionView />} />
                <Route path="pincodes" element={<Pincodes />} />
                <Route path="pincode" element={<PincodeForm />} />
                <Route path="pincode/:pincode_id" element={<PincodeView />} />
                <Route path="tags" element={<Tags />} />
                <Route path="tag" element={<TagForm />} />
                <Route path="tag/:tag_id" element={<TagView />} />
              </Route>
            </Route>
          </Route>
        </Route>

        <Route element={<ReferralAuthenticationGuard />}>
          <Route path="refer" element={<CandidateReferral />}>
            <Route exact path="" element={<Navigate to="candidate" />} />
            <Route path="access-denied" element={<AccessDeniedPage />} />
            <Route path="candidate" element={<CandidateReferralForm />} />
          </Route>
        </Route>

        <Route path="candidate" element={<CandidateFacing />}>
          <Route exact path="" element={<Navigate to="recruitment" />} />
          <Route path="access-denied" element={<AccessDeniedPage />} />
          <Route path="recruitment" element={<CandidateFacingRouting />}>
            <Route
              path="web-register/:designation_id"
              element={<WebRegistrationStepper />}
            />
            <Route
              path="web-register/:step_id/:designation_id"
              element={<WebRegistrationStepper />}
            />
            <Route
              path="web-register/:step_id/:designation_id/:candidate_id"
              element={<WebRegistrationStepper />}
            />
            {/* <Route path='web-register/:designation_id/:resume_source_id' element={<WebRegistrationStepper />} /> */}
            {/* <Route path='web-register/:step_id/:designation_id/:resume_source_id' element={<WebRegistrationStepper />} /> */}
            {/* <Route path='web-register/:step_id/:designation_id/:resume_source_id/:candidate_id' element={<WebRegistrationStepper />} /> */}
            <Route
              path="register/:candidate_id/:uuid"
              element={<RegistrationStepper />}
            />
            <Route
              path="register/:step_id/:candidate_id/:uuid"
              element={<RegistrationStepper />}
            />
            <Route
              path="upload-documents/:candidate_id/:uuid"
              element={<CandidateFacingDocuments />}
            />
          </Route>
        </Route>
      </Routes>
    </React.Suspense>
  );
}

export default Routing;
