import React, { useEffect, useState } from "react";
import { Button, Card, Typography, Tooltip } from "@mui/material";
import * as API from "../../../utills/API";
import { useParams } from "react-router-dom";
import * as CommonMethods from "../../../utills/CommonMethods";
import useFormData from "../../../utills/hooks/useFormData";
import * as Messages from "../../../utills/constants/Messages";
import Skeletonview from "../../../components/Skeletons/Skeletonview";
function GlobalSettingsData(props) {
  const [globalSettingsData, setGlobalSettingsData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const formData = useFormData();

  const fetchGlobalSettingsData = () => {
    setDataLoaded(false);
    API.GetMethod("globalSettings").then((res) => {
      
      
      if (!CommonMethods.isNullOrUndefined(res.data) && res.data.length > 0) {
        setGlobalSettingsData(res.data[0]);
        
      } else {
        setGlobalSettingsData({});
      }
      setDataLoaded(true);
    });
  };

  useEffect(() => {
    fetchGlobalSettingsData();
  }, []);
  return (
    <div className="d-flex flex-column flex-grow-1 mer-overflow mer-overflow-x-hidden mer-margin-top-16">
      <Card className="d-flex flex-column teritary">
        <div className="d-flex flex-row align-items-center justify-content-between mer-margin-left-16 mer-margin-top-16 mer-margin-right-16">
          <Typography variant="h6">Global Settings</Typography>
          <Tooltip title={formData?.can_edit ? "" : Messages?.NO_EDIT}>
            <div>
              <Button
                disabled={formData?.can_edit ? false : true}
                variant="contained"
                onClick={() => {
                  props.onChange();
                }}
              >
                Edit
              </Button>{" "}
            </div>
          </Tooltip>
        </div>
        {dataLoaded ? (
          <div className="d-flex flex-row flex-wrap mer-margin-left-16 mer-margin-top-16 mer-margin-right-16 mer-margin-bottom-16">
            <div className="d-flex flex-column col-12 col-sm-12 mer-mobile-width-percentage-100 col-md-6 col-lg-6 col-xl-6">
              <Card className="mer-margin-right-8 mer-margin-bottom-8 flex-grow-1 secondary">
                <div className="mer-font-bold  mer-margin-bottom-8">
                  SMTP Configurations
                </div>
                <div className="d-flex flex-row">
                  <div className="mer-width-percentage-45 mer-margin-right-8">
                    HOST
                  </div>
                  <div className="mer-width-percentage-5">:</div>
                  <div className="d-flex mer-width-percentage-50 align-items-center">
                    {globalSettingsData?.smtp?.host}
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div className="mer-width-percentage-45 mer-margin-right-8">
                    Port
                  </div>
                  <div className="mer-width-percentage-5">:</div>
                  <div className="d-flex mer-width-percentage-50 align-items-center">
                    {globalSettingsData?.smtp?.port}
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div className="mer-width-percentage-45 mer-margin-right-8">
                    Email
                  </div>
                  <div className="mer-width-percentage-5">:</div>
                  <div
                    className="d-flex mer-width-percentage-50 align-items-center"
                    style={{ wordWrap: "anywhere" }}
                  >
                    {globalSettingsData?.smtp?.email}
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div className="mer-width-percentage-45 mer-margin-right-8">
                    Password
                  </div>{" "}
                  <div className="mer-width-percentage-5">:</div>
                  <div className="d-flex mer-width-percentage-50 align-items-center">
                    {globalSettingsData?.smtp?.password ? "*********" : ""}
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div className="mer-width-percentage-45 mer-margin-right-8">
                    Candidate role
                  </div>
                  <div className="mer-width-percentage-5">:</div>
                  <div className="d-flex mer-width-percentage-50 align-items-center">
                    {globalSettingsData?.candidate_role_name}
                  </div>
                </div>
              </Card>
            </div>
          </div>
        ) : (
          <Skeletonview />
        )}
      </Card>
    </div>
  );
}

export default GlobalSettingsData;
