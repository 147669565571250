import { useFormik } from "formik";
import React, { useEffect, useState, useRef } from "react";
import {
  confirmation_dialog,
  simple_dialog,
  status_dialog,
} from "../../../utills/Alerts";
import * as Messages from "../../../utills/constants/Messages";
import * as API from "../../../utills/API";
import * as CommonMethods from "../../../utills/CommonMethods";
import RichTextEditor from "react-rte";
import * as Yup from "yup";
import { IoMdCloseCircleOutline } from "react-icons/io";
import {
  TextField,
  FormControl,
  Button,
  FormLabel,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Autocomplete,
  Card,
  Tooltip,
  Box,
  CircularProgress,
} from "@mui/material";
import {
  AUTO_COMPLETE_CONFIG,
  TEXT_FIELD_CONFIG,
} from "../../../utills/constants/FormFieldFormats";
import create from "zustand";
import Loading from "../../../components/Loading";

export const request_docs_dialog = async (
  open,
  action,
  candidate,
  callback
) => {
  // async function getTemplate() {
  //   return API.PostMethod(`getEmailTemplateData/` + action.stage_conditions[0].email_template_ids[0],
  //     {
  //       action: action,
  //       candidate: candidate,
  //     }
  //   );
  // }

  RequestDocumentsFormDialogStore.setState({
    open,
    action,
    candidate,
    callback,
  });
  // const templateData = await getTemplate();
  // RequestDocumentsFormDialogStore.setState({ templateData: templateData?.data });
};

const RequestDocumentsFormDialogStore = create((set) => ({
  open: "",
  action: "",
  candidate: "",
  close: () =>
    set({
      open: false,
    }),
  callback: undefined,
}));

function RequestDocumentsForm(props) {
  const { open, close, candidate, action, callback, templateData } =
    RequestDocumentsFormDialogStore();

  const [mailData, setTemplateData] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const markup = "";
  const [loading, setLoading] = useState(false);

  const [value, setValue] = useState(
    RichTextEditor.createValueFromString(markup, "html")
  );

  const request_docs_form = useFormik({
    initialValues: {
      candidate_id: "",
      stage_id: "",
      status_id: "",
      subject: "",
      body: "",
      saved_ccs: [],
      selected_cc: [],
    },
    validationSchema: Yup.object().shape({
      saved_ccs: Yup.array()
        .min(1, "Please select at least 1 recipient")
        .required(Messages?.REQUIRED_FIELD_ERROR)
        .typeError("Please select proper recipient"),
      subject: Yup.string().required(Messages?.REQUIRED_FIELD_ERROR),
      body: Yup.string().required(Messages?.REQUIRED_FIELD_ERROR),
      candidate_id: Yup.string().nullable(),
      stage_id: Yup.string(),
      status_id: Yup.string(),
    }),
    onSubmit: (values) => {
      let message = `Do you want to ${action?.button_name} the mail ?`;
      setLoading(true);
      confirmation_dialog(
        message,
        () => {
          API.PostMethod(`${action?.confirmation_api}`, {
            action: action,
            candidate: candidate,
            subject: request_docs_form.values.subject,
            body: request_docs_form.values.body,
            saved_ccs: request_docs_form.values.saved_ccs.join(","),
          }).then(
            (result) => {
              if (action.success_message) {
                setLoading(false);
                let status_details = {
                  ...CommonMethods.STATUS_POPUP_DETAILS.success,
                  message: action.success_message,
                };
                status_dialog(status_details, () => {
                  callback(true);
                  return;
                });
                // simple_dialog(action.success_message, () => {
                // });
              }
              request_docs_form.resetForm();
              close();
            },
            (error) => {}
          );
        },
        null,
        null,
        () => {
          setLoading(false);
        }
      );
    },
  });

  // useEffect(() => {
  //   if (localStorage.getItem("mer-auth-key")) {
  //     getEmployeesList();
  //   }
  // }, []);
  useEffect(() => {
    setDataLoaded(false);
    if (
      !CommonMethods.isNullOrUndefined(action) &&
      !CommonMethods.isNullOrUndefined(candidate)
    ) {
      if (
        open &&
        localStorage.getItem("mer-auth-key") &&
        (CommonMethods.isNullOrUndefined(employeeList) ||
          employeeList.length === 0)
      ) {
        getEmployeesList()
          .then(
            (res) => {
              getTemplate(res).finally(() => {
                setDataLoaded(true);
              });
            },
            (error) => {
              console.log("error in the getEmployeeList", error);
            }
          )
          .catch((err) => {
            console.log("error in calling employeeList", err);
          });
      } else {
        getTemplate(employeeList).finally(() => {
          setDataLoaded(true);
        });
      }
    }

    // if (templateData) {
    //   const cc_mail_ids = templateData.cc.toString().split(",");
    //   const foundObjects = [];
    //   cc_mail_ids.forEach((searchId) => {
    //     const foundObject = employeeList.find(
    //       (obj) => obj.email_id === searchId
    //     );
    //     if (foundObject) {
    //       foundObjects.push(foundObject);
    //     }
    //   });

    //   request_docs_form.setValues({
    //     ...request_docs_form.values,
    //     subject: templateData.subject || "",
    //     saved_ccs: templateData.cc.toString().split(",") || [],
    //     selected_cc: foundObjects || [],
    //   });
    //   setValue(RichTextEditor.createValueFromString(templateData.body || "", "html"));
    //   let content = RichTextEditor.createValueFromString(templateData.body || "", "html");
    //   request_docs_form.setFieldTouched("body", true, true);
    //   request_docs_form.setFieldValue("body", content.toString("html"));
    // }
  }, [candidate]);

  async function getEmployeesList() {
    return new Promise((resolve, reject) => {
      API.GetMethod(`getEmployees`).then(
        async (res) => {
          setEmployeeList(res.data);
          resolve(res.data);
        },
        (error) => {
          setEmployeeList([]);
          reject(error);
        }
      );
    });
    // .catch((error) => {
    //   reject(error);
    // });
    // });
  }

  function findEmailTemplateIds(data, statusId, documents_available) {
    let templateId;
    for (let item of data) {
      if (item.status_id === statusId) {
        let criteria = {};
        try {
          criteria = JSON.parse(item.criteria);
        } catch (e) {
          criteria = {};
        }
        const documentsAvailableCriteria =
          criteria?.dataChecks?.and?.documents_available;

        if (documentsAvailableCriteria === documents_available) {
          templateId = item.email_template_ids
            ? item.email_template_ids[0]
            : item.email_template_ids;
        } else if (
          (CommonMethods.isNullOrUndefined(criteria) || criteria == "") &&
          !templateId
        ) {
          templateId = item.email_template_ids
            ? item.email_template_ids[0]
            : item.email_template_ids;
        }
      }
    }
    return templateId;
  }

  async function getTemplate(employeeArray) {
    let templateId = await findEmailTemplateIds(
      action?.stage_conditions,
      candidate.status_id,
      candidate.data_checks["documents_available"]
        ? candidate.data_checks["documents_available"]
        : false
    );

    return new Promise((resolve, reject) => {
      API.PostMethod(
        `getEmailTemplateData/` +
          (templateId
            ? templateId
            : action?.stage_conditions[0]?.email_template_ids[0]),
        {
          action: action,
          candidate: candidate,
        }
      ).then(
        async (res) => {
          try {
            if (!CommonMethods.isNullOrUndefined(res?.data)) {
              let templateData = res?.data;
              setTemplateData(res.data);
              const cc_mail_ids = templateData.cc.toString().split(",");
              const foundObjects = [];
              cc_mail_ids.forEach((searchId) => {
                const foundObject = employeeArray?.find(
                  (obj) => obj.email_id === searchId
                );
                if (foundObject) {
                  foundObjects.push(foundObject);
                }
              });
              request_docs_form.setValues({
                ...request_docs_form.values,
                subject: templateData.subject || "",
                saved_ccs: templateData.cc.toString().split(",") || [],
                selected_cc: foundObjects || [],
              });

              /**
               * Replace the ## rejected_docuemnts ##
               * The documents in the below categories are found to have issues and are rejected. We would you to re-upload them.
               * <ul>
               * <li></li>
               * </ul
               *
               */

              let body = templateData?.body ?? "";
              const candidateDocumentsData =
                candidate?.attachments?.documents ?? {};
              const documentCategoryMap = {
                educational: "Educational Documents",
                rank_cards: "Rank & Score Cards",
                work_experience: "Pay History & Full-Time Work Experience",
                identity: "Identity & Residential Proofs",
                reference: "References",
              };
              const rejectedDocumentCategories = Object.keys(
                candidateDocumentsData
              )?.filter((key) => {
                const value = candidateDocumentsData[key];
                return value?.some(
                  (data) =>
                    !data?.verification_status ||
                    data?.verification_status === "rejected" ||
                    data?.verification_status === "pending"
                );
              });
              let replacedText = "";
              if (rejectedDocumentCategories?.length > 0) {
                let lists = rejectedDocumentCategories.map((rej) => {
                  return `<li><b>${documentCategoryMap[rej] + " - "}</b></li>`;
                });
                replacedText = `<ol>${lists.join("\n")}</ol>\n`;
              }
              body = body.replace(
                "##rejected_document_categories##",
                replacedText
              );
              setValue(
                RichTextEditor.createValueFromString(body || "", "html")
              );
              let content = RichTextEditor.createValueFromString(
                body || "",
                "html"
              );
              request_docs_form.setFieldTouched("body", true, true);
              request_docs_form.setFieldValue("body", content.toString("html"));
            }
            resolve();
          } catch (err) {
            reject(err);
          }
        },
        (errors) => {
          reject(errors);
        }
      );
    });
  }
  function handleRecipients(value) {
    let recipients_ids = [];

    for (let recipient of value) {
      recipients_ids.push(recipient.email_id);
    }
    request_docs_form.setFieldValue("selected_cc", value);
    request_docs_form.setFieldValue("saved_ccs", recipients_ids);
  }

  function mailContentChange(value) {
    setValue(value);
    request_docs_form.setFieldTouched("body", true, true);
    request_docs_form.setFieldValue("body", value.toString("html"));
  }

  return (
    <Dialog
      open={Boolean(open)}
      className="request-documents-dialog"
      onClose={() => {
        request_docs_form.resetForm();
        close();
      }}
      disableEscapeKeyDown={true}
    >
      {dataLoaded ? (
        <form
          className="d-grid flex-grow-1 mer-overflow mer-form"
          onSubmit={request_docs_form.handleSubmit}
        >
          <div>
            <DialogTitle className="mer-theme-dialog-header-bg-tables mer-padding-8">
              <div className=" d-flex flex-row align-items-center justify-content-between">
                <div className="mer-font-weight-150 mer-font-size-20">
                  {action.action_name} ?
                </div>
                <DialogActions>
                  <IoMdCloseCircleOutline
                    onClick={() => {
                      if (!loading) {
                        request_docs_form.resetForm();
                        close();
                      }
                    }}
                    className={
                      "mer-min-height-24 mer-min-width-24 " +
                      (loading ? "" : "mer-cursor-pointer")
                    }
                  />
                </DialogActions>
              </div>
            </DialogTitle>
          </div>
          <DialogContent>
            <div className="d-flex flex-row flex-wrap">
              <Card className="d-flex flex-grow-1 flex-column teritary  mer-margin-0 mer-padding-8">
                <div className="d-flex  justify-content-between">
                  <div
                    style={{
                      flexShrink: 0,
                      whiteSpace: "nowrap",
                      marginRight: "8px",
                    }}
                  >
                    <b>Name:&nbsp;</b>
                    {CommonMethods.concateNames(
                      candidate?.first_name,
                      candidate?.middle_name,
                      candidate?.last_name
                    )}
                  </div>
                  <div
                    style={{
                      flexShrink: 0,
                      whiteSpace: "nowrap",
                      marginRight: "8px",
                    }}
                  >
                    <b>Applied domain:&nbsp;</b>
                    {candidate.domain_name}
                  </div>
                  {/* <div style={{ flexShrink: 0, whiteSpace: 'nowrap', marginRight: '8px' }}><b>Role:</b>{candidate.department_name}</div> */}
                  <div
                    style={{
                      flexShrink: 0,
                      whiteSpace: "nowrap",
                      marginRight: "8px",
                    }}
                  >
                    <b>Designation:&nbsp;</b>
                    {candidate.designation_name}
                  </div>
                </div>
              </Card>

              <div className="mer-margin-0 flex-grow-1 mer-padding-top-12 mer-padding-bottom">
                <Card className="d-flex flex-column teritary mer-margin-0  flex-grow-1 mer-padding-12">
                  <div className="d-flex flex-row flex-wrap justify-content-start align-items-start align-content-start">
                    <div className="d-flex flex-column mer-margin-right-12 mer-margin-bottom-12 col-12">
                      <div>
                        <b className="mer-margin-right-4">To:</b>
                        {candidate.email_id}
                      </div>
                    </div>
                    <div className="d-flex flex-column mer-margin-right-12 mer-margin-bottom-12 col-12">
                      <FormLabel id="">Subject</FormLabel>
                      <FormControl>
                        <TextField
                          id={`subject`}
                          name={`subject`}
                          placeholder="Subject"
                          className="mer-field-bg-white"
                          {...TEXT_FIELD_CONFIG.OUTLINED}
                          onChange={request_docs_form.handleChange}
                          value={request_docs_form.values?.subject}
                          // value={ mailData?.subject                      }
                          error={
                            request_docs_form.touched?.subject &&
                            request_docs_form.errors?.subject
                              ? true
                              : false
                          }
                          onBlur={request_docs_form.handleBlur}
                        />
                      </FormControl>
                      <div className="mer-error">
                        {request_docs_form.touched?.subject &&
                        request_docs_form?.errors?.subject ? (
                          <span className="error">
                            {request_docs_form?.errors?.subject}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div className="d-flex flex-column mer-margin-right-12 mer-margin-bottom-12 col-12">
                      <FormControl>
                        <FormLabel id="">
                          Add Cc<sup className="mer-color-red">*</sup>
                        </FormLabel>
                        <Autocomplete
                          name="saved_ccs"
                          className="mer-autoComplete"
                          //onPaste={handlePaste}
                          multiple
                          disableClearable
                          filterSelectedOptions
                          {...AUTO_COMPLETE_CONFIG.OUTLINED}
                          options={
                            !CommonMethods.isNullOrUndefined(employeeList) &&
                            employeeList?.length
                              ? employeeList
                              : []
                          }
                          value={request_docs_form.values?.selected_cc}
                          getOptionLabel={(option) => option.employee_name}
                          getOptionDisabled={(option) => !option.is_active}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option["_id"]}>
                                <div className="d-flex flex-column">
                                  <div>{option["employee_name"]}</div>
                                  <div className="mer-font-size-12">
                                    {option["email_id"]}
                                  </div>
                                </div>
                              </li>
                            );
                          }}
                          key={(option) => option._id}
                          onChange={async (event, value) => {
                            handleRecipients(value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              name="saved_ccs"
                              className="mer-field-bg-white"
                              // onPaste={handlePaste}
                              value={request_docs_form.values.saved_ccs}
                              {...params}
                              placeholder="Type in or select employee"
                              error={
                                request_docs_form.touched.saved_ccs &&
                                request_docs_form.errors.saved_ccs
                                  ? true
                                  : false
                              }
                              onChange={request_docs_form.handleChange}
                              onBlur={request_docs_form.handleBlur}
                            />
                          )}
                        />
                      </FormControl>
                      {request_docs_form.touched.saved_ccs &&
                      request_docs_form.errors.saved_ccs ? (
                        <span className="mer-error">
                          {request_docs_form.errors.saved_ccs}
                        </span>
                      ) : null}
                    </div>

                    <div className="d-flex flex-column mer-margin-right-12 mer-margin-bottom-12 col-12">
                      <FormControl>
                        <FormLabel id="demo-select-small">
                          Body<sup className="mer-color-red">*</sup>
                        </FormLabel>
                        <RichTextEditor
                          id="body"
                          placeholder="Enter here..."
                          className="mer-field-bg-white"
                          value={value}
                          name={request_docs_form.values.body}
                          toolbarConfig={CommonMethods.toolbarConfig}
                          onChange={mailContentChange}
                          onBlur={request_docs_form.handleBlur}
                          error={
                            request_docs_form.touched.body &&
                            request_docs_form.errors.body
                              ? true
                              : false
                          }
                        />
                      </FormControl>
                      {request_docs_form.touched.body &&
                      request_docs_form.errors.body ? (
                        <span className="mer-error">
                          {request_docs_form.errors.body}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </Card>
                {/* </DialogContent> */}
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="d-flex justify-content-end align-self-end">
              <Button
                disabled={loading}
                variant="outlined"
                className="mer-margin-right-12 outlinedcls"
                onClick={() => {
                  request_docs_form.resetForm();
                  close();
                }}
              >
                Cancel
              </Button>
              <Tooltip
                title={
                  request_docs_form.isValid
                    ? ""
                    : "Please provide valid details"
                }
              >
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className="text-capitalize"
                >
                  {!loading ? (
                    action?.button_name
                  ) : (
                    <Box className="d-flex">
                      <CircularProgress size="1.3rem" />
                    </Box>
                  )}
                </Button>
              </Tooltip>
            </div>
          </DialogActions>
        </form>
      ) : (
        <div className="d-flex flex-column flex-grow-1">
          <div>
            <DialogTitle className="mer-theme-dialog-header-bg-tables mer-padding-8">
              <div className=" d-flex flex-row align-items-center justify-content-between">
                <div className="mer-font-weight-150 mer-font-size-20">
                  {action.action_name} ?
                </div>
                <DialogActions>
                  <IoMdCloseCircleOutline
                    onClick={() => {
                      request_docs_form.resetForm();
                      close();
                    }}
                    className="mer-min-height-24 mer-min-width-24 mer-cursor-pointer"
                  />
                </DialogActions>
              </div>
            </DialogTitle>
          </div>
          <div className="d-flex justify-contet-center flex-grow-1 align-items-center">
            <Loading />
          </div>
        </div>
      )}
    </Dialog>
  );
}

export default RequestDocumentsForm;
