import { Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';


function Skeletontable() {
  const [itr_skelton_rows, setIterationrows] = useState([]);
  useEffect(() => {
    let temp_itr = []
    for (let i = 0; i < 8; i++) {
      temp_itr.push(true);
    }
    setIterationrows(temp_itr);
  }, []);

  return (
    <>

      <div className='d-flex flex-column  mer-margin-top-5 mer-margin-left-12'>
        <div className='d-flex flex-row justify-content-end  mer-margin-top-5'>
          <Skeleton variant="rounded" width={210} height={35} className='mer-skel-search mer-theme-skel-tabledata mer-margin-right-20'></Skeleton>
          <Skeleton variant="rounded" width={110} height={35} className='mer-skel-search mer-theme-skel-btn '></Skeleton>
        </div>
        <div className='d-flex flex-column mer-margin-top-5'>
          <Skeleton variant="rectangular" height={45} className='mer-skel-table mer-theme-skel-tableheader'></Skeleton>
        </div>
        {
          itr_skelton_rows.map((row, key) => (
            <div key={key} className='d-flex flex-column '>
              <Skeleton variant="rectangular" height={30} className='mer-skel-table mer-theme-skel-tabledata'></Skeleton>
            </div>
          ))
        }



      </div>
    </>
  )
}

export default Skeletontable