import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Typography } from '@mui/material';
import * as CommonMethods from "../../../utills/CommonMethods";
import * as API from '../../../utills/API';
import * as DateTimeFormats from "../../../utills/DateTimeFormats";
import { RiStarFill, RiStarLine } from 'react-icons/ri';
import { BiRupee } from 'react-icons/bi';
import { LoggedInUserContext } from '../../../App';
// import useFormData from '../../../utills/hooks/useFormData'
import SkeletonAddpopup from '../../../components/Skeletons/SkeletonAddpopup'
function SourcedProfileAdditionalDetails(props) {
  const [candidateDetails, setCandidateDetails] = useState({});
  const { loggedInUser, setLoggedInUser } = useContext(LoggedInUserContext);
  const [isLoaded,setIsloaded] = useState(false);

  //const params = useParams();
  // const formData = useFormData();

  // let navigate = useNavigate();
  useEffect(() => {
    fetchRegistrationDetails();
  }, [])
  const fetchRegistrationDetails = () => {
    if (!CommonMethods.isNullOrUndefined(props?._id)) {
      if (props?.type === 'archive') {
        setIsloaded(false);
        API.GetMethod('webRegistration/web/sourced/' + props?._id).then(response => {
          setIsloaded(true);
          setCandidateDetails(response.data);
        })
      }
      else {
        setIsloaded(false);
        API.GetMethod('webRegistration/web/' + props?._id).then(response => {
          
          setIsloaded(true);
          setCandidateDetails(response.data[0]);
        })
      }
    }
  }

  return (
    <div className='d-flex flex-column flex-grow-1 mer-overflow mer-overflow-x-hidden'>
      { isLoaded? <Card className='d-flex flex-column mer-padding-bottom-12'>
        <div className='d-flex flex-row align-items-center justify-content-between mer-margin-left-16 mer-margin-right-16 mer-margin-top-16'>
          {/* <div className='mer-font-bold'>Registration Details</div> */}
          <Typography variant="h6">Additional Details</Typography>
          {/* <Tooltip title={formData?.can_edit ? '' : Messages?.NO_EDIT} >
            <div>
              <Button disabled={formData?.can_edit ? false : true} variant='contained'  className=''
                onClick={() => {
                  // props.onChange();
                  // navigate(StaticRouterLinks.registration_details_form + params.id) 
                }}>Edit</Button>
            </div>
          </Tooltip> */}
        </div>
        <Card className='d-flex flex-column mer-margin-top-12 mer-margin-left-12 mer-margin-right-12 mer-margin-bottom-0  mer-padding-left-12 mer-padding-top-12 teritary'>
          <div className='mer-font-bold mer-margin-bottom-8'>Skills</div>
          <Card className='d-flex flex-row align-items-center secondary'>
            {candidateDetails?.skills?.length > 0 ?
              <div className='d-flex flex-row flex-wrap'>
                {candidateDetails?.skills?.map(
                  (skill, index) => (
                    <Card key={index} className='d-flex flex-row flex-wrap align-items-center  mer-margin-right-8 mer-margin-bottom-8 mer-margin-left-0 mer-padding-8 chipsTheme'>
                      <div className='mer-margin-right-8'>{skill.skill_name}</div>
                      <div className="d-flex flex-row">
                        {(!CommonMethods.isNullOrUndefined(skill.rating) && skill.rating >= 1) ?
                          <RiStarFill className="mer-width-20 mer-height-20 mer-margin-right-8 mer-purple-color"></RiStarFill> :
                          <RiStarLine className="mer-width-20 mer-height-20 mer-margin-right-8 mer-purple-color"></RiStarLine>}
                        {(!CommonMethods.isNullOrUndefined(skill.rating) && skill.rating >= 2) ?
                          <RiStarFill className="mer-width-20 mer-height-20 mer-margin-right-8 mer-purple-color"></RiStarFill> :
                          <RiStarLine className="mer-width-20 mer-height-20 mer-margin-right-8 mer-purple-color"></RiStarLine>}
                        {(!CommonMethods.isNullOrUndefined(skill.rating) && skill.rating >= 3) ?
                          <RiStarFill className="mer-width-20 mer-height-20 mer-margin-right-8 mer-purple-color"></RiStarFill> :
                          <RiStarLine className="mer-width-20 mer-height-20 mer-margin-right-8 mer-purple-color"></RiStarLine>}
                        {(!CommonMethods.isNullOrUndefined(skill.rating) && skill.rating >= 4) ?
                          <RiStarFill className="mer-width-20 mer-height-20 mer-margin-right-8 mer-purple-color"></RiStarFill> :
                          <RiStarLine className="mer-width-20 mer-height-20 mer-margin-right-8 mer-purple-color"></RiStarLine>}
                        {(!CommonMethods.isNullOrUndefined(skill.rating) && skill.rating === 5) ?
                          <RiStarFill className="mer-width-20 mer-height-20 mer-margin-right-8 mer-purple-color"></RiStarFill> :
                          <RiStarLine className="mer-width-20 mer-height-20 mer-margin-right-8 mer-purple-color"></RiStarLine>}
                      </div>
                    </Card>
                  ))}
              </div> : <div className='d-flex mer-padding-12'>No skills are added</div>
            }
          </Card>
        </Card>
        <Card className='d-flex flex-column mer-margin-top-12 mer-margin-left-12 mer-margin-right-12 mer-margin-bottom-0  mer-padding-left-12 mer-padding-top-12 teritary'>
          <div className='mer-font-bold mer-margin-bottom-8'>Location</div>
          <div className='d-flex flex-grow-1 flex-row flex-wrap mer-overflow'>
            <div className='d-flex flex-column col-md-6 col-lg-4 col-sm-12 col-12 col-xs-12'>
              <Card className='flex-grow-1 secondary'>
                <div className='d-flex flex-row justify-content-between mer-margin-bottom-8'>
                  <div className='mer-font-bold'>Home Town</div>
                </div>
                <div className='d-flex align-items-start justify-content-between'>
                  <div className='mer-width-percentage-45 mer-margin-right-8'>Country</div>
                  <div className='mer-width-percentage-5'>:</div>
                  <div className='mer-width-percentage-50' title={candidateDetails?.personal_details?.home_town?.country_name}>
                    {CommonMethods.isNullOrUndefined(candidateDetails?.personal_details?.home_town?.country_name) ?
                      '-' : candidateDetails?.personal_details?.home_town?.country_name}</div>
                </div>
                <div className='d-flex align-items-start justify-content-between'>
                  <div className='mer-width-percentage-45 mer-margin-right-8'>State</div>
                  <div className='mer-width-percentage-5'>:</div>
                  <div className='mer-width-percentage-50' title={candidateDetails?.personal_details?.home_town?.state_name}>
                    {CommonMethods.isNullOrUndefined(candidateDetails?.personal_details?.home_town?.state_name) ?
                      '-' : candidateDetails?.personal_details?.home_town?.state_name}</div>
                </div>
                <div className='d-flex align-items-start justify-content-between'>
                  <div className='mer-width-percentage-45 mer-margin-right-8'>City</div>
                  <div className='mer-width-percentage-5'>:</div>
                  <div className='mer-width-percentage-50' title={candidateDetails?.personal_details?.home_town?.city_name}>
                    {CommonMethods.isNullOrUndefined(candidateDetails?.personal_details?.home_town?.city_name) ?
                      '-' : candidateDetails?.personal_details?.home_town?.city_name}</div>
                </div>
                <div className='d-flex align-items-start justify-content-between'>
                  <div className='mer-width-percentage-45 mer-margin-right-8'>Pin code</div>
                  <div className='mer-width-percentage-5'>:</div>
                  <div className='mer-width-percentage-50' title={candidateDetails?.personal_details?.home_town?.pincode}>
                    {CommonMethods.isNullOrUndefined(candidateDetails?.personal_details?.home_town?.pincode) ?
                      '-' : candidateDetails?.personal_details?.home_town?.pincode}</div>
                </div>
              </Card>
            </div>
            <div className='d-flex flex-column col-md-6 col-lg-4 col-sm-12 col-12 col-xs-12'>
              <Card className='flex-grow-1 secondary'>
                <div className='d-flex flex-row justify-content-between mer-margin-bottom-8'>
                  <div className='mer-font-bold'>Current Location</div>
                  {candidateDetails?.personal_details?.current_location?.is_home_town ?
                    <Card className='chipsTheme mer-font-size-12 mer-margin-0 mer-padding-4'>Same as Home Town </Card> : null}
                </div>
                <div className='d-flex align-items-start justify-content-between'>
                  <div className='mer-width-percentage-45 mer-margin-right-8'>Country</div>
                  <div className='mer-width-percentage-5'>:</div>
                  <div className='mer-width-percentage-50' title={candidateDetails?.personal_details?.current_location?.country_name}>
                    {CommonMethods.isNullOrUndefined(candidateDetails?.personal_details?.current_location?.country_name) ?
                      '-' : candidateDetails?.personal_details?.current_location?.country_name}</div>
                </div>
                <div className='d-flex align-items-start justify-content-between'>
                  <div className='mer-width-percentage-45 mer-margin-right-8'>State</div>
                  <div className='mer-width-percentage-5'>:</div>
                  <div className='mer-width-percentage-50' title={candidateDetails?.personal_details?.current_location?.state_name}>
                    {CommonMethods.isNullOrUndefined(candidateDetails?.personal_details?.current_location?.state_name) ?
                      '-' : candidateDetails?.personal_details?.current_location?.state_name}</div>
                </div>
                <div className='d-flex align-items-start justify-content-between'>
                  <div className='mer-width-percentage-45 mer-margin-right-8'>City</div>
                  <div className='mer-width-percentage-5'>:</div>
                  <div className='mer-width-percentage-50' title={candidateDetails?.personal_details?.current_location?.city_name}>
                    {CommonMethods.isNullOrUndefined(candidateDetails?.personal_details?.current_location?.city_name) ?
                      '-' : candidateDetails?.personal_details?.current_location?.city_name}</div>
                </div>
                <div className='d-flex align-items-start justify-content-between'>
                  <div className='mer-width-percentage-45 mer-margin-right-8'>Pin code</div>
                  <div className='mer-width-percentage-5'>:</div>
                  <div className='mer-width-percentage-50' title={candidateDetails?.personal_details?.current_location?.pincode}>
                    {CommonMethods.isNullOrUndefined(candidateDetails?.personal_details?.current_location?.pincode) ?
                      '-' : candidateDetails?.personal_details?.current_location?.pincode}</div>
                </div>
              </Card>
            </div>
          </div>
        </Card>
        <Card className='d-flex flex-column mer-margin-top-12 mer-margin-left-12 mer-margin-right-12 mer-margin-bottom-0 mer-padding-left-12 mer-padding-top-12 teritary'>
          <div className='mer-font-bold mer-margin-bottom-8'>Salary Details</div>
          <div className='d-flex flex-wrap flex-row'>
            {candidateDetails?.is_experienced ? <div className='d-flex col-md-6 col-lg-6 col-sm-12 col-12 col-xs-12 flex-column'>
              <Card className='d-flex flex-grow-1 flex-column secondary'>
                <div className='mer-font-bold mer-margin-bottom-8'>Last Drawn Salary Details</div>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-start justify-content-between'>
                    <div className='mer-width-percentage-65 mer-margin-right-8' title='Last Drawn Salary (per annum)/CTC'>Last Drawn Salary (per annum) / CTC</div>
                    <div className='mer-width-percentage-5'>:</div>
                    <div className='d-flex align-items-center mer-width-percentage-30'>
                      {!CommonMethods.isNullOrUndefined(candidateDetails?.preferences?.salary_details?.last_drawn_ctc) ?
                        loggedInUser?.role_info?.pay_details_access ?
                          <div>
                            <BiRupee className='mer-width-16 mer-height-16' />
                            {CommonMethods.numberFormat(candidateDetails?.preferences?.salary_details?.last_drawn_ctc)}
                          </div> : <div className='mer-margin-top-4'>******</div> : '-'}
                    </div>
                  </div>
                  <div className='d-flex align-items-start justify-content-between'>
                    <div className='mer-width-percentage-65 mer-margin-right-8' title='Last Drawn Salary - Fixed (per annum)'>Last Drawn Salary - Fixed (per annum)</div>
                    <div className='mer-width-percentage-5'>:</div>
                    <div className='d-flex align-items-center mer-width-percentage-30'>
                      {!CommonMethods.isNullOrUndefined(candidateDetails?.preferences?.salary_details?.last_drawn_fixed) ?
                        loggedInUser?.role_info?.pay_details_access ?
                          <div>
                            <BiRupee className='mer-width-16 mer-height-16' />
                            {CommonMethods.numberFormat(candidateDetails?.preferences?.salary_details?.last_drawn_fixed)}
                          </div> : <div className='mer-margin-top-4'>******</div> : '-'}
                    </div>
                  </div>
                  <div className='d-flex align-items-start justify-content-between'>
                    <div className='mer-width-percentage-65 mer-margin-right-8' title='Last Drawn Salary - Variable (per annum)'>Last Drawn Salary - Variable/Bonus (per annum)</div>
                    <div className='mer-width-percentage-5'>:</div>
                    <div className='d-flex align-items-center mer-width-percentage-30'>
                      {!CommonMethods.isNullOrUndefined(candidateDetails?.preferences?.salary_details?.last_drawn_variable) ?
                        loggedInUser?.role_info?.pay_details_access ?
                          <div>
                            <BiRupee className='mer-width-16 mer-height-16' />
                            {CommonMethods.numberFormat(candidateDetails?.preferences?.salary_details?.last_drawn_variable)}
                          </div> : <div className='mer-margin-top-4'>******</div> : '-'}
                    </div>
                  </div>
                  <div className='d-flex align-items-start justify-content-between'>
                    <div className='mer-width-percentage-65 mer-margin-right-8' title='Joining Bonus'>Joining Bonus</div>
                    <div className='mer-width-percentage-5'>:</div>
                    <div className='d-flex align-items-center mer-width-percentage-30'>
                      {!CommonMethods.isNullOrUndefined(candidateDetails?.preferences?.salary_details?.joining_bonus) ?
                        loggedInUser?.role_info?.pay_details_access ?
                          <div>
                            <BiRupee className='mer-width-16 mer-height-16' />
                            {CommonMethods.numberFormat(candidateDetails?.preferences?.salary_details?.joining_bonus)}
                          </div> : <div className='mer-margin-top-4'>******</div> : '-'}
                    </div>
                  </div>
                  <div className='d-flex align-items-start justify-content-between'>
                    <div className='mer-width-percentage-65 mer-margin-right-8' title='Retention Bonus'>Retention Bonus</div>
                    <div className='mer-width-percentage-5'>:</div>
                    <div className='d-flex align-items-center mer-width-percentage-30'>
                      {!CommonMethods.isNullOrUndefined(candidateDetails?.preferences?.salary_details?.retention_bonus) ?
                        loggedInUser?.role_info?.pay_details_access ?
                          <div>
                            <BiRupee className='mer-width-16 mer-height-16' />
                            {CommonMethods.numberFormat(candidateDetails?.preferences?.salary_details?.retention_bonus)}
                          </div>
                          : <div className='mer-margin-top-4'>******</div> : '-'}
                    </div>
                  </div>
                  <div className='d-flex align-items-start justify-content-between'>
                    <div className='mer-width-percentage-65 mer-margin-right-8' title='In-hand per month'>In-hand per month</div>
                    <div className='mer-width-percentage-5'>:</div>
                    <div className='d-flex align-items-center mer-width-percentage-30'>
                      {!CommonMethods.isNullOrUndefined(candidateDetails?.preferences?.salary_details?.in_hand_per_month) ?
                        loggedInUser?.role_info?.pay_details_access ?
                          <div>
                            <BiRupee className='mer-width-16 mer-height-16' />
                            {CommonMethods.numberFormat(candidateDetails?.preferences?.salary_details?.in_hand_per_month)}
                          </div> : <div className='mer-margin-top-4'>******</div> : '-'}
                    </div>
                  </div>
                  <div className='d-flex align-items-start justify-content-between'>
                    <div className='mer-width-percentage-65 mer-margin-right-8' title='Other Perks'>Other perks</div>
                    <div className='mer-width-percentage-5'>:</div>
                    <div className='mer-width-percentage-30'>
                      {!CommonMethods.isNullOrUndefined(candidateDetails?.preferences?.salary_details?.other_perks) &&
                        candidateDetails?.preferences?.salary_details?.other_perks !== '' ?
                        loggedInUser?.role_info?.pay_details_access ?
                          candidateDetails?.preferences?.salary_details?.other_perks : <div className='mer-margin-top-4'>***** **** ****</div>
                        : '-'}
                    </div>
                  </div>
                </div>
              </Card>
            </div> : null}
            <div className='d-flex flex-column col-md-6 col-lg-6 col-sm-12 col-12 col-xs-12'>
              <Card className='d-flex flex-grow-1 flex-column secondary'>
                <div className='mer-font-bold mer-margin-bottom-8'>Salary Expectations</div>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-start justify-content-between'>
                    <div className='mer-width-percentage-45 mer-margin-right-8' title='Expected Pay (per annum)'>Expected Pay (per annum) </div>
                    <div className='mer-width-percentage-5'>:</div>
                    <div className='d-flex align-items-center mer-width-percentage-50'>
                      {!CommonMethods.isNullOrUndefined(candidateDetails?.preferences?.salary_details?.expected_pay_ctc) ?
                        loggedInUser?.role_info?.pay_details_access ?
                          <div>
                            <BiRupee className='mer-width-16 mer-height-16' />
                            {CommonMethods.numberFormat(candidateDetails?.preferences?.salary_details?.expected_pay_ctc)}
                          </div> : <div className='mer-margin-top-4'>******</div> : '-'}
                    </div>
                  </div>
                  <div className='d-flex align-items-start justify-content-between'>
                    <div className='mer-width-percentage-45 mer-margin-right-8' title='Flexibility on Pay'>Flexibility on Pay</div>
                    <div className='mer-width-percentage-5'>:</div>
                    <div className='mer-width-percentage-50'>
                      {!CommonMethods.isNullOrUndefined(candidateDetails?.preferences?.salary_details?.pay_flexibility_type) ?
                        loggedInUser?.role_info?.pay_details_access ?
                          candidateDetails?.preferences?.salary_details?.pay_flexibility_type : <div className='mer-margin-top-4'>***** **** ****</div> : '-'}
                    </div>
                  </div>
                </div>
              </Card>
            </div>
            {/* </div>
          <div className='d-flex flex-wrap col-12 justify-content-between'> */}
            {candidateDetails?.is_experienced ?
              <div className='d-flex col-md-6 col-lg-6 col-sm-12 col-12 col-xs-12'>
                <Card className='d-flex flex-grow-1 flex-column secondary'>
                  <div className='mer-font-bold mer-margin-bottom-8'>Reasons for evaluating job change</div>
                  <div>
                    {!CommonMethods.isNullOrUndefined(candidateDetails?.preferences?.salary_details?.reason_for_job_change) ?
                      loggedInUser?.role_info?.pay_details_access ?
                        candidateDetails?.preferences?.salary_details?.reason_for_job_change : <div className=''>***** **** ****</div> : '-'}
                  </div>
                </Card>
              </div> : null}
            {
              !CommonMethods.isNullOrUndefined(candidateDetails?.preferences?.salary_details?.reason_for_expected_pay) && candidateDetails?.preferences?.salary_details?.reason_for_expected_pay !== '' ?
                <div className='d-flex col-md-6 col-lg-6 col-sm-12 col-12 col-xs-12'>
                  <Card className='d-flex flex-grow-1 flex-column secondary'>
                    <div className='mer-font-bold mer-margin-bottom-8'>Reasons for expected pay</div>
                    <div> {loggedInUser?.role_info?.pay_details_access ? candidateDetails?.preferences?.salary_details?.reason_for_expected_pay
                      : <div className=''>***** **** ****</div>}</div>
                  </Card>
                </div> : <></>
            }
          </div >
        </Card >
        {/* <Card className='d-flex flex-column mer-margin-top-12 mer-margin-left-12 mer-margin-right-12 mer-margin-bottom-0 mer-padding-left-12 mer-padding-top-12 teritary'>
          <div className='mer-font-bold mer-margin-bottom-8'>Interview Availability</div>
          <div className='d-flex col-md-4 col-lg-4 col-sm-12 col-12 col-xs-12 flex-column'>
            <Card className='secondary'>
              <div className='d-flex align-items-start justify-content-between'>
                <div className='mer-width-percentage-30'>Date</div>
                <div className='mer-width-percentage-5'>:</div>
                <div className='mer-width-percentage-65 mer-margin-right-8'>{!CommonMethods.isNullOrUndefined(candidateDetails?.preferences?.interview_availability?.available_date) ?
                  DateTimeFormats.DateFormat(candidateDetails?.preferences?.interview_availability?.available_date) : '-'}</div>
              </div>
              <div className='d-flex align-items-start justify-content-between'>
                <div className='mer-width-percentage-30'>Time</div>
                <div className='mer-width-percentage-5'>:</div>
                <div className='mer-width-percentage-65 mer-margin-right-8'>{!CommonMethods.isNullOrUndefined(candidateDetails?.preferences?.interview_availability?.interview_slot_name) ?
                  candidateDetails?.preferences?.interview_availability?.interview_slot_name : '-'}</div>
              </div>
            </Card>
          </div>
        </Card> */}
      </Card >
        :   <Card className='d-flex flex-column mer-padding-bottom-12'>   
        <SkeletonAddpopup></SkeletonAddpopup>
   </Card> }
    </div >
  )
}

export default SourcedProfileAdditionalDetails
