import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { confirmation_dialog, simple_dialog,status_dialog } from '../../utills/Alerts';
import * as Messages from '../../utills/constants/Messages';
import * as API from '../../utills/API';
import * as CommonMethods from "../../utills/CommonMethods";
import { useNavigate } from 'react-router-dom';
import * as Yup from "yup";
import {
    TextField,
    FormControl,
    Button,
    FormLabel,
    TextareaAutosize,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle
} from "@mui/material";
import { TEXT_FIELD_CONFIG } from '../../utills/constants/FormFieldFormats';
import create from "zustand";

export const add_notes_dialog = (open, action, candidate) => {
    AddNotesDialogStore.setState({ open, action, candidate });
};

const AddNotesDialogStore = create((set) => ({
    open: "",
    action: "",
    candidate: "",
    close: () =>
        set({
            open: false,
        }),
}));

function AddNotesForm(props) {
    const { open, close, candidate, action } = AddNotesDialogStore();
    const add_notes_form = useFormik({
        initialValues: {
            candidate_id: "",
            stage_id: "",
            status_id: "",
            reason: "",
            subject: ""
        },
        validationSchema: Yup.object().shape({
            candidate_id: Yup.string().nullable(),
            stage_id: Yup.string(),
            status_id: Yup.string(),
            reason: Yup.string().required(Messages?.REQUIRED_FIELD_ERROR),
            subject: Yup.string().required(Messages?.REQUIRED_FIELD_ERROR),
        }),
        onSubmit: (values) => {
            confirmation_dialog(Messages?.confirmation_messages?.add_notes_submit, () => {
                API.PostMethod('timelines/', { candidate_id: candidate._id, reason: add_notes_form.values.reason, subject: add_notes_form.values.subject }
                ).then((result) => {
                    if (action.success_message) {
                        let status_details = {
                            ...CommonMethods.STATUS_POPUP_DETAILS.success,
                            message: action.success_message,
                          };
                          status_dialog(status_details, () =>{return;}
                          );
                        // simple_dialog(action.success_message, () => {
                        // });
                    }
                    add_notes_form.resetForm();
                    close();
                }, (error) => {
                    
                });
            });
        },
    });

    return (
        <Dialog open={Boolean(open)} onClose={() => { add_notes_form.resetForm(); close() }} disableEscapeKeyDown={true}>
            <form className="d-grid flex-grow-1 mer-overflow mer-form" onSubmit={add_notes_form.handleSubmit}>
                <div >
                    <DialogTitle className='mer-theme-dialog-header-bg-tables'>
                        Add notes for profile  - <span >{CommonMethods.concateNames(candidate?.first_name, candidate?.middle_name, candidate?.last_name)}</span>
                    </DialogTitle>
                </div>
                <DialogContent>
                    <div className='d-flex flex-row flex-wrap justify-content-start align-items-start align-content-start'>
                        <div className="d-flex flex-column mer-margin-right-12 mer-margin-bottom-12 col-12">
                            <FormLabel id="">Subject</FormLabel>
                            <FormControl>
                                <TextField
                                    id={`subject`}
                                    name={`subject`}
                                    placeholder="Subject"
                                    {...TEXT_FIELD_CONFIG.OUTLINED}
                                    onChange={add_notes_form.handleChange}
                                    value={add_notes_form.values?.subject}
                                    error={add_notes_form.touched?.subject && add_notes_form.errors?.subject ? true : false}
                                    onBlur={add_notes_form.handleBlur}
                                />
                            </FormControl>
                            <div className='mer-error'>{add_notes_form.touched?.subject && add_notes_form?.errors?.subject ? <span className='error'>{add_notes_form?.errors?.subject}</span> : null}</div>
                        </div>
                        <div className="d-flex flex-column mer-margin-right-12 col-12">
                            <FormLabel id="">Comment</FormLabel>
                            <FormControl>
                                <TextareaAutosize
                                    minRows={3}
                                    id={`reason`}
                                    name={`reason`}
                                    value={add_notes_form?.values?.reason}
                                    label="Reason for academic gap"
                                    {...TEXT_FIELD_CONFIG.OUTLINED_LABEL}
                                    className="mer-theme-text-area-bg"
                                    onChange={(event) => {
                                        add_notes_form.setFieldValue(`reason`, event.target.value);
                                    }}
                                    onBlur={add_notes_form.handleBlur}
                                    placeholder="Comment"
                                    error={add_notes_form.touched?.reason && add_notes_form.errors?.reason ? true : false}
                                />
                            </FormControl>
                            <div className='mer-error'>{add_notes_form.touched?.reason && add_notes_form?.errors?.reason ? <span className='error'>{add_notes_form?.errors?.reason}</span> : null}</div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className="d-flex justify-content-end align-self-end">
                        <Button
                            variant="outlined"
                            className="mer-margin-right-12 outlinedcls"
                            onClick={() => {
                                add_notes_form.resetForm();
                                close();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            className=""
                            type="submit"
                        >
                            Submit
                        </Button>
                    </div>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default AddNotesForm