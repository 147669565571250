import {
    Button, FormControl, FormLabel, MenuItem, Select, TextareaAutosize
} from "@mui/material";
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from "yup";
import { confirmation_dialog, status_dialog } from '../../utills/Alerts';
import * as API from '../../utills/API';
import * as CommonMethods from "../../utills/CommonMethods";
import { SELECT_FIELD_CONFIG, TEXT_FIELD_CONFIG } from '../../utills/constants/FormFieldFormats';
import * as Messages from '../../utills/constants/Messages';
// import { useParams } from 'react-router-dom';
import useFormData from '../../utills/hooks/useFormData';


function CandidateRejectForm(props) {
    // const params = useParams();
    const formData = useFormData({ pathname: '/recruitee/candidates/' });
    // const { open, close, candidate, action, type, props } = RejectCandidateDialogStore();
    const [reject_categories, setRejectCatergories] = useState([]);
    const reject_candidate_form = useFormik({
        initialValues: {
            reason: '',
            category_id: ''
        },
        validationSchema: Yup.object().shape({
            reason: Yup.string().required(Messages?.REQUIRED_FIELD_ERROR),
            category_id: Yup.string().required(Messages?.REQUIRED_FIELD_ERROR),
        }),
        onSubmit: (values) => {
            let payload = values;
            payload['_id'] = props?._id;
            values.action = props?.action;
            if (props?.type === 1) {
                confirmation_dialog(Messages?.confirmation_messages?.reject_candidate, () => {
                    API.PostMethod('candidateRegistration/reject', values).then((res) => {
                        let status_details = {
                            ...CommonMethods.STATUS_POPUP_DETAILS.success,
                            message: res.message,
                        };
                        status_dialog(status_details, () => {
                            props?.onChange(true);
                            reject_candidate_form.resetForm();
                        });
                        // simple_dialog(res.message, () => {
                        //     props?.onChange(true);
                        //     reject_candidate_form.resetForm();
                        //     // close();
                        // }
                        //);
                    })
                });
            }
            else if (props?.type == 0) {
                API.PostMethod('webRegistration/acceptOrReject', values).then((res) => {
                    let status_details = {
                        ...CommonMethods.STATUS_POPUP_DETAILS.success,
                        message: res.message,
                    };
                    status_dialog(status_details, () => {
                        props?.onChange(true);
                        reject_candidate_form.resetForm();
                    });
                    // simple_dialog(res.message, () => {
                    //     props?.onChange(true);
                    //     reject_candidate_form.resetForm();
                    //     // close();
                    // }
                    // );
                })
            }
            else {
                let event_info = CommonMethods.getEventInfo(formData.form_id, formData.base_path,  'Referral-Reject');
                API.PostMethod('referral-registration/acceptOrReject', values, event_info).then((res) => {
                    let status_details = {
                      ...CommonMethods.STATUS_POPUP_DETAILS.success,
                      message: res.message,
                    };
                    status_dialog(status_details, async () => {
                      props.onChange(true);
                      reject_candidate_form.resetForm();
                    });
                  })
            }
        },
    });
    useEffect(() => {
        fetchRejectCategories();
    }, [])
    function fetchRejectCategories() {
      /*if(props?.type===0 || props?.type===1){
        API.GetMethod('rejectCategories').then(response => {
            setRejectCatergories(response?.data ? response.data : []);
        }, error => {
            setRejectCatergories([]);
        })
      }
      else{
        API.GetMethod('rejectCategories/referral').then(response => {
            console.log(response)
            setRejectCatergories(response?.data ? response.data : []);
        }, error => {
            setRejectCatergories([]);
        })
      }*/
      if(props?.type===0){
        API.GetMethod('rejectCategories/'+'sourced').then(response => {
            setRejectCatergories(response?.data ? response.data : []);
        }, error => {
            setRejectCatergories([]);
        })
      }
      else if(props?.type===1){
        API.GetMethod('rejectCategories/'+'portal').then(response => {
            setRejectCatergories(response?.data ? response.data : []);
        }, error => {
            setRejectCatergories([]);
        })
      }
      else if(props?.type===2){
        API.GetMethod('rejectCategories/'+'referral').then(response => {
            setRejectCatergories(response?.data ? response.data : []);
        }, error => {
            setRejectCatergories([]);
        })
      }
    }
    return (
        <form className="d-flex flex-column flex-grow-1 mer-padding-16 mer-overflow mer-form" onSubmit={reject_candidate_form.handleSubmit}>
            {/* <div className="mer-font-bold">
                Reject candidate - <span className="mer-color2">{props?.type === 1 ? CommonMethods.concateNames(props?.candidate?.first_name, props?.candidate?.middle_name, props?.candidate?.last_name) : props?.candidate?.full_name}</span>
            </div> */}
            <div className='d-flex flex-row flex-wrap justify-content-start align-items-start align-content-start mer-padding-bottom-12'>
                <div className="d-flex flex-column mer-margin-right-12 mer-margin-bottom-12 col-12">
                    <FormLabel id="">Category<sup className='mer-error'>*</sup> </FormLabel>
                    <FormControl size={SELECT_FIELD_CONFIG.OUTLINED.size} >
                        <Select className=''
                            name='category_id'
                            placeholder='How did you know about us?'
                            displayEmpty
                            {...SELECT_FIELD_CONFIG.OUTLINED}
                            MenuProps={SELECT_FIELD_CONFIG.MENU_PROPS}
                            value={reject_candidate_form.values?.category_id}
                            onChange={reject_candidate_form.handleChange}
                            onBlur={reject_candidate_form.handleBlur}
                            error={reject_candidate_form.touched?.category_id
                                && reject_candidate_form.errors?.category_id ? true : false}>
                            <MenuItem value=''>
                                <span className='selectdisabled'>Select category</span>
                            </MenuItem>
                            {!CommonMethods.isNullOrUndefined(reject_categories) && reject_categories.length > 0 &&
                                reject_categories.map((category) => {
                                    return (
                                        <MenuItem className='mer-select' key={category['_id']} value={category['_id']}>
                                            {category['reject_category_name']}
                                        </MenuItem>
                                    )
                                })}
                        </Select>
                    </FormControl>
                    <div className='mer-error'>{reject_candidate_form.touched?.category_id && reject_candidate_form?.errors?.category_id ?
                        reject_candidate_form?.errors?.category_id : null}</div>
                </div>
                <div className="d-flex flex-column mer-margin-right-12 col-12">
                    <FormLabel id="">Reason<sup className='mer-error'>*</sup></FormLabel>
                    <FormControl>
                        <TextareaAutosize
                            minRows={3}
                            id={`reason`}
                            name={`reason`}
                            className="mer-theme-text-area-bg"
                            value={reject_candidate_form?.values?.reason}
                            placeholder="Reason for rejection"
                            {...TEXT_FIELD_CONFIG.OUTLINED}
                            onChange={reject_candidate_form.handleChange}
                            onBlur={reject_candidate_form.handleBlur}
                            error={reject_candidate_form.touched?.reason && reject_candidate_form.errors?.reason ? true : false}
                        />
                    </FormControl>
                    <div className='mer-error'>{reject_candidate_form.touched?.reason && reject_candidate_form?.errors?.reason ?
                        reject_candidate_form?.errors?.reason : null}</div>
                </div>
            </div>
            <div className="d-flex justify-content-end align-self-end mer-margin-top-12">
                <Button variant="outlined" className="mer-margin-right-12 outlinedcls"
                    onClick={() => {
                        reject_candidate_form.resetForm();
                        // close();
                        props.onChange(false);
                    }}
                >
                    Cancel
                </Button>
                <Button variant="contained" className="mer-theme-danger-button" type="submit">
                    Reject
                </Button>
            </div>
        </form>
    )
}

export default CandidateRejectForm
