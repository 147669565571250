import React, { useState } from 'react'
import PincodeData from './PincodeData';
import PincodeForm from './PincodeForm';

function PincodeView() {
    const [isEdit, setIsEdit] = useState(false);

    return (
      <div className="d-flex flex-grow-1 flex-column mer-overflow mer-overflow-x-hidden">
        {!isEdit ? (
          <PincodeData onChange={() => setIsEdit(true)} />
        ) : (
          <PincodeForm onChange={() => setIsEdit(false)} />
        )}
      </div>
    );
}

export default PincodeView
