import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, Typography } from "@mui/material";
import * as CommonMethods from "../../../utills/CommonMethods";
import * as API from "../../../utills/API";
import * as DateTimeFormats from "../../../utills/DateTimeFormats";
// import useFormData from '../../../utills/hooks/useFormData'
import SkeletonRegpopup from "../../../components/Skeletons/SkeletonRegpopup";
import { CHOOSEN_NOT_TO_DISCLOSE } from "../../../utills/constants/Messages";
function SourcedProfileBasicDetails(props) {
  const [candidateDetails, setCandidateDetails] = useState({});
  const [isLoaded, setIsloaded] = useState(false);

  //const params = useParams();
  // const formData = useFormData();

  // let navigate = useNavigate();
  useEffect(() => {
    fetchRegistrationDetails();
  }, []);
  const fetchRegistrationDetails = () => {
    if (!CommonMethods.isNullOrUndefined(props?._id)) {
      if (props?.type === "archive") {
        setIsloaded(false);
        API.GetMethod("webRegistration/web/sourced/" + props?._id).then(
          (response) => {
            setIsloaded(true);
            setCandidateDetails(response.data);
          }
        );
      } else {
        setIsloaded(false);
        API.GetMethod("webRegistration/web/" + props?._id).then((response) => {
          setIsloaded(true);
          setCandidateDetails(response.data[0]);
        });
      }
    }
  };

  return (
    <div className="d-flex flex-column flex-grow-1 mer-overflow mer-overflow-x-hidden">
      {isLoaded ? (
        <Card className="d-flex flex-column mer-padding-bottom-12">
          <div className="d-flex flex-row align-items-center justify-content-between mer-margin-left-16 mer-margin-right-16 mer-margin-top-16">
            {/* <div className='mer-font-bold'>Registration Details</div> */}
            <Typography variant="h6">Basic Details</Typography>
            {/* <Tooltip title={formData?.can_edit ? '' : Messages?.NO_EDIT} >
            <div>
              <Button disabled={formData?.can_edit ? false : true} variant='contained'  className=''
                onClick={() => {
                  // props.onChange();
                  // navigate(StaticRouterLinks.registration_details_form + params.id) 
                }}>Edit</Button>
            </div>
          </Tooltip> */}
          </div>
          <Card className="d-flex flex-column mer-margin-top-12 mer-margin-left-12 mer-margin-right-12 mer-margin-bottom-0  mer-padding-left-12 mer-padding-top-12 teritary">
            <div className="mer-font-bold mer-margin-bottom-8">
              Basic Information
            </div>
            <div className="d-flex flex-wrap flex-row">
              <div className="d-flex flex-column col-md-6 col-lg-4 col-sm-12 col-12 col-xs-12">
                <Card className="flex-grow-1 secondary">
                  <div className="mer-font-bold  mer-margin-bottom-8">
                    In contact to Accordion (formerly Merilytics)
                  </div>
                  <div className="d-flex flex-row align-items-start">
                    <div
                      className="mer-width-percentage-45 mer-margin-right-8"
                      title="Employee Name"
                    >
                      Employee Name
                    </div>
                    <div className="mer-width-percentage-5">:</div>
                    <div
                      className="mer-width-percentage-50"
                      title={
                        candidateDetails?.personal_details
                          ?.contact_to_merilytics?.known_employee_name
                      }
                    >
                      {!CommonMethods.isNullOrUndefined(
                        candidateDetails?.personal_details
                          ?.contact_to_merilytics?.known_employee_name
                      )
                        ? candidateDetails?.personal_details
                            ?.contact_to_merilytics?.known_employee_name
                        : !CommonMethods.isNullOrUndefined(
                            candidateDetails?.personal_details
                              ?.contact_to_merilytics?.knows_anyone
                          )
                        ? "NA"
                        : "-"}
                    </div>
                  </div>
                  <div className="d-flex flex-row align-items-start">
                    <div
                      className="mer-width-percentage-45 mer-margin-right-8"
                      title="Employee ID"
                    >
                      Employee ID
                    </div>
                    <div className="mer-width-percentage-5">:</div>
                    <div
                      className="mer-width-percentage-50"
                      title={
                        candidateDetails?.personal_details
                          ?.contact_to_merilytics?.known_employee_id
                      }
                    >
                      {!CommonMethods.isNullOrUndefined(
                        candidateDetails?.personal_details
                          ?.contact_to_merilytics?.known_employee_id
                      )
                        ? candidateDetails?.personal_details
                            ?.contact_to_merilytics?.known_employee_id
                        : !CommonMethods.isNullOrUndefined(
                            candidateDetails?.personal_details
                              ?.contact_to_merilytics?.knows_anyone
                          )
                        ? "NA"
                        : "-"}
                    </div>
                  </div>
                  <div className="d-flex flex-row align-items-start">
                    <div
                      className="mer-width-percentage-45 mer-margin-right-8"
                      title="Last Applied"
                    >
                      Last Applied
                    </div>
                    <div className="mer-width-percentage-5">:</div>
                    <div
                      className="mer-width-percentage-50"
                      title={
                        candidateDetails?.personal_details
                          ?.contact_to_merilytics?.previous_applied_date
                          ? DateTimeFormats.DateFormat(
                              candidateDetails?.personal_details
                                ?.contact_to_merilytics?.previous_applied_date
                            )
                          : ""
                      }
                    >
                      {!CommonMethods.isNullOrUndefined(
                        candidateDetails?.personal_details
                          ?.contact_to_merilytics?.previous_applied_date
                      )
                        ? DateTimeFormats.DateFormat(
                            candidateDetails?.personal_details
                              ?.contact_to_merilytics?.previous_applied_date
                          )
                        : !CommonMethods.isNullOrUndefined(
                            candidateDetails?.personal_details
                              ?.contact_to_merilytics?.is_applied_before
                          )
                        ? "NA"
                        : "-"}
                    </div>
                  </div>
                  <div className="d-flex flex-row align-items-start">
                    <div className="mer-width-percentage-45 mer-margin-right-8">
                      How did you know about us?
                    </div>
                    <div className="mer-width-percentage-5">:</div>
                    <div
                      className="mer-width-percentage-50"
                      title={
                        candidateDetails?.personal_details
                          ?.contact_to_merilytics?.known_source_name
                      }
                    >
                      {!CommonMethods.isNullOrUndefined(
                        candidateDetails?.personal_details
                          ?.contact_to_merilytics?.known_source_name
                      )
                        ? candidateDetails?.personal_details
                            ?.contact_to_merilytics?.known_source_name
                        : !CommonMethods.isNullOrUndefined(
                            candidateDetails?.personal_details
                              ?.contact_to_merilytics?.knows_anyone
                          )
                        ? "NA"
                        : "-"}
                    </div>
                  </div>
                </Card>
              </div>
              <div className="d-flex flex-column col-md-6 col-lg-4 col-sm-12 col-12 col-xs-12">
                <Card className="flex-grow-1 secondary">
                  <div className="mer-font-bold mer-margin-bottom-8 ">
                    Other details
                  </div>
                  <div className="d-flex flex-row align-items-start">
                    <div
                      className="mer-width-percentage-45 mer-margin-right-8"
                      title="Date of birth"
                    >
                      Date of birth
                    </div>
                    <div className="mer-width-percentage-5">:</div>
                    <div
                      className="mer-width-percentage-50"
                      title={candidateDetails?.personal_details?.dob}
                    >
                      {!CommonMethods.isNullOrUndefined(
                        candidateDetails?.personal_details?.dob
                      )
                        ? DateTimeFormats.DateFormat(
                            candidateDetails?.personal_details?.dob
                          )
                        : "-"}
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </Card>
          {candidateDetails?.is_experienced ? (
            <Card className="d-flex flex-column mer-margin-top-12 mer-margin-left-12 mer-margin-right-12 mer-margin-bottom-0  mer-padding-left-12 mer-padding-top-12 teritary">
              <div className="mer-font-bold mer-margin-bottom-8">
                Full-Time Work Experience
              </div>
              <div className="d-flex flex-grow-1 flex-row flex-wrap mer-overflow ">
                {!CommonMethods.isNullOrUndefined(
                  candidateDetails?.experience_details?.most_recent_experience
                ) && (
                  <div className="d-flex flex-column col-md-6 col-lg-4 col-sm-6 col-xs-12 col-12">
                    <Card className="d-flex flex-column mer-margin-right-12 mer-margin-bottom-12 secondary">
                      <div className="d-flex flex-row justify-content-between mer-margin-bottom-10">
                        <div className="mer-font-bold">
                          {
                            candidateDetails?.experience_details
                              ?.most_recent_experience?.company_name
                          }
                        </div>
                        <div className="mer-font-bold mer-font-size-12">
                          {DateTimeFormats.MonthYearFormat(
                            candidateDetails?.experience_details
                              ?.most_recent_experience?.from_date
                          )}{" "}
                          -
                          {candidateDetails?.experience_details
                            ?.most_recent_experience?.is_current_company
                            ? " Current"
                            : DateTimeFormats.MonthYearFormat(
                                candidateDetails?.experience_details
                                  ?.most_recent_experience?.to_date
                              )}
                        </div>
                      </div>
                      {candidateDetails?.experience_details
                        ?.most_recent_experience?.is_current_company ? (
                        <div className="d-flex flex-column">
                          <Card className="chipsTheme mer-font-size-12 mer-margin-0 mer-padding-4 mer-width-max-content">
                            CURRENT COMPANY
                          </Card>
                          <div className="d-flex flex-row align-items-start">
                            <div className="mer-width-percentage-55">
                              Are you in notice period ?
                            </div>
                            <div className="mer-width-percentage-5">:</div>
                            <div className="mer-width-percentage-40">
                              {candidateDetails?.experience_details
                                ?.most_recent_experience
                                ?.is_serving_notice_period
                                ? "Yes"
                                : "No"}
                            </div>
                          </div>
                          {candidateDetails?.experience_details
                            ?.most_recent_experience
                            ?.is_serving_notice_period ? (
                            <div className="d-flex flex-row">
                              <div className="mer-width-percentage-55">
                                Last working day
                              </div>
                              <div className="mer-width-percentage-5">:</div>
                              <div className="mer-width-percentage-40">
                                {DateTimeFormats.DateFormat(
                                  candidateDetails?.experience_details
                                    ?.most_recent_experience?.last_working_day
                                )}
                              </div>
                            </div>
                          ) : (
                            <div className="d-flex flex-row">
                              <div className="mer-width-percentage-55">
                                Number of days in notice period
                              </div>
                              <div className="mer-width-percentage-5">:</div>
                              <div className="mer-width-percentage-40">
                                {
                                  candidateDetails?.experience_details
                                    ?.most_recent_experience
                                    ?.notice_period_in_days
                                }
                              </div>
                            </div>
                          )}
                        </div>
                      ) : null}
                      <div className="d-flex flex-row">
                        <div className="mer-width-percentage-55">
                          Designation
                        </div>
                        <div className="mer-width-percentage-5">:</div>
                        <div className="mer-width-percentage-40">
                          {
                            candidateDetails?.experience_details
                              ?.most_recent_experience?.designation_name
                          }
                        </div>
                      </div>
                      <div className="d-flex flex-row">
                        <div className="mer-width-percentage-55">Country</div>
                        <div className="mer-width-percentage-5">:</div>
                        <div className="mer-width-percentage-40">
                          {
                            candidateDetails?.experience_details
                              ?.most_recent_experience?.country_name
                          }
                        </div>
                      </div>
                      <div className="d-flex flex-row">
                        <div className="mer-width-percentage-55">State</div>
                        <div className="mer-width-percentage-5">:</div>
                        <div className="mer-width-percentage-40">
                          {
                            candidateDetails?.experience_details
                              ?.most_recent_experience?.state_name
                          }
                        </div>
                      </div>
                      <div className="d-flex flex-row">
                        <div className="mer-width-percentage-55">City</div>
                        <div className="mer-width-percentage-5">:</div>
                        <div className="mer-width-percentage-40">
                          {
                            candidateDetails?.experience_details
                              ?.most_recent_experience?.city_name
                          }
                        </div>
                      </div>
                    </Card>
                  </div>
                )}
                {candidateDetails?.experience_details?.experiences?.length >
                  0 &&
                  candidateDetails?.experience_details?.experiences?.map(
                    (experience, index) => (
                      <div
                        key={"experience" + index}
                        className="d-flex flex-column col-md-6 col-lg-4 col-sm-6 col-xs-12 col-12"
                      >
                        <Card className="d-flex flex-column mer-margin-right-12 mer-margin-bottom-12 secondary">
                          <div className="d-flex flex-row justify-content-between mer-margin-bottom-10">
                            <div className="mer-font-bold">
                              {experience.company_name}
                            </div>
                            <div className="mer-font-bold mer-font-size-12">
                              {DateTimeFormats.MonthYearFormat(
                                experience.from_date
                              )}{" "}
                              -{" "}
                              {DateTimeFormats.MonthYearFormat(
                                experience.to_date
                              )}
                            </div>
                          </div>
                          <div className="d-flex flex-row">
                            <div className="mer-width-percentage-45">
                              Designation
                            </div>
                            <div className="mer-width-percentage-5">:</div>
                            <div className="mer-width-percentage-50">
                              {experience.designation_name}
                            </div>
                          </div>
                          <div className="d-flex flex-row">
                            <div className="mer-width-percentage-45">
                              Country
                            </div>
                            <div className="mer-width-percentage-5">:</div>
                            <div className="mer-width-percentage-50">
                              {experience.country_name}
                            </div>
                          </div>
                          <div className="d-flex flex-row">
                            <div className="mer-width-percentage-45">State</div>
                            <div className="mer-width-percentage-5">:</div>
                            <div className="mer-width-percentage-50">
                              {experience.state_name}
                            </div>
                          </div>
                          <div className="d-flex flex-row">
                            <div className="mer-width-percentage-45">City</div>
                            <div className="mer-width-percentage-5">:</div>
                            <div className="mer-width-percentage-50">
                              {experience.city_name}
                            </div>
                          </div>
                        </Card>
                      </div>
                    )
                  )}
              </div>
              {CommonMethods.isNullOrUndefined(
                candidateDetails?.experience_details?.employment_gap_reason
              ) ? (
                ""
              ) : (
                <div className="col-md-8 col-sm-12 col-xs-12 col-lg-6 col-12 ">
                  <Card className="secondary">
                    <div className="mer-font-bold">
                      Reason for Employment Gaps
                    </div>
                    <div>
                      {
                        candidateDetails?.experience_details
                          ?.employment_gap_reason
                      }
                    </div>
                  </Card>
                </div>
              )}
            </Card>
          ) : null}
          <Card className="d-flex flex-column mer-margin-top-12 mer-margin-left-12 mer-margin-right-12 mer-margin-bottom-0 mer-padding-left-12 mer-padding-top-12 teritary">
            <div className="mer-font-bold mer-margin-bottom-8">
              Academic Details
            </div>
            <div className="d-flex flex-grow-1 flex-row flex-wrap mer-overflow">
              {candidateDetails?.academic_details?.degree_details?.map(
                (academic, index) => (
                  <div
                    key={"Academic" + index}
                    className="d-flex flex-column col-md-4 col-lg-4 col-sm-6 col-xs-12 col-12"
                  >
                    <Card className="secondary d-flex flex-column flex-grow-1">
                      <div className="d-flex flex-row justify-content-between mer-margin-bottom-10">
                        <div className="mer-font-bold">
                          {academic.degree_name}
                        </div>
                        <div className="mer-font-bold mer-font-size-12">
                          {academic.from_id} - {academic.to_id}
                        </div>
                      </div>
                      <div className="d-flex flex-row">
                        <div className="mer-width-percentage-40">College</div>
                        <div className="mer-width-percentage-5">:</div>
                        <div className="mer-width-percentage-45">
                          {" "}
                          {academic.college_name}
                        </div>
                      </div>
                      <div className="d-flex flex-row">
                        <div className="mer-width-percentage-40">Branch</div>
                        <div className="mer-width-percentage-5">:</div>
                        <div className="mer-width-percentage-45">
                          {" "}
                          {academic.branch_name}
                        </div>
                      </div>
                      <div className="d-flex flex-row">
                        <div className="mer-width-percentage-40">
                          {academic?.grade_display_name}
                        </div>
                        <div className="mer-width-percentage-5">:</div>
                        <div className="mer-width-percentage-45">
                          {" "}
                          {academic?.percentage_cgpa
                            ? academic?.percentage_cgpa +
                              "/" +
                              academic?.grade_max_value
                            : "-"}
                        </div>
                      </div>
                      <div className="d-flex flex-row">
                        <div className="mer-width-percentage-40">Location</div>
                        <div className="mer-width-percentage-5">:</div>
                        <div className="mer-width-percentage-45">
                          {" "}
                          {academic?.location || "-"}
                        </div>
                      </div>
                    </Card>
                  </div>
                )
              )}
              {!CommonMethods.isNullOrUndefined(
                candidateDetails?.academic_details?.inter_details
              ) &&
              Object.keys(candidateDetails?.academic_details?.inter_details)
                .length ? (
                <div className="d-flex flex-column col-md-4 col-lg-4 col-sm-6 col-xs-12 col-12">
                  <Card className="secondary flex-grow-1 d-flex flex-column">
                    <div className="d-flex flex-row justify-content-between mer-margin-bottom-10">
                      <div className="mer-font-bold">12th / Diploma</div>
                      <div className="mer-font-bold mer-font-size-12">
                        {
                          candidateDetails?.academic_details?.inter_details
                            ?.passed_year_id
                        }
                      </div>
                    </div>
                    <div className="d-flex flex-row">
                      <div className="mer-width-percentage-40">Board</div>
                      <div className="mer-width-percentage-5">:</div>
                      <div className="mer-width-percentage-45">
                        {" "}
                        {candidateDetails?.academic_details?.inter_details
                          ?.board_name
                          ? candidateDetails?.academic_details?.inter_details
                              ?.board_name
                          : "-"}
                      </div>
                    </div>
                    <div className="d-flex flex-row">
                      <div className="mer-width-percentage-40">
                        {
                          candidateDetails?.academic_details?.inter_details
                            ?.grade_display_name
                        }
                      </div>
                      <div className="mer-width-percentage-5">:</div>
                      <div className="mer-width-percentage-45">
                        {" "}
                        {candidateDetails?.academic_details?.inter_details
                          ?.percentage_cgpa
                          ? candidateDetails?.academic_details?.inter_details
                              ?.percentage_cgpa +
                            "/" +
                            candidateDetails?.academic_details?.inter_details
                              ?.grade_max_value
                          : "-"}
                      </div>
                    </div>
                    <div className="d-flex flex-row">
                      <div className="mer-width-percentage-40">
                        School/College
                      </div>
                      <div className="mer-width-percentage-5">:</div>
                      <div className="mer-width-percentage-45">
                        {" "}
                        {candidateDetails?.academic_details?.inter_details
                          ?.school_college_name
                          ? candidateDetails?.academic_details?.inter_details
                              ?.school_college_name
                          : "-"}
                      </div>
                    </div>
                    <div className="d-flex flex-row">
                      <div className="mer-width-percentage-40">Location</div>
                      <div className="mer-width-percentage-5">:</div>
                      <div className="mer-width-percentage-45">
                        {" "}
                        {candidateDetails?.academic_details?.inter_details
                          ?.location
                          ? candidateDetails?.academic_details?.inter_details
                              ?.location
                          : "-"}
                      </div>
                    </div>
                  </Card>
                </div>
              ) : (
                ""
              )}
              {!CommonMethods.isNullOrUndefined(
                candidateDetails?.academic_details?.tenth_details
              ) &&
              Object.keys(candidateDetails?.academic_details?.tenth_details)
                .length ? (
                <div className="d-flex flex-column col-md-4 col-lg-4 col-sm-6 col-xs-12 col-12">
                  <Card className="secondary flex-grow-1 d-flex flex-column">
                    <div className="d-flex flex-row justify-content-between mer-margin-bottom-10">
                      <div className="mer-font-bold">10th / SSC</div>
                      <div className="mer-font-bold mer-font-size-12">
                        {
                          candidateDetails?.academic_details?.tenth_details
                            ?.passed_year_id
                        }
                      </div>
                    </div>
                    <div className="d-flex flex-row">
                      <div className="mer-width-percentage-40">Board</div>
                      <div className="mer-width-percentage-5">:</div>
                      <div className="mer-width-percentage-45">
                        {" "}
                        {candidateDetails?.academic_details?.tenth_details
                          ?.board_name
                          ? candidateDetails?.academic_details?.tenth_details
                              ?.board_name
                          : "-"}
                      </div>
                    </div>
                    <div className="d-flex flex-row">
                      <div className="mer-width-percentage-40">
                        {
                          candidateDetails?.academic_details?.tenth_details
                            ?.grade_display_name
                        }
                      </div>
                      <div className="mer-width-percentage-5">:</div>
                      <div className="mer-width-percentage-45">
                        {" "}
                        {candidateDetails?.academic_details?.tenth_details
                          ?.percentage_cgpa
                          ? candidateDetails?.academic_details?.tenth_details
                              ?.percentage_cgpa +
                            "/" +
                            candidateDetails?.academic_details?.tenth_details
                              ?.grade_max_value
                          : "-"}
                      </div>
                    </div>
                    <div className="d-flex flex-row">
                      <div className="mer-width-percentage-40">
                        School/College
                      </div>
                      <div className="mer-width-percentage-5">:</div>
                      <div className="mer-width-percentage-45">
                        {" "}
                        {candidateDetails?.academic_details?.tenth_details
                          ?.school_college_name
                          ? candidateDetails?.academic_details?.tenth_details
                              ?.school_college_name
                          : "-"}
                      </div>
                    </div>
                    <div className="d-flex flex-row">
                      <div className="mer-width-percentage-40">Location</div>
                      <div className="mer-width-percentage-5">:</div>
                      <div className="mer-width-percentage-45">
                        {" "}
                        {candidateDetails?.academic_details?.tenth_details
                          ?.location
                          ? candidateDetails?.academic_details?.tenth_details
                              ?.location
                          : "-"}
                      </div>
                    </div>
                  </Card>
                </div>
              ) : (
                ""
              )}
            </div>
            {candidateDetails?.academic_details?.is_academic_gap_exists ? (
              <div className="col-md-8 col-sm-12 col-xs-12 col-lg-8 col-12">
                <Card className="secondary">
                  <div className="mer-font-bold">Reason for Academic Gaps</div>
                  <div>
                    {candidateDetails?.academic_details?.academic_gap_reason}
                  </div>
                </Card>
              </div>
            ) : (
              ""
            )}
          </Card>
          <Card className="d-flex flex-column mer-margin-top-12 mer-margin-left-12 mer-margin-right-12 mer-margin-bottom-0 mer-padding-left-12 mer-padding-top-12 teritary">
            <div className="mer-font-bold mer-margin-bottom-8">
              Rank and Score Card Details
            </div>
            <div className="d-flex flex-grow-1 flex-row flex-wrap mer-overflow">
              {candidateDetails?.rank_score_details?.length > 0 ? (
                candidateDetails?.rank_score_details?.map((entrance, index) => (
                  <div
                    key={"rank_cards" + index}
                    className="d-flex flex-row col-md-6 col-lg-4 col-sm-12 col-xs-12 mer-mobile-width-percentage-100"
                  >
                    <Card
                      key={"rankcard" + index}
                      className="d-flex flex-grow-1 flex-column secondary  mer-margin-right-12 mer-margin-bottom-12"
                    >
                      <div className="d-flex flex-row justify-content-between mer-margin-bottom-10">
                        <div className="mer-font-bold">
                          {entrance.examination_name}
                        </div>
                      </div>
                      <div className="d-flex flex-row">
                        <div className="mer-width-percentage-65">Year</div>
                        <div className="mer-width-percentage-5">:</div>
                        <div className="mer-width-percentage-30">
                          {entrance.year_id}
                        </div>
                      </div>
                      <div className="d-flex flex-row">
                        <div className="mer-width-percentage-65">
                          {entrance.result_type
                            ? entrance.result_type
                            : "Rank / Score"}
                        </div>
                        <div className="mer-width-percentage-5">:</div>
                        <div className="mer-width-percentage-30">
                          {" "}
                          {entrance?.rank_score ?? CHOOSEN_NOT_TO_DISCLOSE}
                        </div>
                      </div>
                      {entrance.max_validation && (
                        <div className="d-flex flex-row">
                          <div className="mer-width-percentage-65">
                            Max Validation
                          </div>
                          <div className="mer-width-percentage-5">:</div>
                          <div className="mer-width-percentage-30">
                            {" "}
                            {entrance.max_validation}
                          </div>
                        </div>
                      )}
                    </Card>
                  </div>
                ))
              ) : (
                <div>Rank and score card details are not available</div>
              )}
            </div>
          </Card>
        </Card>
      ) : (
        <Card className="d-flex flex-column">
          <SkeletonRegpopup></SkeletonRegpopup>
        </Card>
      )}
    </div>
  );
}

export default SourcedProfileBasicDetails;
