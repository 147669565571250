

export const StaticRouterLinks = {

  "domains_table": "/recruitee/admin/domains/"
  , "domain_form": "/recruitee/admin/domain/"
  , "departments_table": "/recruitee/admin/departments/"
  , "department_form": "/recruitee/admin/department/"
  , "sub-departments_table": "/recruitee/admin/sub-departments/"
  , "sub_department_form": "/recruitee/admin/sub-department/"
  , "users_table": "/recruitee/admin/users/"
  , "user_form": "/recruitee/admin/user/"
  , "desigantions_table": "/recruitee/admin/designations/"
  , "designation_form": "/recruitee/admin/designation/"
  , "resume_sources_table": "/recruitee/admin/resume-sources/"
  , "resume_source_form": "/recruitee/admin/resume-source/"
  , "candidates_registration_table": "/recruitee/candidates"
  , "registration_details": "/recruitee/candidate/registration-details/"
  , "candidate_overview": "/recruitee/candidate/one-pager/"
  // , "registration_details_form": "/recruitee/candidate/registration-details-form/"
  , "user_roles_table": "/recruitee/admin/roles"
  , "user_role_form": "/recruitee/admin/role/"
  , "colleges_table": "/recruitee/admin/colleges"
  , "college_form": "/recruitee/admin/college/"
  , "degrees_table": "/recruitee/admin/degrees"
  , "degree_form": "/recruitee/admin/degree/"
  , "branches_table": "/recruitee/admin/branches"
  , "branch_form": "/recruitee/admin/branch/"
  , "entrances_table": "/recruitee/admin/entrance-exams"
  , "entrance_form": "/recruitee/admin/entrance-exam/"
  , "email_template_form": "/recruitee/admin/template"
  , "email_template_table": "/recruitee/admin/templates"
  , "events_table": "/recruitee/admin/events"
  , "event_form": "/recruitee/admin/event/"
  , "stages_table": "/recruitee/admin/stages"
  , "stage_form": "/recruitee/admin/stage/"
  , "status_table": "/recruitee/admin/statuses/"
  , "status_form": "/recruitee/admin/status/"
  , "pincodes_table": "/recruitee/admin/pincodes/"
  , "pincode_form": "/recruitee/admin/pincode/"
  , "tests": "/recruitee/candidate/tests/"
  , "cases_table": "/recruitee/admin/cases/"
  , "case_form": "/recruitee/admin/case/"
  , "schedule_interviews": "/recruitee/candidate/schedule-interviews/"
  // ,"home_page":"/recruitee/home"
  , "actions_table": "/recruitee/admin/actions"
  , "action_form": "/recruitee/admin/action/"
  , "global-settings": "/recruitee/admin/global-settings/"
  // , "test_edit": "/recruitee/candidate/test-form/"
  , "history": "/recruitee/candidate/profile/history/"
  , "skills": "/recruitee/candidate/profile/skills/"
  , "personal_details": "/recruitee/candidate/profile/personal-details/"
  // , "personal_details_edit": "/recruitee/candidate/profile/personal-details-form/"
  , "preferences_view": "/recruitee/candidate/preferences/"
  // , "preferences_edit": "/recruitee/candidate/preferences-form/"
  , "documents_view": "/recruitee/candidate/documents/"
  // , "documents_edit": "/recruitee/candidate/documents-form/"
  , "proposal_view": "/recruitee/candidate/proposal/"
  // ,"proposal_edit": "/recruitee/candidate/proposal-form/"
  , "roles_to_be_considered": "/recruitee/candidate/roles-to-be-considered/"
  , "my_comments": "/recruitee/candidate/comments/"
  , "all_interviews": "/recruitee/candidate/view-all-comments/"
  , "interviewer_screen": "/recruitee/interviewer/candidates/"
  , "one_pager_view": "/recruitee/one-pager/"
  ,  "tags_table": "/recruitee/admin/tags"
  , "tag_form": "/recruitee/admin/tag/"

}