import { React, createContext, useState } from "react";

import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material";
import Routing from "./Routing";
import {
  SimpleDialog,
  ConfirmationDialog,
  TrackStatus,
  AlertDialog,
  StatusDialog,
  RolesToBeConsideredForDialog,
  ProposalDialog,
  AllDocumentsDownloadDialog,
  OTPDialog,
} from "./utills/Alerts";
import Loader from "./components/Loader";

import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import "./App.scss";
import "./themes/dark.scss";
import "./themes/light.scss";
import { CssBaseline } from "@mui/material";
import { RECRUITEE_MENU } from "./utills/constants/SideMenu";
import { useEffect } from "react";
import * as API from "./utills/API";
import { isNullOrUndefined } from "./utills/CommonMethods";
import AddNotesForm from "./pages/candidates/AddNotesForm";
import RequestDocumentsForm from "./pages/candidates/documents/RequestDocumentsForm";
import { useNavigate } from "react-router-dom";
// import SetupInterceptors from "./utills/API-interceptor";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import Login from "./pages/Login";
import OfferLetterForm from "./pages/candidates/OfferLetterForm";
import DownloadAllDocuments from "./pages/candidates/documents/DownloadAllDocuments";
import SyncDatatoEmployeeDBForm from "./pages/candidates/SyncDatatoEmployeeDBForm";

export const ThemeContext = createContext(null);
export const DisableFormsContext = createContext([]);
export const LoggedInUserContext = createContext({});
export const LoadingContext = createContext({});
export const SideMenuContext = createContext(null);
const breakpoints = createBreakpoints({});

// function AxiosInterceptorNavigate() {
//   let navigate = useNavigate();
//   SetupInterceptors(navigate);
//   return <></>;
// }

function App() {
  // window
  //   .matchMedia("(prefers-color-scheme: dark)")
  //   .addEventListener("change", (e) => {
  //     setCurrentTheme(e.matches ? "dark" : "light");
  //   });

  const selectedTheme = localStorage.getItem("selectedTheme");

  const [currentTheme, setCurrentTheme] = useState(
    // localStorage.getItem("mer-auth-key")
    //   ? localStorage.getItem("selectedTheme")
    //     ? localStorage.getItem("selectedTheme")
    //     : window.matchMedia &&
    //       window.matchMedia("(prefers-color-scheme: dark)").matches
    //     ? "dark"
    //     : "light"
    //   : 
    "light"
  );
  // const [loggedInUser, setLoggedInUser] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [sideMenu, setSideMenu] = useState({
    open: false,
    sideMenuItems: RECRUITEE_MENU,
  });
  //const [disableForms,setDisableForms] = useState([]);

  // const getUserContext = () => {
  //   API.GetMethod("getUserContext").then((user_data) => {
  //     setLoggedInUserContext(user_data ? user_data.data : null);
  //   });
  // };

  // const setLoggedInUserContext = (data) => {
  //   setLoggedInUser(data);
  // };

  // useEffect(() => {
  //   getUserContext();
  // }, []);
  useEffect(() => {
    const collection = document.getElementsByTagName("body");

    collection[0].id = currentTheme;
  }, [currentTheme]);

  const themes = {
    light: {
      palette: {
        mode: "light",
        primary: {
          main: "#1F242F",
          secondary: "#D3D3D3",
          //  contrastText: '#000000'
          //  contrastText: '#000000'
        },
        secondary: {
          main: "#ffffff", // Secondary using for buttons
        },
        text: {
          primary: "#000000",
        },
        MuiCardContent: {
          primary: "#e7e0ec",
        },
        background: {
          // default: '#F5F5F5', // default packground of page
          // paper: '#ffffff', // paper background color
          default: "#FFFFFF",
        },
        action: {
          //  active: "#E8DEF8" // For active links
          active: "#1F242F",
        },
      },
      typography: {
        h6: {
          fontSize: "24px",
          fontWeight: "600",
        },
        button: {
          //fontWeight:500
          color: "#ffffff",
          textTransform: "none",
        },
      },
      components: {
        MuiFormLabel: {
          styleOverrides: {
            root: {
              fontSize: "12px !important",
              fontWeight: "600",
              color: "black",
            },
          },
        },
        MuiTypography: {
          styleOverrides: {
            root: {
              // color: "#1F242F",
              // fontWeight: '600',
              "&.bluetext": { //TO-DO: Need to comment it
                color: "#6750A4",
                fontSize: "16px",
                fontWeight: "500",
              },
            },
          },
        },
        MuiSelect: {
          styleOverrides: {
            root: {
              height: "35px",
            },
          },
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              "&.globalsearch": {
                background: "#E5E5E5",
                borderRadius: "34px",
                color: "#1F242F",
                [breakpoints.up("lg")]: {
                  width: "400px",
                },
              },
              "&.tablesearch": {
                height: "35px",
                [breakpoints.up("md")]: {
                  width: "250px",
                },

                background: "#E5E5E5",
                borderRadius: "34px",
                color: "#1F242F",
                padding: "0px 16px",
              },
              "&.MuiOutlinedInput-root": {
                padding: "0px 4px 0px 0px",
              },
            },
            input: {
              "&.MuiOutlinedInput-input": {
                paddingLeft: "6px",
                "&.MuiOutlinedInput-input": {
                  paddingTop: "6px",
                },
              },
            },
          },
        },
        MuiTablePagination: {
          styleOverrides: {
            select: {
              paddingRight: "20px !important",
              marginRight: "15px !important",
            },
          },
        },
        MuiDialogActions: {
          styleOverrides: {
            root: {
              "&.confirm-action-dialog-box": {
                justifyContent: "center !important",
              },
            },
          },
        },
        MuiToolbar: {
          styleOverrides: {
            root: {
              background: "#ffffff",
              color: "#000000",
              "&.MuiTablePagination-toolbar": {
                minHeight: "22px",
                background: "#818995",
                margin: "10px",
                height: "40px",
                color: "#FFFFFF",
              },
            },
          },
        },
        MuiTableRow: {
          styleOverrides: {
            root: {
              // background:
              //   "linear-gradient(0deg, rgba(86, 85, 168, 0.05), rgba(86, 85, 168, 0.05)), #FFFBFF",
              background: "#F2F2F2",
              padding: "6px 6px !important",
              "&:hover": {
                backgroundColor: "#E5E5E5;",
              },
            },
          },
        },
        MuiTableCell: {
          styleOverrides: {
            root: {
              borderBottom: "2px solid white",
            },
            head: {
              //background: '#E8DEF8',
              fontSize: "14px",
              fontWeight: "700",
              background: "#818995",
              color: "#FFFFFF",
              fontsize: "16px",
              fontweight: "500",
              "&:hover": {
                color: "#FFFFFF !important",
              },
              "&.mer-theme-onepagertablehead": {
                background: "#F8FDCE",
                color: "#1F242F"
              },
              "&.mer-theme-onepagertablehead:hover": {
                color: "#1F242F !important"
              },
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              // background: '#6750A4',
              color: "#1F242F",
              background: "#EEFB87",
              borderRadius: "100px",
              height: "35px",
              "&:hover": {
                background: "#818995",
                color: "#ffffff",
              },
              "&.textcls": {
                background: "None",
                color: "#1F242F",
              },
              "&.outlinedcls": {
                background: "None",
                border: "1px solid #1F242F",
                color: "#1F242F",
              },
              "&.mer-theme-viewprofile": {
                background: "None",
                border: "1px solid #FFFFFF",
                color: "#FFFFFF",
              },
              "&.mer-theme-logout": {
                background: "#EEFB87",
                color: "#1F242F",
              },
              "&.mer-theme-danger-button": {
                background: "#d32f2f",
                borderRadius: "100px",
                color: "#fff",
              },
            },
          },
        },
        MuiTab: {
          styleOverrides: {
            root: {
              minHeight: "50px",
              // color:'red',
              // "&.Mui-selected": {
              //   color: '#5655A8',
              //   borderRadius: "25px"
              // },
              "&:hover": {
                color: "#7776b8",
              },
            },
          },
        },
        MuiTabs: {
          styleOverrides: {
            indicator: {
              background: "#EEFB87",
              height: "4px",
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              MuiDialog: {
                paper: {
                  "&.dialog-box": {
                    width: "50% !important",
                    minHeight: "60% !important",
                    maxHeight: "auto !important",
                  },
                },
              },
            },
          },
        },

        MuiDrawer: {
          styleOverrides: {
            root: {
              height: "100%",

              ".css-1egvwlz-MuiPaper-root-MuiDrawer-paper": {
                height: `calc(100% - 60px) !important`,
              },
            },
            paper: {
              height: `calc(100% - 50px) !important`,
            },
          },
        },
        MuiList: {
          styleOverrides: {
            root: {
              paddingTop: "0",
              ".mer-theme-sub-list-item": {
                background: "#ddd !important",
              },
              "&.MuiMenu-list": {
                paddingBottom: "0px",
              },
            },
          },
        },
        MuiListItem: {
          styleOverrides: {
            root: {
              color: "#1F242F",
              marginTop: "4px",
              marginBottom: "4px",
              paddingLeft: "12px",
              paddingRight: "12px",
              // borderBottom: "1px solid #ddd",
              "&.stageslistItem": {
                backgroundColor: "#E5E5E5",
                borderRadius: "100px",
                // color: "#47464F",
                cursor: "pointer",
              },
              "&:hover": {
                backgroundColor: "#F1FC9C",
                color: "#1F242F",
                // color: "#1D192B",
                borderRadius: "100px",
                "&.stageslistItem": {
                  // backgroundColor: "#7776b8",
                  borderRadius: "100px",
                  // color: "white",
                  cursor: "pointer",
                },
              },
              "&.active": {
                backgroundColor: "#EEFB87",
                // color: "#1D192B",
                // color: "#100563",
                borderRadius: "100px",
              },
              "&.Mui-selected": {
                "&.stageslistItem": {
                  backgroundColor: "#EEFB87",
                  borderRadius: "100px",
                  // color: "white",
                  cursor: "pointer",
                },
              },
            },
          },
        },
        MuiCard: {
          styleOverrides: {
            root: {
              // background:
              //   "linear-gradient(0deg, rgba(86, 85, 168, 0.08), rgba(86, 85, 168, 0.08)), #FFFBFF",
              background: "#F2F2F2",
              margin: "12px",
              padding: "0px",
              borderRadius: "24px",
              overflow: "visible",
              boxShadow: "0px 0px",
              "&.secondary": {
                background: "#FFFFFF",
                margin: "0px 12px 12px 0px",
                padding: "12px",
                borderRadius: "8px",
                boxShadow:
                  "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
              },
              "&.candDetails": {
                margin: "0px 12px 12px 12px",
                background: "#EADCF5",
                borderRadius: "0px 0px 16px 16px",
              },
              "&.chipsTheme": {
                background: "#F2F2F2",
                borderRadius: "8px",
              },

              "&.teritary": {
                background: "#E5E5E5",
                margin: "0px 12px 12px 12px",
                borderRadius: "16px",
              },
              "&.mer-theme-headermenucard": {
                background: "#818995",
                borderRadius: "4px",
                color: "#FFFFFF",
              },
              "&.mer-theme-profilemenu": {
                background: "#3E3C8F",
                borderRadius: "24px",
                boxShadow:
                  "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
              },
              "&.mer-theme-loggedinas": {
                background: "#6F6EC3",
                borderRadius: "8px",
                color: "#FFFFFF",
              },

              "&.mer-theme-switchrole": {
                background: "#FFFFFF",
                borderRadius: "8px",
                color: "#1F242F",
                boxShadow:
                  "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
              },
              "&.mer-action-card": {
                padding: "4px 12px",
                margin: "0px 0px 6px 0px",
                borderRadius: "8px",
                background: "#FFFFFF",
                boxShadow:
                  "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
              },
              "&.mer-theme-success-card": {
                background: "#CEF14F",
                color: "#171E00",
                borderRadius: "8px",
              },
              "&.mer-theme-error-card": {
                background: "#FFDAD6",
                color: "#410002",
                borderRadius: "8px",
              },
              "&.mer-theme-primary-card": {
                background: "#818995",
                color: "#FFFFFF",
                borderRadius: "8px",
              },
              "&.mer-theme-logincard": {
                background: "#FFFFFF",
                color: "#1F242F",
                borderRadius: "16px",
                padding: "20px",
                // boxShadow:
                //   " 8px 8px 2px #D8D4FF, 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
              },
              "&.mer-theme-dialog-header-bg": {
                margin: "0px",
                padding: "0px",
                borderRadius: "0px",
                overflow: "visible",
                boxShadow: "0px 0px",
                background: "#818995",
                color: "#FFFFFF"
              },
              "&.mer-theme-dialog-header-bg-tables": {
                margin: "0px",
                padding: "0px",
                borderRadius: "0px",
                overflow: "visible",
                boxShadow: "0px 0px",
                background: "#818995",
                color: "#FFFFFF"
              },
              "&.mer-theme-whitecard": {
                background: "#FFFFFF",
                margin: "0px 12px 12px 0px",
                padding: "12px",
                borderRadius: "8px",
              },
            },
          },
        },

        MuiChip: {
          styleOverrides: {
            root: {
              backgroundColor: "#F5F5F5",
              color: "#1F242F",
              cursor: "pointer",
              margin: "0px 8px",
              "&.active": {
                color: "#FFFFFF",
                backgroundColor: "#818995",
              },
              "&.mer-disabled-step": {
                color: "rgba(0, 0, 0, 0.26)",
                boxShadow: "none",
                backgroundColor: "rgba(0, 0, 0, 0.12)",
              },
            },
            icon: {
              color: "#1F242F",
              "&.active": {
                color: "#FFFFFF !important",
              },
            },
            deleteIcon: {
              fontSize: "18px !important",
            },
            avatar: {
              color: "#1F242F",
              "&.active": {
                color: "#FFFFFF !important",
              },
              "&.mer-disabled-step": {
                color: "rgba(0, 0, 0, 0.26)",
              },
            },
          },
        },
        MuiAutocomplete: {
          styleOverrides: {
            root: {
              "&.MuiAutocomplete-input": {
                padding: "0.5px 4px 2.5px 6px",
              },
              "&.gsearch": {
                marginTop: "10px",
              },
            },
            tag: {
              margin: "1px 2px 1px 2px",
            },
            endAdornment: {
              top: "6px",
            },
          },
        },
        MuiAccordion: {
          styleOverrides: {
            root: {
              width: "100%",
              [breakpoints.down("md")]: {
                maxWidth: "367px",
              },
              "&.Mui-expanded": {
                width: "100%",
              },
            },
          },
        },
        MuiButtonBase: {
          styleOverrides: {
            root: {
              "&.MuiIconButton-root": {
                marginRight: "-3px",
                marginTop: "4px",
                float: "left",
                //padding:0,
                "&.errorclr": {
                  color: "#BA1A1A",
                },
                "&.disabled": {
                  color: "ddd",
                },
              },
              "MuiIconButton-root-MuiAutocomplete-popupIndicator.MuiIconButton-root":
              {
                marginTop: "7px",
              },
              "&.MuiTableSortLabel-root": {
                color: "#FFFFFF",
                "&:hover": {
                  color: "#FFFFFF !important",
                }
              },
              "&.MuiAccordionSummary-root": {
                height: "34px",
                minHeight: "34px",
                color: "white",
                background: "#818995",
              },
              "&.MuiAccordionSummary-root.Mui-expanded": {
                height: "34px",
                minHeight: "34px",
                color: "white",
                background: "#818995",
              },
              "MuiCheckbox-root": {
                "&.mer-checkbox": {
                  padding: "0 0 0 9px !important",
                },
              },
              "&.MuiMenuItem-root": {
                "&.mer-checkbox": {
                  paddingLeft: "16px !important",
                },
              },
            },
          },
        },
        // MuiAccordionSummary:{
        //   styleOverrides: {
        //     content:{
        //        background:'red',
        //       "&.Mui-expanded":{

        //       }
        //     }
        //   }
        // },

        MuiTableContainer: {
          styleOverrides: {
            root: {},
          },
        },
        MuiSvgIcon: {
          styleOverrides: {
            root: {
              height: "25px",
              "&.mer-checkbox": {
                width: "1.5rem !important",
                height: "1.5rem !important",
              },
              "&.MuiSelect-icon": {
                color: "#1F242F",
                paddingLeft: "8px",
                marginTop: "4px",
              },
              ".mer-pagination &.MuiSelect-icon": {
                color: "#FFFFFF",
                paddingLeft: "8px",
                marginTop: "4px",
              }
            },
          },
        },

        MuiInputAdornment: {
          styleOverrides: {
            root: {
              color: "#000000",
              paddingLeft: "10px",
              "MuiIconButton-root": {
                padding: 0,
              },
            },
          },
        },



        MuiPickersArrowSwitcher: {
          styleOverrides: {
            spacer: {
              width: "12px",
            },
          },
        },
        MuiDialogTitle: {
          styleOverrides: {
            root: {
              "&.mer-theme-dialog-header-bg": {
                background: "#818995",
              },
              "&.mer-theme-dialog-header-bg-tables": {
                background: "#818995",
                color: "#FFFFFF"
              },
            },
          },
        },
        MuiSkeleton: {
          styleOverrides: {
            root: {
              backgroundColor: "white",
              "&.mer-theme-skel-tableheader": {
                backgroundColor: "#818995",
              },
              "&.mer-theme-skel-tabledata": {
                backgroundColor: "#F2F2F2",
              },
              "&.mer-theme-skel-btn": {
                backgroundColor: "#EEFB87",
              },
              "&.mer-theme-skel-menu": {
                backgroundColor: "#E5E5E5",
              },
              "&.mer-theme-skel-menuh": {
                backgroundColor: "#EEFB87",
              },
              "&.mer-theme-skel-view": {
                backgroundColor: "#ffffff",
              },
            },
          },
        },
        MuiSlider: {
          styleOverrides: {
            mark: {
              width: "9px",
              height: "9px",
              borderRadius: "50%",
              color: "#1F242F",
              zIndex: "2",
            },
            markActive: {
              width: "9px",
              height: "9px",
              borderRadius: "50%",
              color: "white",
              backgroundColor: "white",
              zIndex: "2",
            },

            thumb: {
              width: "36px",
              borderRadius: "100px",
              color: "#1F242F",
              marginLeft: "3px",
            },
            rail: {
              color: "#818995",
            },
          },
        },
        MuiPickersDay: {
          styleOverrides: {
            root: {
              backgroundColor: "None",
              "&.Mui-selected": {
                color: "White",
                backgroundColor: "#1F242F",
              },
              "&:hover": {
                color: "White",
                backgroundColor: "#1F242F",
              },
            },
            today: {
              backgroundColor: "#ffffff",
            },
          },
        },
        // MuiYearPicker:{
        //   styleOverrides: {
        //     root:{
        //       background:'red',
        //       "&.PrivatePickersYear-root":{
        //         "&.Mui-selected":{
        //              color:'white !important'
        //              }
        //       }
        //       // ['&> div >button']: {
        //       //   "&.Mui-selected":{
        //       //     color:'white !important'
        //       //   }
        //       // }
        //     }
        //   }
        // },
        // MuiClockPicker:{
        //   styleOverrides: {
        //     root:{
        //       backgroundColor:'red',
        //       ['&>div >div>div>span']: {
        //         "&.Mui-selected":{
        //           color:'red !important'
        //         }
        //       }
        //     }
        //   }
        // },
        // MuiCalendarPicker:{
        //   styleOverrides: {
        //     root:{
        //       maxHeight:'290px'
        //     },
        //     viewTransitionContainer:{
        //       overflow:'hidden'
        //     }
        //   }

        // }
      },
    },

    dark: {
      palette: {
        mode: "dark",
        primary: {
          main: "#6750A4",
        },
        secondary: {
          main: "#6750A4",
        },
        text: {
          primary: "#ffffff",
        },
        background: {
          default: "#000000",
          paper: "#000000",
        },
        action: {
          active: "#E8DEF8", // For active links
        },
      },

      typography: {
        h6: {
          fontSize: "24px",
          fontWeight: "600",
        },
        button: {
          //fontWeight:500
          color: "#ffffff",
          textTransform: "none",
        },
      },

      components: {
        MuiFormLabel: {
          styleOverrides: {
            root: {
              fontSize: "12px !important",
              fontWeight: "600",
              color: "white",
            },
          },
        },
        MuiSelect: {
          styleOverrides: {
            root: {
              height: "35px",
            },
          },
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              "&.globalsearch": {
                background: "#393939",
                borderRadius: "34px",
                color: "white",
                [breakpoints.up("lg")]: {
                  width: "400px",
                },
              },
              "&.tablesearch": {
                height: "35px",
                [breakpoints.up("md")]: {
                  width: "250px",
                },
                background: "#393939",
                borderRadius: "34px",
                color: "white",
                padding: "0px 16px",
              },
              "&.localsearch": {
                //background: 'red',
                borderRadius: "34px",
                color: "white",
                padding: "0px 16px",
              },
              "&.MuiOutlinedInput-root": {
                padding: "0px 4px 0px 0px",
              },
              input: {
                "&.MuiOutlinedInput-input": {
                  paddingLeft: "6px",
                  "&:autofill": {
                    boxShadow: "None",
                  },
                  "&.MuiOutlinedInput-input": {
                    paddingTop: "6px",
                  },
                },
              },
            },
          },
        },
        MuiToolbar: {
          styleOverrides: {
            root: {
              background: "#000000",
              color: "#ffffff",
              "&.MuiTablePagination-toolbar": {
                minHeight: "22px",
                //background: '#ffffff',
                background: "#382e52",
                margin: "10px",
                height: "40px",
              },
            },
          },
        },
        MuiChip: {
          styleOverrides: {
            root: {
              backgroundColor: "#00000",

              cursor: "pointer",
              margin: "0px 8px",
              "&.active": {
                backgroundColor: "#000000",
                "&.mer-disabled-step": {
                  color: "rgba(0, 0, 0, 0.26)",
                },
              },
              "&.mer-disabled-step": {
                color: "rgba(255, 255, 255, 0.3)",
                boxShadow: "none",
                backgroundColor: "rgba(255, 255, 255, 0.12)",
              },
            },
            deleteIcon: {
              fontSize: "18px !important",
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              // background: '#4d3f72',
              // color: '#ffffff',
              color: "#ffffff",
              background: "#5655A8",
              borderRadius: "100px",
              height: "35px",
              "&:hover": {
                background: "#4d3f72",
                color: "#ffffff",
              },
              "&.textcls": {
                background: "None",
                color: "#5655A8",
              },
              "&.outlinedcls": {
                background: "None",
                border: "1px solid #5655A8",
                color: "#5655A8",
              },
              "&.mer-theme-viewprofile": {
                background: "None",
                border: "1px solid #E2DFFF",
                color: "#E2DFFF",
              },
              "&.mer-theme-logout": {
                background: "#C2C1FF",
                color: "#272377",
              },
              "&.mer-theme-danger-button": {
                background: "#d32f2f",
                borderRadius: "100px",
                color: "#fff",
              },
            },
          },
        },
        MuiAutocomplete: {
          styleOverrides: {
            root: {
              "&.MuiAutocomplete-input": {
                padding: "0.5px 4px 2.5px 6px",
              },
              "&.gsearch": {
                marginTop: "10px",
              },
            },
            tag: {
              margin: "1px 2px 1px 2px",
            },
            endAdornment: {
              top: "6px",
              "&.mer-theme-auto-specialIconalign": {
                top: "2px",
              },
            },
          },
        },
        // MuiAccordionSummary:{
        //   styleOverrides: {
        //     content:{
        //        background:'red',
        //       "&.Mui-expanded":{

        //       }
        //     }
        //   }
        // },
        MuiAccordion: {
          styleOverrides: {
            root: {
              width: "100%",
              [breakpoints.down("md")]: {
                maxWidth: "367px",
              },
              "&.Mui-expanded": {
                width: "100%",
              },
            },
          },
        },
        MuiButtonBase: {
          styleOverrides: {
            root: {
              "&.MuiIconButton-root": {
                marginRight: "-3px",
                marginTop: "4px",
                float: "left",
                // padding:0,
                "&.errorclr": {
                  color: "#BA1A1A",
                },
              },
              "MuiIconButton-root-MuiAutocomplete-popupIndicator.MuiIconButton-root":
              {
                marginTop: "7px",
              },
              "&.MuiAccordionSummary-root": {
                height: "34px",
                minHeight: "34px",
                background: "#5655A8",
              },
              "&.MuiAccordionSummary-root.Mui-expanded": {
                height: "34px",
                minHeight: "34px",
                background: "#5655A8",
              },
              "MuiCheckbox-root": {
                "&.mer-checkbox": {
                  padding: "0 0 0 9px !important",
                },
              },
              "&.MuiMenuItem-root": {
                "&.mer-checkbox": {
                  paddingLeft: "16px !important",
                },
              },
            },
          },
        },
        MuiTab: {
          styleOverrides: {
            root: {
              minHeight: "50px",
              // "&.Mui-selected": {
              //   color: '#4d3f72',

              //   borderRadius: "25px",
              //   borderBottom: "1px solid #171321"
              // },
              "&:hover": {
                color: "#453966",
              },
            },
          },
        },
        MuiTabs: {
          styleOverrides: {
            indicator: {
              background: "#4d3f72",
              height: "4px",
            },
          },
        },

        MuiPaper: {
          styleOverrides: {
            root: {
              MuiDialog: {
                paper: {
                  "&.dialog-box": {
                    width: "50% !important",
                    minHeight: "60% !important",
                    maxHeight: "auto !important",
                  },
                },
              },
            },
          },
        },

        MuiDrawer: {
          styleOverrides: {
            root: {
              height: "100%",

              ".css-1egvwlz-MuiPaper-root-MuiDrawer-paper": {
                height: `calc(100% - 60px) !important`,
              },
            },
            paper: {
              height: `calc(100% - 50px) !important`,
            },
          },
        },
        MuiList: {
          styleOverrides: {
            root: {
              paddingTop: "0",
              // ".mer-theme-sub-list-item":{
              //   background:'#ddd !important'
              // },
              paddingBottom: "0px !important",
              "&.MuiMenu-list": {
                paddingBottom: "0px",
              },
            },
          },
        },
        MuiListItem: {
          styleOverrides: {
            root: {
              backgroundColor: "#000000",
              color: "#ffffff",
              marginTop: "4px",
              marginBottom: "4px",
              paddingLeft: "12px",
              paddingRight: "12px",
              // border:"1px solid #171321",
              "&:hover": {
                backgroundColor: "#241d39",
                color: "#ffffff",
                borderRadius: "100px",
                "&.stageslistItem": {
                  backgroundColor: "#241d39",
                  borderRadius: "100px",
                  color: "white",
                  cursor: "pointer",
                },
              },
              "&.active": {
                backgroundColor: "#4d3f72",
                color: "#ffffff",
                borderRadius: "100px",
              },
              "&.Mui-selected": {
                "&.stageslistItem": {
                  backgroundColor: "#4d3f72",
                  borderRadius: "100px",
                  color: "white",
                  cursor: "pointer",
                },
              },
            },
          },
        },
        MuiTableRow: {
          styleOverrides: {
            root: {
              backgroundColor: "#000000",
              padding: "6px 6px !important",
              "&:hover": {
                backgroundColor: "#242424;",
              },
            },
          },
        },

        MuiTableCell: {
          styleOverrides: {
            root: {
              //color:"#1D192B",

              // color: "#ffffff",

              // color: "#ffffff",
              borderBottom: "1px solid #171321",
            },
            head: {
              //   background: '#251d3c',
              fontSize: "14px",
              fontWeight: "700",
              background: "#382e52",
              color: "#ffffff",
              "&.mer-theme-onepagertablehead": {
                background: "#004C6D",
              },
            },
          },
        },
        MuiDialogActions: {
          styleOverrides: {
            root: {
              "&.confirm-action-dialog-box": {
                justifyContent: "center !important",
              },
            },
          },
        },
        MuiTablePagination: {
          styleOverrides: {
            root: {
              //color:"#1D192B",
              "&:toolbar": {
                backgroundColor: "#000000",
                color: "#ffffff",
                borderBottom: "1px solid #171321",
              },
            },
            select: {
              paddingRight: "20px !important",
              marginRight: "15px !important",
            },
          },
        },

        MuiCard: {
          styleOverrides: {
            root: {
              background:
                "linear-gradient(0deg, rgba(194, 193, 255, 0.05), rgba(194, 193, 255, 0.05)), #1C1B1F",
              margin: "12px",
              padding: "0px",
              borderRadius: "24px",
              overflow: "visible",
              boxShadow: "0px 0px",
              "&.secondary": {
                margin: "0px 12px 12px 0px",
                padding: "12px",
                borderRadius: "8px",
                background: "#1C1B1F",
                boxShadow:
                  "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
              },
              "&.candDetails": {
                background: "#004C6D",
                borderRadius: "0px 0px 16px 16px",
                margin: "0px 12px 12px 12px",
              },
              "&.chipsTheme": {
                background: "#004C6D",
                borderRadius: "8px",
              },
              "&.teritary": {
                background: "#47464F",
                margin: "0px 12px 12px 12px",
                borderRadius: "16px",
              },
              "&.mer-theme-headermenucard": {
                background: "#3E3C8F",
                borderRadius: "4px",
                color: "#FFFFFF",
              },
              "&.mer-theme-profilemenu": {
                background: "#3E3C8F",
                borderRadius: "24px",
                boxShadow:
                  "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
              },
              "&.mer-theme-loggedinas": {
                background: "#6F6EC3",
                borderRadius: "8px",
                color: "#FFFFFF",
              },

              "&.mer-theme-switchrole": {
                background: "#FFFBFF",
                borderRadius: "8px",
                color: "#1C1B1F",
                boxShadow:
                  "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
              },

              "&.mer-action-card": {
                padding: "4px 12px",
                margin: "0px 0px 6px 0px",
                borderRadius: "8px",
                background: "#1C1B1F",
                boxShadow:
                  "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
              },
              "&.mer-theme-success-card": {
                background: "#CEF14F",
                color: "#171E00",
                borderRadius: "8px",
              },
              "&.mer-theme-error-card": {
                background: "#FFDAD6",
                color: "#410002",
                borderRadius: "8px",
              },
              "&.mer-theme-primary-card": {
                background: "#5655A8",
                color: "#FFFFFF",
                borderRadius: "8px",
              },
              "&.mer-theme-dialog-header-bg": {
                margin: "0px",
                padding: "0px",
                borderRadius: "0px",
                overflow: "visible",
                boxShadow: "0px 0px",
                background: "#382e52",
              },
              "&.mer-theme-dialog-header-bg-tables": {
                margin: "0px",
                padding: "0px",
                borderRadius: "0px",
                overflow: "visible",
                boxShadow: "0px 0px",
                background: "#382e52",
              },
            },
          },
        },
        MuiTableContainer: {
          styleOverrides: {
            root: {},
          },
        },
        MuiSvgIcon: {
          styleOverrides: {
            root: {
              height: "25px",
              "&.mer-checkbox": {
                width: "1.5rem !important",
                height: "1.5rem !important",
              },
              "&.MuiSelect-icon": {
                // color:'#000000',
                paddingLeft: "8px",
                marginTop: "4px",
              },
            },
          },
        },
        MuiInputAdornment: {
          styleOverrides: {
            root: {
              color: "#ffffff",
              paddingLeft: "10px",
            },
          },
        },
        MuiSlider: {
          styleOverrides: {
            mark: {
              width: "9px",
              height: "9px",
              borderRadius: "50%",
              color: "#03035e",
              zIndex: "2",
            },
            markActive: {
              width: "9px",
              height: "9px",
              borderRadius: "50%",
              color: "white",
              backgroundColor: "white",
              zIndex: "2",
            },
            thumb: {
              width: "36px",
              borderRadius: "100px",
              color: "#5655A8",
              marginLeft: "3px",
            },
            rail: {
              color: "#090655",
            },
          },
        },
        MuiPickersArrowSwitcher: {
          styleOverrides: {
            spacer: {
              width: "12px",
            },
          },
        },
        MuiDialogTitle: {
          styleOverrides: {
            root: {
              "&.mer-theme-dialog-header-bg": {
                background: "#382e52",
              },
              "&.mer-theme-dialog-header-bg-tables": {
                background: "#382e52",
              },
            },
          },
        },
        MuiSkeleton: {
          styleOverrides: {
            root: {
              backgroundColor: "white",
              "&.mer-theme-skel-tableheader": {
                backgroundColor: "#382e52",
              },
              "&.mer-theme-skel-tabledata": {
                backgroundColor: "#393939",
              },

              "&.mer-theme-skel-btn": {
                backgroundColor: "#9897e2",
              },
              "&.mer-theme-skel-menu": {
                backgroundColor: "#393939",
              },
              "&.mer-theme-skel-menuh": {
                backgroundColor: "#4d3f72",
              },
              "&.mer-theme-skel-view": {
                backgroundColor: "#1c1b1f",
              },
            },
          },
        },
        MuiPickersDay: {
          styleOverrides: {
            root: {
              backgroundColor: "#1f1f1f",
              "&.Mui-selected": {
                color: "White",
                backgroundColor: "#5655a8",
              },
              "&:hover": {
                color: "White",
                backgroundColor: "#5655a8",
              },
            },
            today: {
              backgroundColor: "#000000",
            },
          },
        },

        // MuiYearPicker:{
        //   styleOverrides: {
        //     root:{
        //       "&.PrivatePickersYear-root":{
        //         "&.Mui-selected":{
        //              color:'white !important'
        //              }
        //       }
        //       // ['&> div >button']: {
        //       //   "&.Mui-selected":{
        //       //     color:'white !important'
        //       //   }
        //       // }
        //     }
        //   }
        // },
        // MuiClockPicker:{
        //   styleOverrides: {
        //     root:{
        //       backgroundColor:'red',
        //       ['&>div >div>div>span']: {
        //         "&.Mui-selected":{
        //           color:'red !important'
        //         }
        //       }
        //     }
        //   }
        // },
        MuiCalendarPicker: {
          styleOverrides: {
            root: {
              maxHeight: "290px",
            },
          },
        },
      },
    },
  };

  // const toggleTheme = () => {
  //    setCurrentTheme((curr)=> (curr == "normal" ? "dark":"normal") )
  // };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeContext.Provider value={{ currentTheme, setCurrentTheme }}>
        <ThemeProvider theme={createTheme(themes[currentTheme])}>
          <SideMenuContext.Provider value={{ sideMenu, setSideMenu }}>
            <LoadingContext.Provider value={{ showLoader, setShowLoader }}>
              {/* <LoggedInUserContext.Provider value={{ loggedInUser, setLoggedInUser }}> */}
              <CssBaseline />
              {/* id={`${currentTheme}`} */}
              <div className="mer-fill">
                <Routing />
                <SimpleDialog />
                <ConfirmationDialog />
                <TrackStatus />
                <AlertDialog />
                <StatusDialog />
                <AddNotesForm />
                {/* <AllDocumentsDownloadDialog /> */}
                <DownloadAllDocuments />
                <RequestDocumentsForm />
                <OfferLetterForm />
                <SyncDatatoEmployeeDBForm />
                <RolesToBeConsideredForDialog />
                <ProposalDialog />
                <OTPDialog />
                {/* <AxiosInterceptorNavigate /> */}
                {showLoader ? <Loader /> : <></>}
              </div>
              {/* </LoggedInUserContext.Provider> */}
            </LoadingContext.Provider>
          </SideMenuContext.Provider>
        </ThemeProvider>
      </ThemeContext.Provider>
    </StyledEngineProvider>
  );
}

export default App;
