
import { useFormik } from "formik";
import * as Yup from "yup";
import * as API from "../../../utills/API";
import React, { useEffect, useState } from "react";
import SimpleHeader from "../../../components/SimpleHeader";
import { simple_dialog } from "../../../utills/Alerts";
import { Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography,Card } from "@mui/material";
import { SELECT_FIELD_CONFIG, TEXT_FIELD_CONFIG } from "../../../utills/constants/FormFieldFormats";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import InputAdornment from "@mui/material/InputAdornment";
import { StaticRouterLinks } from "../../../utills/constants/StaticRouterLinks";
import { MdArrowBack } from "react-icons/md";
import * as CommonMethods from "../../../utills/CommonMethods";
import {reg_mail,err_msg} from '../../../utills/CommonMethods';

function GlobalSettings(props) {

  const [showEye, updateEye] = useState(true);
  const [rolesList, setRolesList] = useState([]);
  const [resumeSourcesList,setResumeSourcesList] = useState([]);

  const globalSettingsForm = useFormik({
    initialValues: {
      _id: null,
      smtp:{
        email: "",
        password: "",
        host: "",
        port: "",
      },
      candidate_role_id:"",
      sourced_profile_resume_source_id:""      
    },
    validationSchema: Yup.object().shape({
      _id: Yup.string().nullable(),
      smtp: Yup.object().shape({
        email: Yup.string().email('Invalid Email ID.').matches(reg_mail,err_msg.mail).max(64,err_msg.mail_char).required("Email is required"),
        password: Yup.string().required("Password is required"),
        host: Yup.string().required("Host is required"),
        port: Yup.number()
                 .typeError("Please enter the valid port")
                 .required("Port is required"),
      }),
      candidate_role_id: Yup.string().required("Candidate Role is required")
    }),
    onSubmit: (values) => {
      let payLoad = values;
      API.PostMethod("globalSettings", payLoad).then((res) => {
        simple_dialog(res.message, () => {backNavigate()});
      });
    },
  });

  useEffect(() => {
    API.GetMethod("globalSettings").then((res) => {
      if(!CommonMethods.isNullOrUndefined(res.data) && res.data.length > 0){
        globalSettingsForm.setValues(res.data[0]);
      }
      else{
        globalSettingsForm.setValues({});
      }
    });

    fetchUserRoles();
    fetchResumeSources();
  }, []);

  
  

  const toggleEye = () => {
    updateEye(!showEye);
  };

  function fetchResumeSources() {
      API.GetMethod('resumeSource').then((resp) => {
          setResumeSourcesList(CommonMethods.isNullOrUndefined(resp.data.resumeSources) ? [] : resp.data.resumeSources);
      })
  }

  function fetchUserRoles(){
    API.GetMethod("userRoles").then((response) => {
      setRolesList(response.data.result);
    });
  }

  function backNavigate() {
    props.onChange();
  }

  return (
    <Card className="d-flex flex-grow-1 flex-column">
      <div className="d-flex align-items-center mer-margin-top-20 mer-margin-left-20 mer-margin-right-20">
        <MdArrowBack
          className="mer-width-20 mer-height-20 mer-cursor-pointer  mer-margin-right-10 mer-icon-color"
          onClick={() => backNavigate()}
        />
        <Typography variant="h6" className="mer-icon-color">Edit Global Settings</Typography>
      </div>
      <Card className="d-flex flex-column secondary mer-margin-12">
        <form onSubmit={globalSettingsForm.handleSubmit}>
          <div className="d-flex mer-fill justify-content-start flex-wrap mer-padding-20 align-items-start  mer-form">
           <div className="d-flex col-md-4 col-lg-4 col-sm-12 col-xs-12 col-12">
            <div className="d-flex flex-grow-1 flex-column mer-margin-right-8 mer-margin-bottom-8">
              <TextField
                name="smtp.host"
                className="form-field"
                label="Host"
                {...TEXT_FIELD_CONFIG.OUTLINED_LABEL}
                value={globalSettingsForm.values?.smtp?.host}
                onChange={globalSettingsForm.handleChange}
                error={
                  globalSettingsForm.touched?.smtp?.host && globalSettingsForm.errors.smtp?.host ? true : false
                }
              />
              <div className="mer-error">
                {globalSettingsForm.touched?.smtp?.host && globalSettingsForm.errors?.smtp?.host ? (
                  <span className="error">{globalSettingsForm.errors?.smtp?.host}</span>
                ) : null}
              </div>
            </div>
           </div>
            <div className="d-flex col-md-4 col-lg-4 col-sm-12 col-xs-12 col-12">
            <div className="d-flex flex-grow-1 flex-column mer-margin-right-8 mer-margin-bottom-8">
              <TextField
                name="smtp.port"
                className="form-field"
                label="Port"
                {...TEXT_FIELD_CONFIG.OUTLINED_LABEL}
                value={globalSettingsForm.values?.smtp?.port}
                onChange={(e) => {
                  globalSettingsForm.setFieldValue("smtp.port", e.target.value);
                }}
                error={
                  globalSettingsForm.touched?.smtp?.port && globalSettingsForm.errors?.smtp?.port ? true : false
                }
              />
              <div className="mer-error">
                {globalSettingsForm.touched?.smtp?.port && globalSettingsForm.errors?.smtp?.port ? (
                  <span className="error">{globalSettingsForm.errors.port}</span>
                ) : null}
              </div>
            </div>
            </div>
            <div className="d-flex col-md-4 col-lg-4 col-sm-12 col-xs-12 col-12">
            <div className="d-flex flex-grow-1 flex-column mer-margin-right-8 mer-margin-bottom-8">
              <TextField
                name="smtp.email"
                className="form-field"
                label="Email"
                {...TEXT_FIELD_CONFIG.OUTLINED_LABEL}
                value={globalSettingsForm.values?.smtp?.email}
                onChange={globalSettingsForm.handleChange}
                error={
                  globalSettingsForm.touched?.smtp?.email && globalSettingsForm.errors?.smtp?.email ? true : false
                }
              />
              <div className="mer-error">
                {globalSettingsForm.touched?.smtp?.email && globalSettingsForm.errors?.smtp?.email ? (
                  <span className="error">{globalSettingsForm.errors.email}</span>
                ) : null}
              </div>
            </div>
            </div>
            <div className="d-flex col-md-4 col-lg-4 col-sm-12 col-xs-12 col-12">
              <div className="d-flex flex-grow-1 flex-column mer-margin-right-8 mer-margin-bottom-8">
                <TextField
                  name="smtp.password"
                  className="form-field"
                  label="Password"
                  {...TEXT_FIELD_CONFIG.OUTLINED_LABEL}
                  value={globalSettingsForm.values?.smtp?.password}
                  type={showEye ? "password" : "text"}
                  onChange={globalSettingsForm.handleChange}
                  error={
                    globalSettingsForm.touched?.smtp?.password && globalSettingsForm.errors?.smtp?.password
                      ? true
                      : false
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {showEye ? (
                          <AiFillEye
                            className="mer-height-20 mer-width-20 mer-cursor-pointer mer-icon-color"
                            onClick={toggleEye}
                          />
                        ) : (
                          <AiFillEyeInvisible
                            className="mer-height-20 mer-width-20 mer-cursor-pointer mer-icon-color"
                            onClick={toggleEye}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
                <div className="mer-error">
                  {globalSettingsForm.touched?.smtp?.password && globalSettingsForm.errors?.smtp?.password ? (
                    <span className="error">{globalSettingsForm.errors?.smtp?.password}</span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="d-flex col-md-4 col-lg-4 col-sm-12 col-xs-12 col-12">
              <div className="d-flex flex-grow-1 flex-column mer-margin-right-8 mer-margin-bottom-8">
                <FormControl size={SELECT_FIELD_CONFIG.OUTLINED_LABEL.size}>
                  <InputLabel id={SELECT_FIELD_CONFIG.OUTLINED_LABEL.labelId}>
                    Candidate Role
                  </InputLabel>
                  <Select
                    className="form-field"
                    name="candidate_role_id"
                    label="Candidate Role"
                    {...SELECT_FIELD_CONFIG.OUTLINED_LABEL}
                    value={globalSettingsForm.values.candidate_role_id}
                    onChange={globalSettingsForm.handleChange}
                    onBlur={globalSettingsForm.handleBlur}
                    MenuProps={SELECT_FIELD_CONFIG.MENU_PROPS}
                    error={
                      globalSettingsForm.touched.candidate_role_id &&
                      globalSettingsForm.errors.candidate_role_id
                        ? true
                        : false
                    }
                  >
                    {!CommonMethods.isNullOrUndefined(rolesList) && rolesList?.length > 0 &&
                      rolesList.map((role) => {
                        return (
                          <MenuItem
                            className="mer-select"
                            key={role["_id"]}
                            value={role["_id"]}
                          >
                            <div>
                              <div>{role["role_name"]}</div>
                            </div>
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
                <div className="mer-error">
                  {globalSettingsForm.touched.candidate_role_id &&
                  globalSettingsForm.errors.candidate_role_id ? (
                    <span className="error">{globalSettingsForm.errors.candidate_role_id}</span>
                  ) : null}
                </div>
              </div>     
            </div> 

            <div className="d-flex col-md-4 col-lg-4 col-sm-12 col-xs-12 col-12">
              <div className="d-flex flex-grow-1 flex-column mer-margin-right-8 mer-margin-bottom-8">
                <FormControl size={SELECT_FIELD_CONFIG.OUTLINED_LABEL.size}>
                  <InputLabel id={SELECT_FIELD_CONFIG.OUTLINED_LABEL.labelId}>
                    Sourced profile resume source
                  </InputLabel>
                  <Select
                    className="form-field"
                    name="sourced_profile_resume_source_id"
                    label="Sourced profile resume source"
                    {...SELECT_FIELD_CONFIG.OUTLINED_LABEL}
                    value={globalSettingsForm.values.sourced_profile_resume_source_id}
                    onChange={globalSettingsForm.handleChange}
                    onBlur={globalSettingsForm.handleBlur}
                    MenuProps={SELECT_FIELD_CONFIG.MENU_PROPS}
                    error={
                      globalSettingsForm.touched.sourced_profile_resume_source_id &&
                      globalSettingsForm.errors.sourced_profile_resume_source_id
                        ? true
                        : false
                    }
                  >
                    {!CommonMethods.isNullOrUndefined(resumeSourcesList) && resumeSourcesList?.length > 0 &&
                      resumeSourcesList.map((role) => {
                        return (
                          <MenuItem
                            className="mer-select"
                            key={role["_id"]}
                            value={role["_id"]}
                          >
                            <div>
                              <div>{role["resume_source_name"]}</div>
                            </div>
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
                <div className="mer-error">
                  {globalSettingsForm.touched.sourced_profile_resume_source_id &&
                  globalSettingsForm.errors.sourced_profile_resume_source_id ? (
                    <span className="error">{globalSettingsForm.errors.sourced_profile_resume_source_id}</span>
                  ) : null}
                </div>
              </div>     
            </div> 

          </div>
          <div className="d-flex justify-content-end align-self-end mer-padding-20">
            <Button
              variant="contained"
              type="submit"
              className=""
            >
              Save Configuration
            </Button>
          </div>
        </form>
      </Card>
    </Card>
  );
}

export default GlobalSettings;
