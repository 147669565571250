

import currency_rupee_svg from '../../assets/icons/currency_rupee.svg';
import documnets_svg from '../../assets/icons/documents.svg';
import interview_svg from '../../assets/icons/interview.svg';
import profile_svg from '../../assets/icons/profile.svg';
import proposal_svg from '../../assets/icons/proposal.svg';
import registration_svg from '../../assets/icons/registration.svg';
import tests_svg from '../../assets/icons/tests.svg';
import overview_svg from '../../assets/icons/candidate_overview.svg';

import department from '../../assets/icons/department.svg';
import designation from '../../assets/icons/designation.svg';
import domains from '../../assets/icons/domains.svg';
import roles from '../../assets/icons/roles.svg';
import sub_department from '../../assets/icons/sub_department.svg';
import users from '../../assets/icons/users.svg';

import branches from '../../assets/icons/branches.svg';
import colleges from '../../assets/icons/colleges.svg';
import degrees from '../../assets/icons/degrees.svg';
import resume_sources from '../../assets/icons/resume_sources.svg';
import pincodes from '../../assets/icons/pincodes.svg';
import entranceexams from '../../assets/icons/entranceexams.svg';
import tags from '../../assets/icons/tags.svg';

import action from '../../assets/icons/action.svg';
import cases from '../../assets/icons/cases.svg';
import email_templates from '../../assets/icons/email_templates.svg';
import events from '../../assets/icons/events.svg';
import smtp from '../../assets/icons/smtp.svg';
import stages from '../../assets/icons/stages.svg';
import status from '../../assets/icons/status.svg';

import analytics from '../../assets/icons/analytics.svg';
import candidates from '../../assets/icons/candidates.svg';
import home from '../../assets/icons/home.svg';

import add from '../../assets/icons/add.svg';
import archive from '../../assets/icons/archive.svg';
import settings from '../../assets/icons/settings.svg';
import mobile_logo from '../../assets/images/merilytics_mobile_logo.svg';
import mobile_logo_dark from '../../assets/images/merilytics_mobile_logo_dark.svg';
import login_background from '../../assets/images/login_background.svg';
import accordion_login_background from '../../assets/images/accordion_login_page_background.svg';

import microsoft_logo from "../../assets/images/microsoft_logo.svg"

import { ReactComponent as OnlineMeeting } from '../../assets/images/online_meeting.svg';
import { ReactComponent as ThumbsUp } from '../../assets/images/thumbs_up.svg';
import { ReactComponent as TokenExpiredImage } from '../../assets/images/token_expired_image.svg';
import { ReactComponent as LookingforJob } from '../../assets/images/looking_for_job.svg';
import { ReactComponent as LoginBackground } from '../../assets/images/login_background.svg';
import referral from '../../assets/icons/referral.svg';
import sign_in_with_microsoft_light from "../../assets/images/sign_in_with_microsoft_light.svg"
import sign_in_with_microsoft_dark from "../../assets/images/sign_in_with_microsoft_dark.svg"

const IMAGES = {
    "microsoft_login_image_light":sign_in_with_microsoft_light,
    "microsoft_login_image_dark":sign_in_with_microsoft_dark,
    "login_background": login_background,
    "accordion_login_background": accordion_login_background,
    "microsoft_logo": microsoft_logo,
    "access_denied": process.env.REACT_APP_BASE_URL + "/files/static/access_denied.svg",
    "recruitment_with_merilytics_logo": process.env.REACT_APP_BASE_URL + "/files/static/recruitment_with_merilytics_logo.svg",
    "recruitment_with_merilytics_logo_dark": process.env.REACT_APP_BASE_URL + "/files/static/recruitment_with_merilytics_logo_dark.svg",
    "accordion_logo":process.env.REACT_APP_BASE_URL + "/files/static/accordion_logo.svg",
    "accordion_logo_white":process.env.REACT_APP_BASE_URL + "/files/static/accordion_logo_white.svg",
    "accordion_icon":process.env.REACT_APP_BASE_URL + "/files/static/accordion_icon.svg",
    "accordion_icon_white":process.env.REACT_APP_BASE_URL + "/files/static/accordion_icon_white.svg",
    "accordion_icon_disabled":process.env.REACT_APP_BASE_URL + "/files/static/accordion_A_outer_disabled.svg",
    "page_background_image": process.env.REACT_APP_BASE_URL + "/files/static/page_background_image.svg",
    "page_background_dark": process.env.REACT_APP_BASE_URL + "/files/static/pattern_black.svg",
    "page_background_light": process.env.REACT_APP_BASE_URL + "/files/static/pattern_white.svg",
    "thumbs_up": process.env.REACT_APP_BASE_URL + "/files/static/thumbs_up.svg",
    "merilytics_mobile_logo": mobile_logo,
    "merilytics_mobile_logo_dark": mobile_logo_dark,
    "looking_for_job": process.env.REACT_APP_BASE_URL + "/files/static/looking_for_job.svg",
    "token_expired_image": process.env.REACT_APP_BASE_URL + "/files/static/token_expired_image.svg",
    //"online_meeting": process.env.REACT_APP_BASE_URL + "/files/static/online_meeting.svg",
    "online_meeting": <OnlineMeeting />,
    "assets_merilytics_mobile_logo": mobile_logo,
    "assets_merilytics_mobile_logo_dark": mobile_logo_dark,
    "sidemenu": {
        "overview": overview_svg,
        "registration": registration_svg,
        "profile": profile_svg,
        "tests": tests_svg,
        "interviews": interview_svg,
        "documents": documnets_svg,
        "proposal": proposal_svg,
        "currency_rupee": currency_rupee_svg,

        "home": home,
        "candidates": candidates,
        "analytics": analytics,

        "add": add,
        "archive": archive,
        "settings": settings,

        "roles": roles,
        "users": users,
        "domains": domains,
        "departments": department,
        "sub_departments": sub_department,
        "designations": designation,
        "resume_sources": resume_sources,
        "colleges": colleges,
        "degrees": degrees,
        "branches": branches,
        "events": events,
        "email_templates": email_templates,
        "smtp": smtp,
        "stages": stages,
        "status": status,
        "cases": cases,
        "actions": action,
        "tags": tags,
        "pincodes": pincodes,
        "entranceexams": entranceexams,
        "referral": referral,
    }
}


export default IMAGES;
