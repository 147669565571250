import * as API from "../API";
import * as commonMethods from "../../utills/CommonMethods";

// const {sideMenu, setSideMenu} = useContext(SideMenuContext);

export let GetSideMenu = async (page) => {
  return API.GetMethod(`menu?menu_name=${page}`);
};

export let GetForms = async (params) => {
  return API.GetMethod(`userRoles/screens?${params}`);
};

export let GetDisabledForms = async (params) => {
  return new Promise((resolve, reject) => {
    API.GetMethod("candidateDetails/basicInfo/" + params?.id).then(
      (response) => {
        let candidatesInfo = response.data;
        let candidateInfo = {};

        if (candidatesInfo.length > 0) {
          candidateInfo = response.data[0];
          if (!commonMethods.isNullOrUndefined(candidateInfo.status_id)) {
            API.GetMethod(
              `status/${candidateInfo.status_id}/disableForms`
            ).then(
              (status_list) => {
                if (
                  !commonMethods.isNullOrUndefined(status_list) &&
                  !commonMethods.isNullOrUndefined(status_list.data) &&
                  status_list["data"].length > 0
                ) {
                  //setDisableForms(status_list['data'][0]['disable_form_info'])
                  resolve(status_list["data"][0]["disable_form_info"]);
                }
              },
              (error) => {
                reject("It's failed");
              }
            );
          }
        } else {
          resolve([]);
        }
      }
    );
  });
};

export const CONFIGURE_MENU = [
  {
    menu_id: 1,
    menu_name: "Roles",
    link: "/recruitee/admin/roles",
  },
  {
    menu_id: 2,
    menu_name: "Users",
    link: "/recruitee/admin/users",
  },
  {
    menu_id: 3,
    menu_name: "SMTP Configurations",
    link: "/recruitee/admin/smtp",
  },
  {
    menu_id: 4,
    menu_name: "Email Templates",
    link: "/recruitee/admin/templates",
  },
  {
    menu_id: 5,
    menu_name: "Domains",
    link: "/recruitee/admin/domains",
  },
  {
    menu_id: 6,
    menu_name: "Departments",
    link: "/recruitee/admin/departments",
  },
  {
    menu_id: 7,
    menu_name: "Sub Departments",
    link: "/recruitee/admin/sub-departments",
  },
  {
    menu_id: 8,
    menu_name: "Designations",
    link: "/recruitee/admin/designations",
  },
  {
    menu_id: 9,
    menu_name: "Resume Sources",
    link: "/recruitee/admin/resume-sources",
  },
  {
    menu_id: 10,
    menu_name: "Colleges",
    link: "/recruitee/admin/colleges",
  },
  {
    menu_id: 11,
    menu_name: "Degrees",
    link: "/recruitee/admin/degrees",
  },
  {
    menu_id: 12,
    menu_name: "Branches",
    link: "/recruitee/admin/branches",
  },
  {
    menu_id: 13,
    menu_name: "Events",
    link: "/recruitee/admin/events",
  },
  {
    menu_id: 14,
    menu_name: "Stages",
    link: "/recruitee/admin/stages",
  },
  {
    menu_id: 15,
    menu_name: "Status",
    link: "/recruitee/admin/statuses",
  },
];

export const RECRUITEE_MENU = [
  // {
  //   "menu_id": 1, "menu_name": "Dashboard", "link": "/recruitee/dashboard"
  // },
  // {
  //   "menu_id": 2, "menu_name": "Candidates", "link": StaticRouterLinks.candidates_registration_table
  // },
  // {
  //   "menu_id":3,"menu_name":"Drive","link":"/recruitee/drive"
  // },
  // {
  //   "menu_id": 4, "menu_name": "Archive", "link": "/recruitee/archive"
  // }
];

export const CANDIDATE_MENU = [
  // {
  //   "menu_id": 1, "menu_name": "Summary", "link": "/recruitee/candidate/summary/"
  // },
  {
    menu_id: 1,
    menu_name: "Registration details",
    link: "/recruitee/candidate/registration-details/",
    expanded: false,
  },
  {
    menu_id: 3,
    menu_name: "Profile",
    link: "/recruitee/candidate/profile/personal-details/",
    expanded: false,
    children: [
      {
        menu_id: 4,
        menu_name: "Personal Details",
        link: "/recruitee/candidate/profile/personal-details/",
      },
      {
        menu_id: 5,
        menu_name: "History",
        link: "/recruitee/candidate/profile/history/",
      },
      {
        menu_id: 6,
        menu_name: "Skills",
        link: "/recruitee/candidate/profile/skills/",
      },
      {
        menu_id: 7,
        menu_name: "Preferences",
        link: "/recruitee/candidate/preferences/",
      },
      { menu_id: 8, menu_name: "BGV", link: "/recruitee/candidate/bgv/" },
    ],
  },
  {
    menu_id: 8,
    menu_name: "Tests",
    link: "/recruitee/candidate/tests/",
  },
  {
    menu_id: 2,
    menu_name: "Interviews",
    expanded: false,
    children: [
      {
        menu_id: 9,
        menu_name: "Schedule Interviews",
        link: "/recruitee/candidate/schedule-interviews/",
      },
      {
        menu_id: 14,
        menu_name: "Comments",
        link: "/recruitee/candidate/comments/",
      },
    ],
  },
  // {
  //   "menu_id": 10, "menu_name": "Review", "link": "/recruitee/candidate/review/online-tests/", "expanded": false,
  //   "children": [
  //     { "menu_id": 11, "menu_name": "Online tests", "link": "/recruitee/candidate/review/online-tests/" },
  //     { "menu_id": 12, "menu_name": "Technical interviews", "link": "/recruitee/candidate/review/interviews/" },
  //     { "menu_id": 13, "menu_name": "Behavioural interviews", "link": "/recruitee/candidate/review/interviews/" },
  //   ]
  // },
  {
    menu_id: 15,
    menu_name: "Documents",
    link: "/recruitee/candidate/documents/",
  },
  {
    menu_id: 16,
    menu_name: "Proposal",
    link: "/recruitee/candidate/proposal/",
  },
];
