import { Paper, Card } from '@mui/material';
import React, { useState, useEffect, useContext } from 'react';
// import { GoPrimitiveDot } from 'react-icons/go';
import { MdCircle } from 'react-icons/md';
import * as API from '../../../utills/API';
import { DateFormat, DateTimeFormat } from '../../../utills/DateTimeFormats';
import { ThemeContext } from "../../../App";

function Timeline(props) {


  const Themeobj = useContext(ThemeContext);
  function getCandidateTimeline() {
    API.GetMethod("timelines/" + props?.candidate._id).then((res) => {
      setTileLine(res.data[0].timelines)
    });
  }

  useEffect(() => {
    getCandidateTimeline();
  }, []);

  const [time_line, setTileLine] = useState([]);
  return (
    <div>
      <div className='d-flex flex-column mer-margin-top-12'>
        {time_line.map((screen, index) => (
          <Card key={'screen' + index} sx={{ boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)' }}
            className={screen?.subject ? "d-flex flex-column mer-margin-bottom-10 mer-margin-left-12 mer-padding-12 mer-margin-top-0 chipsTheme" : "d-flex flex-column mer-margin-bottom-10 mer-margin-left-12 secondary"}>
            {/* <div className='d-flex flex-row justify-content-between align-items-center'> */}
               <div className='d-flex flex-row align-items-center'>
                <MdCircle color={Themeobj.currentTheme === 'light' ? '#1F242F' : 'white'} className={'mer-width-10 mer-height-10 mer-margin-4  mer-time-line-' + screen?.status_name}/>
                <div>{screen?.subject ? screen?.subject : (screen?.action ? screen.action : screen?.status_name)}</div>
              </div>
            {/* </div> */}
            <div className='d-flex flex-row justify-content-between'>
              <div className='mer-margin-left-20 mer-font-size-12'>{screen?.reason}</div>
              {screen?.data?.map((sub_screen, sub_index) => (
                <div className='mer-margin-left-20 mer-font-size-12' key={'sub-screen' + sub_index}>{sub_screen}</div>
              ))}
            </div>
            <div className="d-flex flex-row justify-content-between align-items-center mer-padding-top-4">
              <div className={props?.loggedInUser?._id === screen?.created_by ? ' mer-color6 mer-font-size-12 mer-margin-left-20' : 'mer-color2 mer-font-size-12 mer-margin-left-20'}>By: {props?.loggedInUser?._id === screen?.created_by ? 'You' : screen?.created_by_name}</div>
              <div className='mer-font-size-12'>
                {screen?.created_on ? DateTimeFormat(screen?.created_on) : ''}
              </div>
            </div>
          </Card>
        ))}
      </div>
    </div>
  )
}

export default Timeline
