import {
    Box,
    Button,
    CircularProgress,
    IconButton,
    InputBase,
    Menu, TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
    Typography
  } from "@mui/material";
  import InputAdornment from "@mui/material/InputAdornment";
  import Table from "@mui/material/Table";
  import TableSortLabel from "@mui/material/TableSortLabel";
  import React, { useEffect, useRef, useState } from "react";
  import { BiSearch } from "react-icons/bi";
  import { FiEye } from "react-icons/fi";
  import { MdClear, MdInfo, MdModeEditOutline } from "react-icons/md";
  import { Link, useNavigate } from "react-router-dom";
  import * as API from "../../../utills/API";
  import * as CommonMethods from "../../../utills/CommonMethods";
  import * as Messages from "../../../utills/constants/Messages";
  import { StaticRouterLinks } from "../../../utills/constants/StaticRouterLinks";
  import * as DateTimeFormats from "../../../utills/DateTimeFormats";
  import useFormData from "../../../utills/hooks/useFormData";
  import { LoadingContext } from '../../../App';
  import Skeletontable from "../../../components/Skeletons/Skeletontable";
  import useDeviceProperties from "../../../utills/hooks/useDeviceProperties";
  import { useContext } from 'react';
  function Pincodes() {
    let navigate = useNavigate();
    const [pincodesData, setPincodesData] = useState([]);
    const [isDataLoaded, SetIsDataLoaded] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(
      CommonMethods.DEFAULT_ROWS_PER_PAGE
    );
    const [totalCount, setTotalCount] = useState(0);
    const timeout = useRef();
    const [searched, setSearched] = useState("");
    const [encodedSearch, setEncodedSearch] = useState("");
    var Highlight = require("react-highlighter");
    const [openedMoreInfo, setOpenedSettingsMenu] = useState({});
    const [highlightText, setHighlightText] = useState("");
    const [searchLoader, setSearchLoader] = useState(false);
    const { showLoader, setShowLoader } = useContext(LoadingContext);
    const formData = useFormData();
    let emptyString = "";
  
    let deviceProperties = useDeviceProperties();
    const [order, setOrder] = useState("asc");
    let sortDataDetails = ["state_name", "asc"];



    const fetchPincodesData = (
      page,
      offset,
      encodedValue,
      lowerCase,
      sortDetails,
      next
    ) => {
    

      API.GetMethod(
        "pincodes?page=" +
        page +
        "&offset=" +
        offset +
        "&search=" +
        encodedValue +
        "&sort=" +
        sortDetails
      ).then((res) => {
         
        setPincodesData(res?.data?.pincodes_list ? res.data.pincodes_list : []);
        SetIsDataLoaded(true);
        setTotalCount(res.data.total_count);
        setHighlightText(lowerCase);
        setSearchLoader(false);
        setShowLoader(false);
        if (!CommonMethods.isNullOrUndefined(next)) {
          next();
        }
      });
    };
    const handleSort = (columnName) => {
      let newArr = [columnName, order === 'asc' ? 'desc' : 'asc'];
  
      if (order === "asc") {
        setShowLoader(true);
        fetchPincodesData(page, rowsPerPage, encodedSearch, searched, newArr);
        setOrder("desc");
      } else {
        setShowLoader(true);
        fetchPincodesData(page, rowsPerPage, encodedSearch, searched, newArr);
        setOrder("asc");
      }
    };
    const requestSearch = (e) => {
      let lowerCase = CommonMethods.isNullOrUndefined(e.target)
        ? ""
        : e.target.value.toLowerCase();
      setSearched(lowerCase);
      clearTimeout(timeout.current);
      let encodedValue = encodeURIComponent(lowerCase);
      setEncodedSearch(encodedValue);
      setSearchLoader(true);
      timeout.current = setTimeout(() => {
        fetchPincodesData(0, rowsPerPage, encodedValue, lowerCase, sortDataDetails, () => {
          setPage(0);
        });
      }, 1000);
    };
  
    const cancelSearch = () => {
      setEncodedSearch(emptyString);
      setSearched(emptyString);
      requestSearch(emptyString);
      setPage(0);
    };
  
    const handleChangePage = (event, newPage) => {
      SetIsDataLoaded(false);
      setPage(newPage);
      fetchPincodesData(
        newPage + 1,
        rowsPerPage,
        encodedSearch,
        searched,
        sortDataDetails
      );
    };
    const handleChangeRowsPerPage = (event) => {
      SetIsDataLoaded(false);
      setRowsPerPage(+event.target.value);
      setPage(0);
      fetchPincodesData(
        0,
        +event.target.value,
        encodedSearch,
        searched,
        sortDataDetails
      );
    };
    useEffect(() => {
      SetIsDataLoaded(false);
      // fetchPins();
      fetchPincodesData(1, rowsPerPage, emptyString, emptyString, sortDataDetails);
    }, []);



    const openMoreInfoMenu = (key, event) => {
      let openMenu = { ...openedMoreInfo };
      openMenu[key] = event.currentTarget;
      setOpenedSettingsMenu(openMenu);
    };
    const closeMenu = (key, event) => {
      let openMenu = { ...openedMoreInfo };
      openMenu[key] = null;
      setOpenedSettingsMenu(openMenu);
    };
    const editPincode = (id) => {
      navigate(StaticRouterLinks.pincode_form + id);
    };
    return (
      <div className="mer-width-percentage-100 mer-height-percentage-100">
        {isDataLoaded ? (
          <div className="d-flex justify-content-between flex-column mer-height-percentage-100 flex-grow-1">
            <div>
              <div className="d-flex justify-content-between mer-margin-12 mer-height-35">
                <Typography variant="h6" className="d-flex align-items-center mer-margin-right-8">
                    Pin codes
                </Typography>
                <div className="d-flex align-items-center">
                  <InputBase
                    className="tablesearch mer-margin-right-20 mer-padding-0"
                    onChange={requestSearch}
                    placeholder="Search"
                    value={searched}
                    endAdornment={
                      <InputAdornment position="end">
                        {searched.length === 0 ? (
                          <div></div>
                        ) : searchLoader ? (
                          <Box className="d-flex mer-margin-right-10">
                            <CircularProgress size="1.3rem" />
                          </Box>
                        ) : (
                          <MdClear
                            className="mer-height-23 mer-width-23 mer-cursor-pointer iconclr mer-margin-right-10"
                            onClick={cancelSearch}
                          />
                        )}
                      </InputAdornment>
                    }
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton className="mer-margin-top-0">
                          <BiSearch className="mer-height-20 mer-width-20" />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <Tooltip title={formData?.can_edit ? "" : Messages.NO_CREATE} arrow >
                    <div>
                      <Button
                        className="mer-min-width-105"
                        variant="contained"
                        component={Link}
                        to={StaticRouterLinks.pincode_form}
                        disabled={formData?.can_edit ? false : true} >
                        {CommonMethods.ADDNEW_BUTTON}
                      </Button>
                    </div>
                  </Tooltip>
                </div>
              </div>
              <TableContainer sx={{ maxHeight: `${deviceProperties.height - (deviceProperties.isMobileDevice ? 230 : 180)}px`, maxWidth: `${deviceProperties.width}px`, padding: '0px 10px' }}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  size="small"
                  className="mer-table" >
                  <TableHead>
                    <TableRow>
                      
                    <TableCell className="mer-width-percentage-20">
                        <TableSortLabel
                          direction={order}
                          onClick={() => { handleSort("state_name"); }}>State
                        </TableSortLabel>
                      </TableCell>

                      <TableCell className="mer-width-percentage-20">
                        <TableSortLabel
                          direction={order}
                          onClick={() => { handleSort("pincode");}}>Pin code
                        </TableSortLabel>
                      </TableCell>

                      <TableCell className="mer-width-percentage-20">
                        <TableSortLabel
                          direction={order}
                          onClick={() => { 
                            // handleSort("city_name");
                            handleSort("division_name"); 
                            }}>City
                        </TableSortLabel>
                      </TableCell>
                    
                      <TableCell className="mer-width-percentage-20">
                        <TableSortLabel
                          direction={order}
                          onClick={() => { handleSort("country_name"); }}>Country
                        </TableSortLabel>
                      </TableCell>
                      {/* <TableCell className="mer-width-percentage-10">
                        <TableSortLabel
                          direction={order}
                          onClick={() => { handleSort("status_name"); }}>Status
                        </TableSortLabel>
                      </TableCell> */}
                      <TableCell className="mer-width-percentage-10"
                        align="center" >
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pincodesData?.length === 0 ? (
                      <div></div>
                    ) : (
                      pincodesData?.map((pincode, key) => (
                        <TableRow
                          key={key}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}>
                          <TableCell className="mer-width-percentage-25"
                            title={pincode.state_name} >
                            <Highlight
                              search={highlightText}
                              matchStyle={{ backgroundColor: "#FCF55F" }} >
                              {pincode.state_name}
                            </Highlight>
                          </TableCell>
                          <TableCell className="mer-width-percentage-25"
                            title={pincode.pincode} >
                            <Highlight
                              search={highlightText}
                              matchStyle={{ backgroundColor: "#FCF55F" }}>
                              {pincode.pincode.toString()}
                            </Highlight>
                          </TableCell>
                          <TableCell
                            className="mer-width-percentage-25"
                            title={pincode.city_name}>
                            <Highlight
                              search={highlightText}
                              matchStyle={{ backgroundColor: "#FCF55F" }}>
                              {/* {pincode.city_name} */}
                              {pincode.division_name}
                            </Highlight>
                          </TableCell>
                          <TableCell
                            className="mer-width-percentage-25"
                            title={pincode.country_name}>
                            <Highlight
                              search={highlightText}
                              matchStyle={{ backgroundColor: "#FCF55F" }} >
                              {pincode.country_name}
                            </Highlight>
                          </TableCell>
                        
                          <TableCell className="mer-width-percentage-10">
                            <div className="d-flex flex-row align-items-center justify-content-around">
                              <Tooltip title={formData?.can_view ? "View" : Messages.NO_VIEW} arrow>
                                <div>
                                    <FiEye
                                    disabled={formData?.can_view ? false : true}
                                    onClick={() => {
                                      editPincode(pincode._id);
                                    }}
                                    className="mer-width-25 mer-height-20 mer-cursor-pointer mer-icon-color" />
                                </div>
                              </Tooltip>
                              <Tooltip title={ formData?.can_edit ? "Edit" : Messages.NO_EDIT } arrow >
                                <div>
                                    <MdModeEditOutline 
                                    className="mer-width-25 mer-height-20 mer-cursor-pointer mer-icon-color"
                                    disabled={formData?.can_edit ? false : true}
                                    onClick={() => {
                                      editPincode(pincode._id);
                                    }} />
                                </div>
                              </Tooltip>
                              <Tooltip title="More Info" placement="top" arrow style={{ cursor: 'pointer' }}>
                                <div>
                                  <MdInfo
                                    aria-controls={key}
                                    aria-haspopup="true"
                                    className="mer-width-20 mer-height-20 mer-margin-right-5 mer-cursor-pointer mer-icon-color"
                                    onClick={(e) => openMoreInfoMenu(key, e)}
                                  />
                                  <Menu
                                    id={key}
                                    keepMounted
                                    anchorEl={openedMoreInfo[key]}
                                    open={Boolean(openedMoreInfo[key])}
                                    // getContentAnchorEl={null}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "center",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "center",
                                    }}
                                    onClose={(e) => closeMenu(key, e)}
                                  >
                                    {
                                      <div className="mer-margin-10">
                                        <div>
                                          Created on:
                                          <b>
                                            {" "}
                                            {pincode.created_on
                                              ? DateTimeFormats.DateTimeFormat(
                                                pincode.created_on
                                              )
                                              : "NA"}
                                          </b>
                                        </div>
                                        <div>
                                          Created by:{" "}
                                          <b>
                                            {pincode.created_by_name
                                              ? pincode.created_by_name
                                              : "NA"}
                                          </b>
                                        </div>
                                        <div>
                                          Modified on:{" "}
                                          <b>
                                            {pincode.modified_on
                                              ? DateTimeFormats.DateTimeFormat(
                                                pincode.modified_on
                                              )
                                              : "NA"}
                                          </b>
                                        </div>
                                        <div>
                                          Modified by:{" "}
                                          <b>
                                            {pincode.modified_by_name
                                              ? pincode.modified_by_name
                                              : "NA"}
                                          </b>
                                        </div>
                                      </div>
                                    }
                                  </Menu>
                                </div>
                              </Tooltip>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            {pincodesData?.length === 0 ? (
              <div className="d-flex justify-content-center align-items-center mer-height-percentage-100">
                No data to display
              </div>
            ) : (
              <div></div>
            )}
            <div className="d-flex mer-width-percentage-100 justify-content-end align-self-end">
              <TablePagination
                labelRowsPerPage={CommonMethods.PAGINATION_ROW_LABEL}
                rowsPerPageOptions={CommonMethods.ROWS_PER_PAGE}
                component="div"
                className="mer-pagination mer-width-percentage-100"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
        ) : (
          <Skeletontable></Skeletontable>
        )}
      </div>
    );
  }
  
  export default Pincodes;
  