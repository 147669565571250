import React, { useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import CandidateDetails from "./CandidateDetails";
import { Divider, Paper, useTheme, Card } from "@mui/material";
import * as API from "../../utills/API";
import { SideMenuContext, LoggedInUserContext } from "../../App";

import {
  isNullOrUndefined,
  removeEmptyObjectFromArray,
} from "../../utills/CommonMethods";
import * as sideMenuService from "../../utills/constants/SideMenu";
import useSideMenu from "../../utills/hooks/useSideMenu";
import { Api } from "@mui/icons-material";
import IMAGES from "../../utills/constants/Images";

function CandidateRouting() {
  const { sideMenu, setSideMenu } = useContext(SideMenuContext);
  const location = useLocation();
  const params = useParams();
  const { loggedInUser, setLoggedInUser } = useContext(LoggedInUserContext);
  const { updateSideMenu } = useSideMenu();
  const [candidateDetails, setCandidateDetails] = useState(null);
  let theme = useTheme();
  // useEffect(()=>{
  //     updateSideMenu(`menu_id=62d7e4aaf938fa0c37551709`,params,true);
  // },[])

  // async function updateSideMenu(menu_name){
  //     let side_menu_response = await sideMenuService.GetSideMenu(menu_name)

  //     let side_menu_data = side_menu_response['data'].length > 0 ? side_menu_response['data'][0] : [];

  //     let forms = removeEmptyObjectFromArray(side_menu_data.forms)

  //     let sideMenuItems = forms.length > 0 ? forms : [];

  //     setSideMenu({open: sideMenuItems.length > 0 ? true : false , sideMenuItems: sideMenuItems});

  // }

  useEffect(() => {
    getCandidateHeader();
  },[]);

  function getCandidateHeader() {
    API.GetMethod("candidateDetails/basicInfo/" + params?.id).then(
      (response) => {
        setCandidateDetails(response);
      },
      (error) => {
        setCandidateDetails(null);
      }
    );
  }

  return (
    <Paper
      sx={{
        backgroundImage:
          theme.palette.mode === "light"
            ? "url(" + IMAGES?.page_background_light + ")"
            : "url(" + IMAGES?.page_background_dark + ")",
      }}
      className="d-flex flex-column mer-fill flex-grow-1 mer-overflow-hidden"
    >
      {!(candidateDetails?.is_archive_profile && loggedInUser?.role_info?.is_it_interviewer_role) ? (
        <div className="d-flex flex-column mer-overflow-y-auto">
          <CandidateDetails />
          {/* <Divider className='mer-margin-top-0 mer-margin-bottom-0 mer-margin-left-20 mer-margin-right-20'/> */}
          <Outlet className="d-flex flex-grow-1 mer-overflow" />
        </div>
      ) : (
        <div className='d-flex flex-column align-items-center mer-height-percentage-100 justify-content-center'>
            <Card className="mer-theme-error-card mer-padding-8 mer-margin-0">
            This profile has been archived. You can't perform any action.
            </Card>
        </div>
      )}
    </Paper>
  );
}

export default CandidateRouting;
