import Button from "@mui/material/Button";
import {
  Dialog,
  DialogTitle,
  Card,
  CircularProgress,
  Box,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import create from "zustand";
import Timeline from "../pages/candidates/Profile/Timeline";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Snackbar, Slide, Tooltip } from "@mui/material";
import React, { useState } from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import * as CommonMethods from "../utills/CommonMethods";
import RolesToBeConsideredForm from "../pages/candidates/interviews/role-to-be-considered/RolesToBeConsideredForm";
import * as API from "../utills/API";
import ProposalForm from "../pages/candidates/proposal/ProposalForm";
import useDeviceProperties from "./hooks/useDeviceProperties";
import { VscChromeMinimize } from "react-icons/vsc";
import parse from "html-react-parser";
import DownloadAllDocuments from "../pages/candidates/documents/DownloadAllDocuments";
import OtpDialog from "../components/login/Otp";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useSimpleDialogStore = create((set) => ({
  message: "",
  onSubmit: undefined,
  close: () =>
    set({
      onSubmit: undefined,
    }),
}));

const SimpleDialog = () => {
  const { message, onSubmit, close } = useSimpleDialogStore();
  return (
    <Dialog
      open={Boolean(onSubmit)}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          close();
        }
      }}
    >
      <DialogTitle>Confirm Action</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            onSubmit();
            close();
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { SimpleDialog };

export const simple_dialog = (message, onSubmit) => {
  useSimpleDialogStore.setState({ message, onSubmit });
};

const useConfirmationDialogStore = create((set) => ({
  message: "",
  onSubmit: undefined,
  close: () =>
    set({
      onSubmit: undefined,
    }),
  button: "",
  note: "",
  useLoader: false,
}));

const ConfirmationDialog = () => {
  const { message, note, onSubmit, close, button, onClose, useLoader } =
    useConfirmationDialogStore();

  const [loading, setLoading] = useState(false);
  return (
    <Dialog
      className="confirm-action-dialog-box"
      open={Boolean(onSubmit)}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          close();
        }
      }}
    >
      <DialogTitle>Confirm Action</DialogTitle>
      <DialogContent>
        <DialogContentText>{parse(message)}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <div className="d-flex flex-row flex-wrap  mer-width-percentage-100 mer-padding-left-16 mer-padding-right-16 justify-content-end">
          {!CommonMethods.isNullOrUndefined(note) ? (
            <div
              className="d-flex  col-12 col-sm-6 col-md-6 col-lg-7 col-xl-7 align-items-center "
              style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}
            >
              <span className="mer-note">Note: </span>
              <span className="mer-font-size-12">{note}</span>
            </div>
          ) : null}
          <div className="d-flex  col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5 justify-content-end align-items-center">
            <Button
              className="mer-margin-right-10 outlinedcls"
              disabled={loading}
              onClick={() => {
                if (!CommonMethods.isNullOrUndefined(onClose)) {
                  onClose();
                }
                close();
                setLoading(false);
              }}
            >
              <div className="mer-width-percentage-100">No</div>
            </Button>
            <Button
              className=""
              variant="contained"
              onClick={async () => {
                useLoader && setLoading(true);
                await onSubmit();
                close();
                setLoading(false);
              }}
              sx={{ padding: "6px" }}
            >
              <div className="mer-width-percentage-100 text-capitalize">
                {CommonMethods.isNullOrUndefined(button) ? "Yes" : button}
              </div>
              {loading && (
                <Box className="d-flex mer-margin-left-4">
                  <CircularProgress size="1.2rem" />
                </Box>
              )}
            </Button>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export const confirmation_dialog = (
  message,
  onSubmit,
  note,
  button = "Yes",
  onClose,
  useLoader = false
) => {
  useConfirmationDialogStore.setState({
    message,
    onSubmit,
    note,
    button,
    onClose,
    useLoader,
  });
};
export { ConfirmationDialog };

const track_status_dialog_store = create((set) => ({
  candidate: "",
  open: null,
  loggedInUser: null,
  close: () =>
    set({
      open: null,
    }),
}));

export const track_status_dialog = (open, candidate, loggedInUser) => {
  track_status_dialog_store.setState({ open, candidate, loggedInUser });
};

const TrackStatus = () => {
  const { open, close, candidate, loggedInUser } = track_status_dialog_store();
  let deviceProperties = useDeviceProperties();

  return (
    <Dialog
      className={
        "dialog-box " +
        (deviceProperties.isTabletDevice || deviceProperties.isMobileDevice)
          ? " mer-timeline-dialog-mobile"
          : " mer-timeline-dialog"
      }
      TransitionComponent={Transition}
      open={Boolean(open)}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          close();
        }
      }}
    >
      {/* <Card className="mer-theme-dialog-header-bg d-flex flex-column justify-content-between"> */}
      {/* <DialogTitle>
          Track status for profile - <span className="mer-color2">{CommonMethods.concateNames(candidate?.first_name, candidate?.middle_name, candidate?.last_name)}</span>
        </DialogTitle> */}
      {/* <DialogActions>
          <AiOutlineCloseCircle
            className="mer-width-20 mer-height-20 mer-color-red mer-cursor-pointer"
            onClick={() => {
              close();
            }}
          ></AiOutlineCloseCircle>
        </DialogActions> */}
      <Card className="mer-theme-dialog-header-bg d-flex flex-row justify-content-between">
        <div className="mer-font-bold mer-padding-8">
          Track status for profile -{" "}
          {/* <span className="mer-color2"> */}
          <b>
            {CommonMethods.concateNames(
              candidate?.first_name,
              candidate?.middle_name,
              candidate?.last_name
            )}
            </b>
          {/* </span> */}
        </div>
        <Tooltip title="Minimize">
          <DialogActions>
            <VscChromeMinimize
              className="mer-width-20 mer-height-20 mer-cursor-pointer"
              onClick={() => {
                close();
              }}
            ></VscChromeMinimize>
          </DialogActions>
        </Tooltip>
      </Card>
      <Timeline candidate={candidate} loggedInUser={loggedInUser} />
      {/* </Card> */}
      {/* <DialogContent>
        <Timeline candidate={candidate} loggedInUser={loggedInUser}></Timeline>
      </DialogContent> */}
    </Dialog>
  );
};

export { TrackStatus };

const alertStore = create((set) => ({
  title: "",
  message: "",
  severity: "",
  open: undefined,
  close: () =>
    set({
      open: undefined,
    }),
}));

const AlertDialog = () => {
  const { title, message, severity, open, close } = alertStore();
  const handleClose = (event, reason) => {
    if ("clickaway" === reason) return;
    close();
  };
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={Boolean(open)}
      onClose={handleClose}
      autoHideDuration={CommonMethods.ALERT_TIMEOUT}
    >
      <Alert
        severity={severity}
        onClose={() => {
          handleClose();
        }}
      >
        <AlertTitle className="mer-font-bold">{title}</AlertTitle>
        {message}
      </Alert>
    </Snackbar>
  );
};

export { AlertDialog };
export const alert_snackbar = (title, message, severity, open) => {
  alertStore.setState({ title, message, severity, open });
};

const statusStore = create((set) => ({
  dialogDetails: {},
  onSubmit: undefined,
  close: () =>
    set({
      onSubmit: undefined,
    }),
}));

const StatusDialog = () => {
  const { dialogDetails, onSubmit, close } = statusStore();
  return (
    <Dialog
      className={dialogDetails?.className}
      open={Boolean(onSubmit)}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          close();
        }
      }}
    >
      <DialogTitle>
        <div className="d-flex flex-row align-items-center">
          <span>{dialogDetails?.iconName}</span>
          <div className="mer-margin-left-10 mer-font-size-18">
            {dialogDetails?.title}
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogDetails?.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            onSubmit();
            close();
          }}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { StatusDialog };

export const status_dialog = (dialogDetails, onSubmit) => {
  statusStore.setState({ dialogDetails, onSubmit });
};

const roles_to_be_considered_for_store = create((set) => ({
  candidate: "",
  action: "",
  open: null,
  close: (status) =>
    set({
      open: null,
    }),
  callback: undefined,
}));

function triggerApi(action, candidate, callback) {
  if (!CommonMethods.isNullOrUndefined(action.confirmation_api)) {
    API.PostMethod(action.confirmation_api, {
      action: action,
      candidate: candidate,
    }).then((res) => {
      callback(res);
    });
  }
}

export const roles_to_be_considered_for_dialog = (
  open,
  candidate,
  action,
  callback
) => {
  roles_to_be_considered_for_store.setState({
    open,
    candidate,
    action,
    callback,
  });
};

const RolesToBeConsideredForDialog = () => {
  let deviceProperties = useDeviceProperties();
  const { open, close, candidate, action, callback } =
    roles_to_be_considered_for_store();
  return (
    <Dialog
      className={
        !(
          deviceProperties?.isMobileDevice || deviceProperties?.isTabletDevice
        ) && "mer-role-to-considered-dialog"
      }
      open={Boolean(open)}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          close();
        }
      }}
    >
      <Card className="mer-theme-dialog-header-bg-tables d-flex flex-row justify-content-between">
        {/* <DialogTitle > */}
        <div className="mer-font-bold mer-margin-8">
          Role to be considered for -{" "}
          {/* <span className="mer-color2"> */}
            {CommonMethods.concateNames(
              candidate?.first_name,
              candidate?.middle_name,
              candidate?.last_name
            )}
          {/* </span> */}
        </div>
        {/* </DialogTitle> */}
        <DialogActions>
          <AiOutlineCloseCircle
            className="mer-width-20 mer-height-20 mer-cursor-pointer"
            onClick={() => {
              close();
            }}
          ></AiOutlineCloseCircle>
        </DialogActions>
      </Card>
      <DialogContent className="d-flex flex-grow-1 mer-padding-0">
        <RolesToBeConsideredForm
          className="d-flex flex-grow-1"
          id={candidate._id}
          onChange={(status) => {
            status && triggerApi(action, candidate, callback);
            close(status);
          }}
        ></RolesToBeConsideredForm>
      </DialogContent>
    </Dialog>
  );
};

export { RolesToBeConsideredForDialog };

const proposal_store = create((set) => ({
  candidate: "",
  action: "",
  open: null,
  close: (status) =>
    set({
      open: null,
    }),
  callback: undefined,
}));

export const proposal_dialog = (open, candidate, action, callback) => {
  proposal_store.setState({ open, candidate, action, callback });
};

const ProposalDialog = () => {
  const { open, close, candidate, action, callback } = proposal_store();
  return (
    <Dialog
      className="mer-track-status-dialog"
      open={Boolean(open)}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          close();
        }
      }}
    >
      <div className="d-flex flex-row justify-content-between">
        <DialogTitle>
          Proposal for {candidate.first_name} {candidate.last_name}
        </DialogTitle>
        <DialogActions>
          <AiOutlineCloseCircle
            className="mer-width-20 mer-height-20 mer-cursor-pointer"
            onClick={() => {
              close();
            }}
          ></AiOutlineCloseCircle>
        </DialogActions>
      </div>
      <DialogContent className="d-flex flex-grow-1">
        <ProposalForm
          className="d-flex flex-grow-1"
          id={candidate._id}
          onChange={(status) => {
            triggerApi(action, candidate, callback);
            close(status);
          }}
        ></ProposalForm>
      </DialogContent>
    </Dialog>
  );
};

export { ProposalDialog };

const all_documents_download_store = create((set) => ({
  candidate: "",
  action: "",
  open: null,
  close: (status) =>
    set({
      open: null,
    }),
  callback: undefined,
}));

export const all_documents_download_dialog = (
  open,
  candidate,
  action,
  callback
) => {
  all_documents_download_store.setState({ open, candidate, action, callback });
};

const AllDocumentsDownloadDialog = () => {
  const { open, close, candidate, action, callback } =
    all_documents_download_store();
  return (
    <Dialog
      open={Boolean(open)}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          close();
        }
      }}
    >
      <div className="d-flex flex-row align-items-center justify-content-between">
        <div className="p-3">
          <span>Are you sure to download all documents of </span>
          <b>
            <span>
              {candidate.first_name} {candidate.last_name}
            </span>
          </b>
          ?
        </div>
        <DialogActions>
          <AiOutlineCloseCircle
            className="mer-width-20 mer-height-20 mer-cursor-pointer"
            onClick={() => {
              close();
            }}
          ></AiOutlineCloseCircle>
        </DialogActions>
      </div>
      <DialogContent className="d-flex flex-grow-1 align-items-center justify-content-end py-2">
        <DownloadAllDocuments
          className="d-flex flex-grow-1"
          id={candidate._id}
          onChange={(status) => {
            triggerApi(action, candidate, callback);
            close(status);
          }}
        ></DownloadAllDocuments>
      </DialogContent>
    </Dialog>
  );
};

export { AllDocumentsDownloadDialog };

const useOTPDialogStore = create((set) => ({
  message: "",
  open: false,
  refresh: false,
  close: () =>
    set({
      open: false,
    }),
}));

const OTPDialog = () => {
  const { message, close, open, refresh, metadata } = useOTPDialogStore();
  return (
    <OtpDialog
      refresh={refresh}
      open={open}
      handleClose={close}
      message={message}
      metadata={metadata}
    />
  );
};

export { OTPDialog };
export const otp_dialog = (open, message, metadata, refresh) => {
  useOTPDialogStore.setState({ open, message, refresh, metadata });
};
