import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import * as API from "../../utills/API";

import { LoggedInUserContext } from "../../App";
import { isNullOrUndefined } from "../CommonMethods";

const useFormData = (props) => {

    const [formData, setFormData] = useState(null);
    const { loggedInUser, setLoggedInUser } = useContext(LoggedInUserContext);

    const location = useLocation();

    useEffect(() => {
        getFormData();
    }, []);

    async function getFormData() {

        let form_location = isNullOrUndefined(props) ? location : props;
        if(!isNullOrUndefined(localStorage.getItem("mer-auth-key")) || !isNullOrUndefined(localStorage.getItem("mer-uuid"))){
            if (!isNullOrUndefined(loggedInUser) && !isNullOrUndefined(loggedInUser['screens'])) {
                let forms = !isNullOrUndefined(loggedInUser['screens']) ? loggedInUser['screens']['forms'] : [];
    
                let results = forms.filter(form => form_location?.pathname.toString().startsWith(form.base_path));
    
                let formData = results.length > 0 ? results[0] : results
                setFormData(formData);
            }
            else {
                API.GetMethod("getUserContext").then((user_context_response) => {
                    let user_data = !isNullOrUndefined(user_context_response['data']) ? user_context_response['data'] : {}
    
                    let forms = !isNullOrUndefined(user_data['screens']) ? user_data['screens']['forms'] : [];
    
                    let results = forms.filter(form => form_location?.pathname.toString().startsWith(form.base_path));
    
                    let formData = results.length > 0 ? results[0] : results
                    setFormData(formData);
                });
            }
        }
    }

    return formData;
}

export default useFormData;