import React, { useEffect, useState } from "react";
import {
    confirmation_dialog,
    simple_dialog,
    status_dialog,
} from "../../../utills/Alerts";
import * as Messages from "../../../utills/constants/Messages";
import * as API from "../../../utills/API";
import * as CommonMethods from "../../../utills/CommonMethods";
import { IoMdCloseCircleOutline } from "react-icons/io";
import {
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Box,
    CircularProgress,
} from "@mui/material";
import create from "zustand";
import useFormData from "../../../utills/hooks/useFormData";
import useDeviceProperties from "../../../utills/hooks/useDeviceProperties";
import { useParams } from "react-router-dom";


export const all_documents_download_dialog = async (open, action, candidate, callback) => {
    AllDocumentsDownloadDialogStore.setState({ open, action, candidate, callback });
};

const AllDocumentsDownloadDialogStore = create((set) => ({
    open: "",
    action: "",
    candidate: "",
    close: () =>
        set({
            open: false,
        }),
    callback: undefined,
}));

function DownloadAllDocuments(props) {
    const { open, close, candidate, action, callback, templateData } = AllDocumentsDownloadDialogStore();

    const formData = useFormData();
    let deviceProperties = useDeviceProperties();
    const BUTTON_VALUE = 'Download';

    const params = useParams();

    const [showDownloadLoader, setShowDownloadLoader] = useState(false);
    useEffect(() => {

    }, [candidate]);

    function downloadAllDocuments() {
        setShowDownloadLoader(true);
        let event_info = CommonMethods.getEventInfo(formData.form_id, formData.base_path, BUTTON_VALUE);

        let payLoad = {}
        payLoad['_id'] = candidate['_id']

        API.PostMethod('candidateDetails/downloadDocuments', payLoad, event_info).then((res) => {
            let zip_url = `${process.env.REACT_APP_BASE_URL}/files${res['data']['path']}`;
            window.open(zip_url, '_blank', 'noopener,noreferrer');
            let status_details = {
                ...CommonMethods.STATUS_POPUP_DETAILS.success,
                message: res.message,
            };
            status_dialog(status_details, () => {
                callback(true);
                close();
                setShowDownloadLoader(false);
                // EventEmitter.emit('refreshHeader', { refresh: true })
            });
        }).catch((error) => {
            setShowDownloadLoader(false);
        });
    }


    return (
        <Dialog open={Boolean(open)}
            onClose={() => { close() }} disableEscapeKeyDown={true}>
            <DialogTitle className="mer-theme-dialog-header-bg-tables mer-padding-8">
                <div className=" d-flex flex-row align-items-center justify-content-between">
                    <div className="mer-font-weight-150 mer-font-size-18">
                        {action.action_name} ?
                    </div>
                    <DialogActions>
                        <IoMdCloseCircleOutline
                            onClick={() => {
                                close();
                            }}
                            className="mer-min-height-24 mer-min-width-24 mer-cursor-pointer"
                        />
                    </DialogActions>
                </div>
            </DialogTitle>
            <DialogContent className="mer-padding-16">
                <div className="d-flex justify-contet-center flex-grow-1 align-items-center">
                    {action.confirmation_message}
                </div>
            </DialogContent>
            <DialogActions>
                <div className='d-flex flex-row flex-grow justify-content-end align-items-center'>
                    <Button variant="outlined" className="outlinedcls mer-margin-right-8" disabled={showDownloadLoader}
                        onClick={() => {
                            close();
                        }}>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={downloadAllDocuments}>
                        {!showDownloadLoader ? "Download"
                            : <Box className="d-flex align-items-center">
                                Download <CircularProgress className='mer-margin-left-4' size="1rem"/>
                            </Box>}
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
}

export default DownloadAllDocuments;
