import React from 'react';
import { CircularProgress , Box} from '@mui/material';

function Loader() {

    return (
        <div className='loader-style d-flex justify-content-center align-items-center mer-fill'>
            <Box sx={{ display: 'flex' }}>
                <CircularProgress />
            </Box>
        </div>
    )
}

export default Loader
