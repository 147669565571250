import { useState } from "react";

const useSelect = ({ candidatesData }) => {
  const [selected, setSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAllClick = () => {
    if (selectAll) {
      setSelected([]);
    } else {
      setSelected(candidatesData.map((candidate) => candidate._id));
    }
    setSelectAll(!selectAll);
  };

  const handleCheckboxClick = (id) => {
    const newSelected = selected?.includes(id)
      ? selected.filter((selectedId) => selectedId !== id)
      : [...selected, id];
    setSelected(newSelected);
    if (newSelected?.length === candidatesData?.length) {
      setSelectAll(true);
    }
    if (newSelected.length === 0) {
      setSelectAll(false);
    }
  };

  function resetSelected() {
    setSelectAll((prev) => false);
    setSelected((prev) => []);
  }

  return {
    selected,
    selectAll,
    handleSelectAllClick,
    handleCheckboxClick,
    resetSelected,
  };
};

export default useSelect;
