import { Skeleton } from '@mui/material';
import React from 'react';


function SkeletonRegpopup() {
 

  return (
    <>
     <div className='d-flex flex-row flex-wrap  mer-margin-top-50 mer-margin-left-12'>
          <div className='d-flex flex-column col-md-6 col-lg-4 col-sm-12 col-12 col-xs-12 '>
            <Skeleton variant="rounded" height={118} className='mer-skel-view mer-theme-skel-view'></Skeleton>
          </div>
          <div className='d-flex flex-column col-md-6 col-lg-4 col-sm-12 col-12 col-xs-12 '>
            <Skeleton variant="rounded" height={118} className='mer-skel-view mer-theme-skel-view'></Skeleton>
          </div>
          <div className='d-flex flex-column col-md-6 col-lg-4 col-sm-12 col-12 col-xs-12 '>
            <Skeleton variant="rounded" height={118} className='mer-skel-view mer-theme-skel-view'></Skeleton>
          </div>
          <div className='d-flex flex-column col-md-6 col-lg-4 col-sm-12 col-12 col-xs-12 '>
            <Skeleton variant="rounded" height={118} className='mer-skel-view mer-theme-skel-view'></Skeleton>
          </div>
    </div>
    </>
    
  )
}

export default SkeletonRegpopup