import React, { useContext, useEffect, useState } from "react";
import * as API from "../../utills/API";
import { useParams } from "react-router-dom";
import * as commonMethods from "../../utills/CommonMethods";
import {
  Snackbar,
  IconButton,
  Dialog,
  DialogContent,
  Button,
  useMediaQuery,
  useTheme,
  Slide,
  Divider,
  DialogTitle,
  Menu,
  DialogActions,
  Tooltip,
} from "@mui/material";
import { RiFileCopyFill } from "react-icons/ri";
//import IconButton from "@mui/material/IconButton";
// import Snackbar from "@material-ui/core/Snackbar";
import { MdOutlineShowChart, MdContentPasteOff } from "react-icons/md";
import { TbPlayerSkipForward } from "react-icons/tb";
import Timeline from "../candidates/Profile/Timeline";
import { Card } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useDeviceProperties from "../../utills/hooks/useDeviceProperties";
import { BiDetail } from "react-icons/bi";
import { MdClose } from "react-icons/md";
import { VscChromeMinimize } from "react-icons/vsc";
import { IoCloseCircleOutline } from "react-icons/io5";
import CandidateRejectForm from "./CandidateRejectForm";
import {
  DisableFormsContext,
  LoggedInUserContext,
  SideMenuContext,
} from "../../App";
import {
  confirmation_dialog,
  roles_to_be_considered_for_dialog,
  track_status_dialog,
  proposal_dialog,
  simple_dialog,
  status_dialog,
} from "../../utills/Alerts";
import { add_notes_dialog } from "./AddNotesForm";
import { BsBoxArrowUpRight } from "react-icons/bs";
import EventEmitter from "../../utills/EventEmitter";
import { StaticRouterLinks } from "../../utills/constants/StaticRouterLinks";
import useSideMenu from "../../utills/hooks/useSideMenu";
import { FiDownload } from "react-icons/fi";
import { request_docs_dialog } from "./documents/RequestDocumentsForm";
import { offer_letter_dialog } from "./OfferLetterForm";
import { all_documents_download_dialog } from "./documents/DownloadAllDocuments";
import { sync_data_to_employeedb_dialog } from "./SyncDatatoEmployeeDBForm";
import * as Messages from "../../utills/constants/Messages";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CandidateDetails() {
  const params = useParams();
  let deviceProperties = useDeviceProperties();
  const navigate = useNavigate();

  const [candidateDetails, setCandidateDetails] = useState(null);
  const [splicedArray, setSplicedArray] = useState([]);

  const [opend, setOpenD] = useState(false);
  const [open_timeline, setTimeLine] = useState(false);
  const [ope_reject_dialog, setOpenRejectDialog] = useState(false);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(null);

  const [actionList, setActionList] = useState([]);
  const [rejectAction, setRejectAction] = useState([]);
  const [opened_actions_menu, setOpenedActionsMenu] = useState(null);
  const [anchor_EL, setAnchorEl] = useState(null);
  const { loggedInUser, setLoggedInUser } = useContext(LoggedInUserContext);
  const { updateSideMenu } = useSideMenu();

  useEffect(() => {
    if (!commonMethods.isNullOrUndefined(params?.id)) {
      getCandidateHeader();
      fetchRejectAction();
    }
    EventEmitter.on("refreshHeader", (newValue) => {
      if (newValue.refresh) {
        getCandidateHeader();
      }
    });
  }, [params]);

  function getCandidateHeader() {
    API.GetMethod("candidateDetails/basicInfo/" + params?.id).then(
      (response) => {
        if (response.is_archive_profile === false) {
          let candidateInfo = response.data[0];
          setCandidateDetails(candidateInfo);
          let newArr = [];
          let rolesInterviewdArray = newArr.concat(
            response.data[0].roles_to_be_considered
          );
          rolesInterviewdArray.splice(0, 1);
          setSplicedArray(rolesInterviewdArray);
          //getDisableForms(candidateInfo['status_id'])
        } else {
          if (
            response.is_archive_profile &&
            loggedInUser?.role_info?.is_it_interviewer_role
          ) {
          } else {
            updateSideMenu();
            navigate(StaticRouterLinks.one_pager_view + params?.id);
          }
          // window.location.reload();
        }
      },
      (error) => {
        setCandidateDetails(null);
      }
    );
  }

  const handleToClose = (event, reason) => {
    if ("clickaway" === reason) return;
    setOpen(false);
  };

  function RejectCandidateComponent(action, candidate, index) {
    // setSelectedProfile(candidate);
    setOpenRejectDialog(true);
    // reject_candidate_dialog('true', action, candidate, 1, props);
  }

  function fetchActionData(statusID, candidateId) {
    API.GetMethod(`status/${statusID}/actions/${candidateId}`).then((res) => {
      let actionsList = [];
      res.data.action_id_info.forEach((action) => {
        if (
          !commonMethods.isNullOrUndefined(action?.is_forward_action) &&
          action.is_forward_action
        ) {
          actionsList.push(action);
        }
      });
      setActionList(actionsList);
    });
  }

  function fetchRejectAction(statusID) {
    API.GetMethod("action/reject").then((res) => {
      let reject_action;
      if (!commonMethods.isNullOrUndefined(res.data) && res.data.length > 0) {
        reject_action = res.data[0];
      }
      setRejectAction(reject_action);
    });
  }

  function forward(candidate) {
    fetchActionData(candidate.status_id, candidate?._id);
    setOpenedActionsMenu(true);
  }

  function performAction(action, candidate) {
    if (!commonMethods.isNullOrUndefined(action.component_name)) {
      // setOpenedActionsMenu(false);
      eval(
        action.component_name +
          "(" +
          JSON.stringify(action) +
          "," +
          JSON.stringify(candidate) +
          ")"
      );
    }
  }

  function refreshSideMenu() {
    updateSideMenu(`menu_id=62d7e4aaf938fa0c37551709`, params, true);
  }

  function ConfirmationDialogComponent(action, candidate) {
    // When the user clicks on "Skip Request Documents" without having the documents, we are adding an extra warning message.
    if (
      action.action_id == "skip_request_documents" &&
      !candidate["data_checks"]["documents_available"]
    ) {
      action.confirmation_message =
        "You haven't requested the documents yet. " +
        action.confirmation_message;
    }
    // When the user clicks on "Skip Offer Letter Email" without requesting earlier, we are adding an extra warning message.
    else if (
      action.action_id == "skip_offer_letter_email" &&
      !candidate["data_checks"]["offer_letter_sent"]
    ) {
      action.confirmation_message =
        "You haven't sent the offer letter email yet. " +
        action.confirmation_message;
    }
    confirmation_dialog(
      action.confirmation_message,
      (confirmation_response) => {
        setOpenedActionsMenu(false);
        if (!commonMethods.isNullOrUndefined(action.confirmation_api)) {
          API.PostMethod(action.confirmation_api, {
            action: action,
            candidate: candidate,
          }).then((res) => {
            if (res) {
              if (action.success_message) {
                let status_details = {
                  ...commonMethods.STATUS_POPUP_DETAILS.success,
                  message: action.success_message,
                };
                status_dialog(status_details, () => {
                  getCandidateHeader();
                  refreshSideMenu();
                });
                // simple_dialog(action.success_message, () => {
                //     getCandidateHeader();
                // });
              } else {
                getCandidateHeader();
                refreshSideMenu();
              }
              // props.onChange();
              // fetchCandidatesData(props?.stage_info?._id, page, rowsPerPage, encodedSearch);
              // getStatusList(props?.stage_info?._id);
            }
          });
        } else {
          // props.onChange();
          // fetchCandidatesData(props?.stage_info?._id, page, rowsPerPage, encodedSearch);
          // getStatusList(props?.stage_info?._id);
        }
      },
      null,
      action.button_name
    );
  }

  function TrackingStatusComponent(action, candidate) {
    setOpenedActionsMenu(false);
    let open = true;
    track_status_dialog(open, candidate, loggedInUser);
  }

  function RolesToBeConsideredForComponent(action, candidate) {
    let open = true;
    setOpenedActionsMenu(false);
    roles_to_be_considered_for_dialog(open, candidate, action, (res) => {
      if (action.success_message) {
        let status_details = {
          ...commonMethods.STATUS_POPUP_DETAILS.success,
          message: action.success_message,
        };
        status_dialog(status_details, () => {
          getCandidateHeader();
          refreshSideMenu();
        });
        // simple_dialog(action.success_message, () => {
        //     getCandidateHeader();
        // });
      } else {
        getCandidateHeader();
        refreshSideMenu();
      }
      // props.onChange();
      // fetchCandidatesData(props?.stage_info?._id, page, rowsPerPage, encodedSearch);
      // getStatusList(props?.stage_info?._id);
    });
  }

  function AddNotesComponent(action, candidate) {
    setOpenedActionsMenu(false);
    add_notes_dialog("true", action, candidate);
  }

  function RequestDocumentsFormComponent(action, candidate) {
    setOpenedActionsMenu(false);
    request_docs_dialog("true", action, candidate, (res) => {
      if (res) {
        getCandidateHeader();
        refreshSideMenu();
      }
    });
  }

  function CandidateJoinedDialogComponent(action, candidate) {
    setOpenedActionsMenu(false);
    if (candidate?._id) {
      API.GetMethod("candidateRegistration/" + candidate?._id).then(async (response) => {
        if (response && response.data) {
          let candaditeDetails = response.data;
          let missingFields = [];
          commonMethods.FIELDS_TO_CHECK_FOR_EMPLOYEEDB_SYNC.forEach(field => {
            if (!candaditeDetails.hasOwnProperty(field) && commonMethods.isNullOrUndefined(candaditeDetails[field])) {
              missingFields.push(field);
            }
          });
          if (missingFields.length > 0) {
            let status_details = {
              ...commonMethods.STATUS_POPUP_DETAILS.error,
              message: Messages.error_messages.mandatory_fields_missing,
            };
            status_dialog(status_details, () => {

            })
          }
          else {
            ConfirmationDialogComponent(action, candidate);
          }

        }
        else {
          let status_details = {
            ...commonMethods.STATUS_POPUP_DETAILS.error,
            message: Messages.error_messages.candidate_joined_failure,
          };
          status_dialog(status_details, () => {

          })
        }
      }).catch(error => {
        console.log(error);
      });
    }

  }

  function SyncDataToEmployeeDBFormComponent(action, candidate) {
    setOpenedActionsMenu(false);
    if (candidate?._id) {
      API.GetMethod("candidateRegistration/" + candidate?._id).then(async (response) => {
        if (response && response.data) {
          let candaditeDetails = response.data;
          let missingFields = [];
          commonMethods.FIELDS_TO_CHECK_FOR_EMPLOYEEDB_SYNC.forEach(field => {
            if (!candaditeDetails.hasOwnProperty(field) && commonMethods.isNullOrUndefined(candaditeDetails[field])) {
              missingFields.push(field);
            }
          });
          if (missingFields.length > 0) {
            let status_details = {
              ...commonMethods.STATUS_POPUP_DETAILS.error,
              message: Messages.error_messages.mandatory_fields_missing,
            };
            status_dialog(status_details, () => {

            })
          }
          else {
            candidate['work_email'] = candaditeDetails.work_email ? candaditeDetails.work_email : '';
            sync_data_to_employeedb_dialog("true", action, candidate, (res) => {
              if (res) {
                getCandidateHeader();
                refreshSideMenu();
              }
            });
          }

        }
        else {
          let status_details = {
            ...commonMethods.STATUS_POPUP_DETAILS.error,
            message: Messages.error_messages.sync_failure,
          };
          status_dialog(status_details, () => {

          })
        }
      }).catch(error => {
      });
    }
  }

  function OfferLetterFormComponent(action, candidate) {
    setOpenedActionsMenu(false);
    offer_letter_dialog("true", action, candidate, (res) => {
      if (res) {
        getCandidateHeader();
        refreshSideMenu();
      }
    });
  }

  function AllDocumentsDownloadComponent(action, candidate) {
    setOpenedActionsMenu(false);
    all_documents_download_dialog("true", action, candidate, (res) => {
      if (res) {
        // if (action.success_message) {
        //     let status_details = {
        //         ...commonMethods.STATUS_POPUP_DETAILS.success,
        //         message: action.success_message,
        //     };
        //     status_dialog(status_details, () => {
        //         //closeActionsMenu(index);
        //         //props.onChange();
        //         //fetchCandidatesData(props?.stage_info?._id, page, rowsPerPage, encodedSearch, null, (sortDetails ? sortDetails : sortDataDetails));
        //     });
        // } else {
        //     //closeActionsMenu(index);
        //     //props.onChange();
        //     //fetchCandidatesData(props?.stage_info?._id, page, rowsPerPage, encodedSearch, null, (sortDetails ? sortDetails : sortDataDetails));
        //     // getStatusList(props?.stage_info?._id);
        // }
      }
    });
  }

  function proposalComponent(action, candidate, index) {
    let open = true;
    setOpenedActionsMenu(false);
    proposal_dialog(open, candidate, action, () => {
      if (action.success_message) {
        let status_details = {
          ...commonMethods.STATUS_POPUP_DETAILS.success,
          message: action.success_message,
        };
        status_dialog(status_details, () => {
          getCandidateHeader();
          refreshSideMenu();
        });
        // simple_dialog(action.success_message, () => {
        //     getCandidateHeader();
        // });
      } else {
        getCandidateHeader();
        refreshSideMenu();
      }
    });
  }

  function getDisableForms(status_id) {
    if (!commonMethods.isNullOrUndefined(status_id)) {
      API.GetMethod(`status/${status_id}/disableForms`).then(
        (status_list) => {
          if (
            !commonMethods.isNullOrUndefined(status_list) &&
            !commonMethods.isNullOrUndefined(status_list.data) &&
            status_list["data"].length > 0
          ) {
            //setDisableForms(status_list['data'][0]['disable_form_info'])
          }
        },
        (error) => {}
      );
    }
  }

  return (
    <Card className="candDetails no-box-shadow">
      {!commonMethods.isNullOrUndefined(params?.id) ? (
        <div
          className={
            "d-flex justify-content-start align-items-center mer-padding-top-10 mer-margin-left-20 mer-margin-right-20 mer-padding-bottom-10 " +
            (deviceProperties.isMobileDevice || deviceProperties.isTabletDevice
              ? "flex-wrap"
              : "")
          }
        >
          {deviceProperties.isTabletDevice ||
          deviceProperties.isMobileDevice ? (
            <div className="d-flex flex-row mer-width-percentage-100 justify-content-between">
              <div>
                <div className="d-flex flex-row align-items-center col-md-12 col-lg-4 col-sm-12 col-12">
                  <div className="mer-font-bold">Name: &nbsp;</div>
                  <div>
                    {commonMethods.concateNames(
                      candidateDetails?.first_name,
                      candidateDetails?.middle_name,
                      candidateDetails?.last_name
                    )}
                  </div>
                </div>
                <div className="d-flex flex-row align-items-center col-md-12 col-lg-4 col-sm-12 col-12">
                  <div className="mer-font-bold">Status: &nbsp;</div>
                  <div>{candidateDetails?.status_name}</div>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <BiDetail
                  className="mer-height-20 mer-width-20 mer-cursor-pointer"
                  onClick={() => {
                    setOpenD(true);
                  }}
                ></BiDetail>
                <Dialog
                  open={opend}
                  onClose={() => {
                    setOpenD(false);
                  }}
                  className="header-dialog"
                >
                  <DialogTitle
                    className="d-flex justify-content-between align-items-center"
                    sx={{ borderBottom: "2px" }}
                  >
                    <div>Candidate information</div>
                    <MdClose
                      className="mer-width-20 mer-height-20 mer-cursor-pointer"
                      onClick={() => {
                        setOpenD(false);
                      }}
                    ></MdClose>
                  </DialogTitle>
                  <DialogContent>
                    <div className="d-flex flex-row">
                      <div className="mer-font-bold mer-width-percentage-25">
                        Name
                      </div>
                      <div className="mer-width-percentage-5">:</div>
                      <div className="mer-break-word mer-width-percentage-50">
                        {commonMethods.concateNames(
                          candidateDetails?.first_name,
                          candidateDetails?.middle_name,
                          candidateDetails?.last_name
                        )}
                        {candidateDetails?.first_name ? (
                          <RiFileCopyFill
                            className="mer-width-16 mer-height-16 mer-margin-left-5 mer-cursor-pointer mer-icon-color"
                            onClick={() => {
                              commonMethods.copyToClipboard(
                                commonMethods.concateNames(
                                  candidateDetails?.first_name,
                                  candidateDetails?.middle_name,
                                  candidateDetails?.last_name
                                )
                              );
                              setMessage("Candidate name is copied !");
                              setOpen(true);
                            }}
                          />
                        ) : null}
                      </div>
                    </div>
                    <div className="d-flex flex-row ">
                      <div className="mer-font-bold mer-width-percentage-25">
                        Mobile
                      </div>
                      <div className="mer-width-percentage-5">:</div>
                      <div className="mer-break-word mer-width-percentage-50">
                        {candidateDetails?.mobile_number}
                        {candidateDetails?.mobile_number ? (
                          <RiFileCopyFill
                            className="mer-width-16 mer-height-16 mer-margin-left-5 mer-cursor-pointer mer-icon-color"
                            onClick={() => {
                              commonMethods.copyToClipboard(
                                candidateDetails?.mobile_number
                              );
                              setMessage("Mobile number is copied !");
                              setOpen(true);
                            }}
                          />
                        ) : null}
                      </div>
                    </div>
                    <div className="d-flex flex-row ">
                      <div className="mer-font-bold mer-width-percentage-25">
                        Email
                      </div>
                      <div className="mer-width-percentage-5">:</div>
                      <div className="mer-break-word mer-width-percentage-50">
                        {candidateDetails?.email_id}
                        {candidateDetails?.email_id ? (
                          <RiFileCopyFill
                            className="mer-width-16 mer-height-16  mer-margin-left-5 mer-cursor-pointer mer-icon-color"
                            onClick={() => {
                              commonMethods.copyToClipboard(
                                candidateDetails?.email_id
                              );
                              setMessage("Email ID is copied !");
                              setOpen(true);
                            }}
                          />
                        ) : null}
                      </div>
                    </div>
                    <div className="d-flex flex-row">
                      <div className="mer-font-bold mer-width-percentage-25">
                        Domain
                      </div>
                      <div className="mer-width-percentage-5">:</div>
                      <div className="mer-break-word mer-width-percentage-50">
                        {candidateDetails?.domain_name}
                      </div>
                    </div>
                    <div className="d-flex flex-row">
                      <div className="mer-font-bold mer-width-percentage-25">
                        Roles
                      </div>
                      <div className="mer-width-percentage-5">:</div>
                      <div className="d-flex flex-row mer-width-percentage-50">
                        <div
                          className="mer-break-word"
                          title={commonMethods.arrayToString(
                            candidateDetails?.roles_to_be_considered,
                            "role_name"
                          )}
                        >
                          {commonMethods.arrayToString(
                            candidateDetails?.roles_to_be_considered,
                            "role_name"
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-row ">
                      <div className="mer-font-bold mer-width-percentage-25">
                        Status
                      </div>
                      <div className="mer-width-percentage-5">:</div>
                      <div className="mer-break-word mer-width-percentage-50">
                        {candidateDetails?.status_name}
                      </div>
                    </div>
                  </DialogContent>
                </Dialog>
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-start flex-grow-1 align-items-center flex-wrap">
              <div className="d-flex flex-row align-items-center mer-width-percentage-30 mer-margin-left-8">
                <div className="mer-font-bold mer-font-size-12 mer-width-percentage-25">
                  Name
                </div>
                <div className="mer-font-bold mer-font-size-12 mer-width-percentage-5">
                  :
                </div>
                <div
                  className="mer-text-ellipsis mer-font-size-12 mer-width-percentage-65"
                  title={commonMethods.concateNames(
                    candidateDetails?.first_name,
                    candidateDetails?.middle_name,
                    candidateDetails?.last_name
                  )}
                >
                  {commonMethods.concateNames(
                    candidateDetails?.first_name,
                    candidateDetails?.middle_name,
                    candidateDetails?.last_name
                  )}
                  {candidateDetails?.first_name ? (
                    <RiFileCopyFill
                      className="mer-width-14 mer-height-14 mer-margin-left-5 mer-cursor-pointer mer-icon-color"
                      onClick={() => {
                        commonMethods.copyToClipboard(
                          commonMethods.concateNames(
                            candidateDetails?.first_name,
                            candidateDetails?.middle_name,
                            candidateDetails?.last_name
                          )
                        );
                        setMessage("Candidate name is copied !");
                        setOpen(true);
                      }}
                    />
                  ) : null}
                </div>
              </div>
              <div className="d-flex flex-row align-items-center mer-width-percentage-30 mer-margin-left-8">
                <div className="mer-font-bold mer-font-size-12 mer-width-percentage-20">
                  Mobile
                </div>
                <div className="mer-font-bold mer-font-size-12 mer-width-percentage-5">
                  :
                </div>
                <div className="d-flex flex-row mer-font-size-12 align-items-center mer-width-percentage-65">
                  <div
                    className="mer-text-ellipsis mer-font-size-12"
                    title={candidateDetails?.mobile_number}
                  >
                    {candidateDetails?.mobile_number}
                  </div>
                  <div>
                    {" "}
                    {candidateDetails?.mobile_number ? (
                      <RiFileCopyFill
                        className="mer-width-14 mer-height-14 mer-margin-left-5 mer-cursor-pointer mer-icon-color"
                        onClick={() => {
                          commonMethods.copyToClipboard(
                            candidateDetails?.mobile_number
                          );
                          setMessage("Mobile number is copied.");
                          setOpen(true);
                        }}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="d-flex flex-row align-items-center mer-width-percentage-35 mer-margin-left-8">
                <div className="mer-font-bold mer-font-size-12 mer-width-percentage-20">
                  Email
                </div>
                <div className="mer-font-bold mer-font-size-12 mer-width-percentage-5">
                  :
                </div>
                <div className="d-flex flex-row mer-font-size-12 align-items-center mer-width-percentage-75">
                  <div
                    className="mer-text-ellipsis mer-font-size-12"
                    title={candidateDetails?.email_id}
                  >
                    {candidateDetails?.email_id}
                  </div>
                  <div>
                    {" "}
                    {candidateDetails?.email_id ? (
                      <RiFileCopyFill
                        className="mer-width-14 mer-height-14 mer-margin-left-5 mer-cursor-pointer mer-icon-color"
                        onClick={() => {
                          commonMethods.copyToClipboard(
                            candidateDetails?.email_id
                          );
                          setMessage("Email ID is copied.");
                          setOpen(true);
                        }}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="d-flex flex-row align-items-center mer-width-percentage-30 mer-margin-left-8">
                <div className="mer-font-bold mer-font-size-12 mer-width-percentage-25">
                  Domain
                </div>
                <div className="mer-font-bold mer-font-size-12 mer-width-percentage-5">
                  :
                </div>

                <div
                  className="mer-text-ellipsis mer-font-size-12 mer-width-percentage-65"
                  title={candidateDetails?.domain_name}
                >
                  {candidateDetails?.domain_name}
                </div>
              </div>
              <div className="d-flex flex-row align-items-center mer-width-percentage-30 mer-margin-left-8">
                <div className="mer-font-bold mer-font-size-12 mer-width-percentage-20">
                  Roles
                </div>
                <div className="mer-font-bold mer-font-size-12 mer-width-percentage-5">
                  :
                </div>

                <div className="d-flex flex-row mer-font-size-12 mer-width-percentage-65">
                  <div
                    className="mer-text-ellipsis mer-font-size-12"
                    title={
                      !commonMethods.isNullOrUndefined(
                        candidateDetails?.roles_to_be_considered
                      ) && candidateDetails?.roles_to_be_considered?.length > 0
                        ? candidateDetails?.roles_to_be_considered[0]?.role_name
                        : ""
                    }
                  >
                    {!commonMethods.isNullOrUndefined(
                      candidateDetails?.roles_to_be_considered
                    ) && candidateDetails?.roles_to_be_considered?.length > 0
                      ? candidateDetails?.roles_to_be_considered[0]?.role_name
                      : ""}
                  </div>
                  {!commonMethods.isNullOrUndefined(
                    candidateDetails?.roles_to_be_considered
                  ) && candidateDetails?.roles_to_be_considered?.length > 1 ? (
                    <Tooltip
                      placement="bottom"
                      title={commonMethods.arrayToString(
                        splicedArray,
                        "role_name"
                      )}
                    >
                      <div className="mer-margin-left-8 mer-margin-right-10 mer-cursor-pointer mer-text-underline">
                        +{candidateDetails?.roles_to_be_considered?.length - 1}
                      </div>
                    </Tooltip>
                  ) : null}
                </div>
              </div>
              <div className="d-flex flex-row align-items-center mer-width-percentage-35 mer-margin-left-8">
                <div className="mer-font-bold mer-font-size-12 mer-width-percentage-20">
                  Status
                </div>
                <div className="mer-font-bold mer-font-size-12 mer-width-percentage-5">
                  :
                </div>
                <div
                  className="mer-text-ellipsis mer-font-size-12 mer-width-percentage-75"
                  title={candidateDetails?.status_name}
                >
                  {candidateDetails?.status_name}
                </div>
              </div>
            </div>
          )}
          {/* {(loggedInUser?.role_info?.candidate_actions?.timeline ||
                        loggedInUser?.role_info?.candidate_actions?.reject ||
                        loggedInUser?.role_info?.candidate_actions?.forward) && */}
          <div
            className={
              deviceProperties.isTabletDevice || deviceProperties.isMobileDevice
                ? "d-flex flex-grow-1 flex-column"
                : "d-flex flex-row "
            }
          >
            {deviceProperties.isTabletDevice ||
            deviceProperties.isMobileDevice ? (
              <div className="mer-width-percentage-100">
                <Divider
                  orientation="horizontal"
                  className="mer-width-percentage-100"
                  flexItem={true}
                />
              </div>
            ) : null}
            <div
              className={
                deviceProperties.isTabletDevice ||
                deviceProperties.isMobileDevice
                  ? "d-flex flex-row col-md-12 col-lg-2 col-sm-12 col-12 justify-content-between"
                  : "d-flex flex-row mer-width-percentage-100 justify-content-between"
              }
            >
              {!(
                deviceProperties.isTabletDevice ||
                deviceProperties.isMobileDevice
              ) ? (
                <Divider
                  orientation="vertical"
                  className="mer-height-35"
                  flexItem={true}
                />
              ) : null}

              {loggedInUser?.role_info?.candidate_actions?.timeline && (
                <div
                  title="Timeline"
                  className="d-flex flex-column mer-cursor-pointer justify-content-center align-items-center mer-margin-left-8 mer-padding-right-8 mer-icon-color"
                  onClick={() => setTimeLine(true)}
                >
                  <MdOutlineShowChart />
                  {!(
                    deviceProperties.isTabletDevice ||
                    deviceProperties.isMobileDevice
                  ) ? (
                    <div className="mer-font-size-12 mer-icon-color">
                      Timeline
                    </div>
                  ) : null}
                </div>
              )}
              {loggedInUser?.role_info?.candidate_actions?.reject && (
                <div
                  title="Reject"
                  className="d-flex flex-column mer-cursor-pointer justify-content-center align-items-center mer-margin-left-8 mer-padding-right-8 mer-icon-color"
                  onClick={() => {
                    RejectCandidateComponent();
                  }}
                >
                  <MdContentPasteOff />
                  {!(
                    deviceProperties.isTabletDevice ||
                    deviceProperties.isMobileDevice
                  ) ? (
                    <div className="mer-font-size-12 mer-icon-color">
                      Reject
                    </div>
                  ) : null}
                </div>
              )}
              {loggedInUser?.role_info?.candidate_actions?.forward && (
                <div
                  title="Forward"
                  aria-controls={opened_actions_menu}
                  aria-haspopup="true"
                  className="d-flex flex-column mer-cursor-pointer justify-content-center align-items-center mer-margin-left-8 mer-padding-right-8 mer-icon-color"
                  onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    forward(candidateDetails);
                  }}
                >
                  <TbPlayerSkipForward />
                  {!(
                    deviceProperties.isTabletDevice ||
                    deviceProperties.isMobileDevice
                  ) ? (
                    <div className="mer-font-size-12 mer-icon-color">
                      Forward
                    </div>
                  ) : null}
                </div>
              )}
              <Menu
                keepMounted
                anchorEl={anchor_EL}
                getContentAnchorEl={null}
                open={Boolean(opened_actions_menu)}
                // getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                onClose={(e) => setOpenedActionsMenu(false)}
              >
                <Card className="mer-theme-headermenucard mer-forward-menu mer-margin-0 mer-padding-16">
                  {actionList?.length != 0 ? (
                    actionList?.map((action, index) => (
                      <Card
                        key={index}
                        onClick={(e) => performAction(action, candidateDetails)}
                        className="mer-action-card mer-cursor-pointer justify-content-between  d-flex flex-row"
                      >
                        <div>{action.action_name}</div>
                      </Card>
                    ))
                  ) : (
                    <div>No actions to display</div>
                  )}
                </Card>
              </Menu>
              {/* {loggedInUser?.role_info?.candidate_actions?.overview && 
                                <div title='Overview' className='d-flex flex-column mer-cursor-pointer justify-content-center align-items-center  mer-padding-right-8 mer-icon-color'
                                    onClick={() => { window.open(StaticRouterLinks?.one_pager_view + candidateDetails?._id, '_blank') }}>
                                    <BsBoxArrowUpRight />
                                    {!(deviceProperties.isTabletDevice || deviceProperties.isMobileDevice) ? <div className="mer-font-size-12 mer-icon-color">Overview</div> : null}
                                </div>} */}
              <div
                title="Resume"
                className="d-flex flex-column mer-cursor-pointer justify-content-center align-items-center mer-margin-left-8 mer-padding-right-8 mer-icon-color"
                onClick={() => {
                  if (
                    !commonMethods.isNullOrUndefined(
                      candidateDetails?.attachments?.resume
                    )
                  ) {
                    window.open(
                      process.env.REACT_APP_BASE_URL +
                        candidateDetails?.attachments?.resume?.download_path,
                      "_blank"
                    );
                  } else {
                    let status_details = {
                      ...commonMethods.STATUS_POPUP_DETAILS.error,
                      message: "Resume is not available",
                    };
                    status_dialog(status_details, () => {
                      return;
                    });
                  }
                }}
              >
                <FiDownload />
                {!(
                  deviceProperties.isTabletDevice ||
                  deviceProperties.isMobileDevice
                ) ? (
                  <div className="mer-font-size-12 mer-icon-color">Resume</div>
                ) : null}
              </div>
            </div>
          </div>
          {/* } */}
          <Snackbar
            anchorOrigin={{
              horizontal: "left",
              vertical: "bottom",
            }}
            open={open}
            autoHideDuration={5000}
            message={message}
            onClose={handleToClose}
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleToClose}
                >
                  <IoCloseCircleOutline
                    className="mer-width-20 text-white mer-height-20"
                    size="small"
                  />
                </IconButton>
              </React.Fragment>
            }
          />
          {/* <Dialog open={Boolean(open_timeline)}>
                        <Timeline></Timeline>
                    </Dialog> */}

          <Dialog
            className={
              "dialog-box " +
              (deviceProperties.isTabletDevice ||
                deviceProperties.isMobileDevice)
                ? " mer-timeline-dialog-mobile"
                : " mer-timeline-dialog"
            }
            TransitionComponent={Transition}
            // fullScreen={fullScreen}
            open={open_timeline}
            onClose={() => {
              setTimeLine(false);
            }}
            aria-labelledby="responsive-dialog-title"
          >
            <Card className="mer-theme-dialog-header-bg d-flex flex-row justify-content-between">
              <div className="mer-font-bold mer-padding-8">
                Timeline for profile -{" "}
                {/* <span className="mer-color2"> */}
                <b>
                  {commonMethods.concateNames(
                    candidateDetails?.first_name,
                    candidateDetails?.middle_name,
                    candidateDetails?.last_name
                  )}
                  </b>
                {/* </span> */}
              </div>
              <Tooltip title="Minimize">
                <DialogActions>
                  <VscChromeMinimize
                    className="mer-width-20 mer-height-20 mer-cursor-pointer"
                    onClick={() => {
                      setTimeLine(false);
                    }}
                  ></VscChromeMinimize>
                </DialogActions>
              </Tooltip>
            </Card>
            <Timeline
              candidate={candidateDetails}
              loggedInUser={loggedInUser}
            />
          </Dialog>
        </div>
      ) : (
        <></>
      )}
      <Dialog open={Boolean(ope_reject_dialog)}>
        <div>
          <DialogTitle className="mer-theme-dialog-header-bg-tables mer-padding-top-16 mer-padding-left-16">
            Reject candidate -{" "}
            <span>
              {commonMethods.concateNames(
                candidateDetails?.first_name,
                candidateDetails?.middle_name,
                candidateDetails?.last_name
              )}
            </span>
          </DialogTitle>
        </div>
        <CandidateRejectForm
          _id={candidateDetails?._id}
          type={1}
          action={rejectAction}
          onChange={(value) => {
            if (value === true) {
              setOpenRejectDialog(false);
              navigate("/");
            } else {
              setOpenRejectDialog(false);
            }
          }}
        />
      </Dialog>
    </Card>
  );
}

export default CandidateDetails;
