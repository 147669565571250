import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import * as API from "../utills/API";
import useDeviceProperties from "../utills/hooks/useDeviceProperties";
import IMAGES from "../utills/constants/Images";
import { Card } from "@mui/material";
import * as CommonMethods from "../utills/CommonMethods";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  TextField,
  FormLabel,
  InputAdornment,
  Box,
  CircularProgress,
} from "@mui/material";
import { TEXT_FIELD_CONFIG } from "../utills/constants/FormFieldFormats";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { OTPDialog, simple_dialog, status_dialog } from "../utills/Alerts";
import Otp from "../components/login/Otp";
import { otp_dialog } from "../utills/Alerts";

//import crypto from "crypto";

function Login() {
  const navigate = useNavigate();
  const [loginFailedErrorMessage, setLoginFailedErrorMessage] = useState(null);
  let deviceProperties = useDeviceProperties();
  const [loginLoader, setLoginLoader] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [isCodeInUrl, setIsCodeInUrl] = useState(false);
  const location = useLocation();

  const getTokenAndUserDetails = async (code) => {
    let response = await CommonMethods.getTokenAndDetails(code);
    if (!CommonMethods.isNullOrUndefined(response)) {
      await localStorage.setItem("restore_time", new Date());
      handleSubmit({
        token: response?.data,
      });
    }
  };
  const [showEye, updateEye] = useState(true);

  const loginForm = useFormik({
    initialValues: {
      user_name: "",
      password: "",
    },
    validationSchema: Yup.object({
      user_name: Yup.string()
        .required("Email address is required")
        .email("Invalid Email address")
        .test({
          name: "domain-check",
          exclusive: false,
          params: {},
          message: "Please use Sign in with Microsoft",
          test: function (value) {
            return value && !value.endsWith("@merilytics.com");
          },
        }),
      password: Yup.string()
        .required("Password is required")
        .min(8, "Must be 8 characters or more")
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "Require at least one uppercase, one lowercase, one number and one special character"
        ),
    }),
    onSubmit: (values) => {
      setLoginLoader(true);

      // Alogrithm:
      /**
       * Send the values and upon success open the OTP dialog
       * Enter the OTP and submit it.
       * If Success move inside the application
       * Else show invalid otp.
       * User can cancel the dialog if required.
       */

      API.PostMethod("login", loginForm.values).then(
        (res) => {
          setLoginLoader(false);
          if (res.message === "success" || res.message === "Success") {
            API.PostMethod(
              "otp/generate",
              {
                email: loginForm?.values?.user_name,
              },
              {
                trigger_at: "login",
                Authorization: `Bearer ${res.Authorization}`,
              }
            )
              .then((data) => {
                otp_dialog(
                  true,
                  "Enter the OTP sent to your email",
                  {
                    token: res.Authorization,
                    is_interviewer:
                      res["data"]["is_it_interviewer_role"] ?? false,
                    email: loginForm?.values?.user_name,
                  },
                  false
                );
              })
              .catch((err) => {
                setLoginFailedErrorMessage("Couln't generate OTP");
              });

            // localStorage.setItem("mer-auth-key", res.Authorization);
            // if (res["data"]["is_it_interviewer_role"]) {
            //   navigate("/recruitee/interviewer/candidates");
            // } else {
            //   navigate("/recruitee");
            // }
          } else {
            loginForm.setFieldError("password", res.message);
          }
        },
        (error) => {
          setLoginLoader(false);
          if (error["response"]["status"] === 403) {
            setLoginFailedErrorMessage(error["data"]["message"]);
          } else if (error["response"]["status"] === 401) {
            setLoginFailedErrorMessage(error["response"]["data"]["message"]);
          }
        }
      );
    },
  });

  const forgotPasswordForm = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email address is required")
        .email("Invalid Email address")
        .test({
          name: "domain-check",
          exclusive: false,
          params: {},
          message: "Please go back and use Sign in with Microsoft",
          test: function (value) {
            return value && !value.endsWith("@merilytics.com");
          },
        }),
    }),
    onSubmit: (values) => {
      setLoginLoader(true);
      API.PostMethod("forgot-password", values).then(
        (res) => {
          setLoginLoader(false);
          let status_details = {
            ...CommonMethods.STATUS_POPUP_DETAILS.success,
            message: res.message,
          };
          status_dialog(status_details, async () => {
            forgotPasswordForm.resetForm();
            setForgotPassword(false);
          });
        },
        (error) => {
          setLoginLoader(false);
          if (error["response"]["status"] === 403) {
            setLoginFailedErrorMessage(error["data"]["message"]);
          } else if (error["response"]["status"] === 401) {
            setLoginFailedErrorMessage(error["response"]["data"]["message"]);
          }
        }
      );
    },
  });

  function emailChange(event) {
    loginForm.setFieldValue("user_name", event.target.value);
    setLoginFailedErrorMessage(null);
  }
  function forgotEmailChange(event) {
    forgotPasswordForm.setFieldValue("email", event.target.value);
    setLoginFailedErrorMessage(null);
  }

  function passwordChange(event) {
    loginForm.setFieldValue("password", event.target.value);
    setLoginFailedErrorMessage(null);
  }

  const toggleEye = () => {
    updateEye(!showEye);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    if (window.location.pathname === "/referral/login") {
      if (
        window.localStorage.getItem("is_referal") &&
        !CommonMethods.isNullOrUndefined(
          window.localStorage.getItem("mer-auth-key")
        )
      ) {
        navigate("/refer");
      } else {
        window.localStorage.setItem("is_referal", true);
      }
    }
    if (code) {
      setIsCodeInUrl(true);
      getTokenAndUserDetails(code);
    }
  }, [location]);

  function handleSubmit(values) {
    setLoginLoader(true);
    let is_referal = localStorage.getItem("is_referal");
    if (is_referal == "true") {
      API.PostMethod("referalLogin", values).then(
        (res) => {
          setLoginLoader(false);
          if (res.message === "success" || res.message === "Success") {
            localStorage.setItem("mer-auth-key", res.Authorization);
            localStorage.setItem("is_rpuser", res.is_rpuser);
            navigate("/refer");
          } else {
            // loginForm.setFieldError("password", res.message);
          }
        },
        async (error) => {
          // await Logout();
          navigate("/referral/login");
          setLoginLoader(false);
          setIsCodeInUrl(false);
          if (error["response"]["status"] === 403) {
            setLoginFailedErrorMessage(error["data"]["message"]);
          } else if (error["response"]["status"] === 401) {
            setLoginFailedErrorMessage(error["response"]["data"]["message"]);
          }
        }
      );
    } else {
      API.PostMethod("login", values).then(
        (res) => {
          setLoginLoader(false);
          if (res.message === "success" || res.message === "Success") {
            localStorage.setItem("mer-auth-key", res.Authorization);
            if (res["data"]["is_it_interviewer_role"]) {
              navigate("/recruitee/interviewer/candidates");
            } else {
              navigate("/recruitee");
            }
          } else {
            // loginForm.setFieldError("password", res.message);
          }
        },
        async (error) => {
          // await Logout();
          navigate("/");
          setLoginLoader(false);
          setIsCodeInUrl(false);
          if (error["response"]["status"] === 403) {
            setLoginFailedErrorMessage(error["data"]["message"]);
          } else if (error["response"]["status"] === 401) {
            setLoginFailedErrorMessage(error["response"]["data"]["message"]);
          }
        }
      );
    }
  }

  return (
    <div
      className=" flex-column d-flex mer-fill justify-content-center align-items-center"
      style={{ backgroundImage: `url(${IMAGES.accordion_login_background})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}
    >
      {isCodeInUrl ? (
        <h1 className="mer-text-white" style={{ fontSize: "10rem !important" }}>Redirecting...</h1>
      ) : (
        // <div className="d-flex flex-row align-items-center flex-wrap">
        //   {!(
        //     deviceProperties.isMobileDevice || deviceProperties.isTabletDevice
        //   ) ? (
        //     <div className="d-flex justify-content-center mer-margin-bottom-8 col-12 col-sm-12 col-md-6 col-lg-7 col-xl-6">
        //       {IMAGES.online_meeting}
        //     </div>
        //   ) : null}
        <div className="d-flex flex-column justify-content-center col-sm-12 col-md-12 col-lg-4 col-xl-4 mer-padding-16">


          {/* {!forgotPassword ? ( */}
            <Card className="mer-theme-logincard mer-margin-0" elevation={5}>
              <div className="d-flex justify-content-center">
                <img
                  height={"20px"}
                  src={IMAGES.accordion_logo}
                  alt="Accordion"
                />
              </div>
              <div className="d-flex justify-content-center mer-margin-8">
                <div className="mer-margin-y-8">
                  <b>Recruitment Portal Login</b>
                </div>
              </div>
              {/* <Card className="d-flex flex-column teritary mer-padding-8 mer-rounded-8  mer-margin-0"> */}
                <div className="d-flex justify-content-center">
                  <div
                    // className="d-flex justify-content-center border border-secondary mer-rounded-8 mer-bg-white mer-padding-x-12"
                    role="button"
                    onClick={async () => {
                      if (window.location.pathname === "/login") {
                        window.localStorage.setItem("is_referal", false);
                        if (
                          !CommonMethods.isNullOrUndefined(
                            window.localStorage.getItem("mer-auth-key")
                          )
                        ) {
                          navigate("/recruitee");
                          return;
                        }
                      }

                      if (window.location.pathname === "/referral/login") {
                        window.localStorage.setItem("is_referal", true);
                        if (
                          !CommonMethods.isNullOrUndefined(
                            window.localStorage.getItem("mer-auth-key")
                          )
                        ) {
                          navigate("/refer");
                          return;
                        }
                      }

                      try {
                        let login_url = await CommonMethods.getLoginUrl();
                        window.location.replace(login_url);
                      } catch (err) {
                        console.log(err);
                      }
                    }}
                  >
                    {/* <img
                      className="mer-margin-6 mer-padding-left-2 "
                      src={IMAGES.microsoft_logo}
                      alt="Microsoft"
                    ></img>
                    <div className="mer-margin-6  mer-padding-right-2">
                      Sign in with Microsoft
                    </div> */}
                    <img
                      src={IMAGES.microsoft_login_image_light}
                      alt="Sign in with Microsoft"
                    ></img>
                  </div>
                </div>
                {/* <div className="mer-margin-top-8 text-center">
                  <b>Note:&nbsp;</b>This method currently support{" "}
                  <b>@merilytics.com</b> domain only
                </div>
              </Card> */}
               {/*<div className="d-flex justify-content-center mer-margin-top-12 mer-margin-8">
                <div className="mer-margin-left-8 mer-margin-right-8">
                  -- OR --
                </div>
              </div>

              <form onSubmit={loginForm.handleSubmit} className="mer-form">
                <Card className="d-flex flex-column teritary mer-padding-8 mer-rounded-8 mer-margin-0">
                  <div className="mer-margin-bottom-8">
                    <FormLabel>
                      <div
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                      >
                        Email<sup className="mer-color-red">*</sup>
                      </div>
                    </FormLabel>
                    <TextField
                      className="w-100 mer-bg-white mer-rounded-4"
                      {...TEXT_FIELD_CONFIG.OUTLINED}
                      id="outlined-size-small"
                      size="small"
                      name="user_name"
                      placeholder="Enter work email address"
                      value={loginForm.values.user_name}
                      onChange={emailChange}
                      error={
                        loginForm.touched.user_name &&
                          loginForm.errors.user_name
                          ? true
                          : false
                      }
                      onBlur={loginForm.handleBlur}
                    />
                    <div className="mer-error">
                      {loginForm.touched.user_name &&
                        loginForm.errors.user_name ? (
                        <span className="error">
                          {loginForm.errors.user_name}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="mer-margin-bottom-8">
                    <FormLabel>
                      <div
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                      >
                        Password<sup className="mer-color-red">*</sup>
                      </div>
                    </FormLabel>
                    <TextField
                      className="w-100 mer-bg-white mer-rounded-4"
                      {...TEXT_FIELD_CONFIG.OUTLINED}
                      type={showEye ? "password" : "text"}
                      id="outlined-size-small"
                      size="small"
                      name="password"
                      placeholder="Enter password"
                      value={loginForm.values.password}
                      onCopy={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      onChange={passwordChange}
                      onBlur={loginForm.handleBlur}
                      error={
                        loginForm.touched.password &&
                          loginForm.errors.password
                          ? true
                          : false
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {showEye ? (
                              <AiFillEye
                                className="mer-height-20 mer-width-20 mer-cursor-pointer iconclr"
                                onClick={toggleEye}
                              />
                            ) : (
                              <AiFillEyeInvisible
                                className="mer-height-20 mer-width-20 mer-cursor-pointer iconclr"
                                onClick={toggleEye}
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                    <div className="mer-error mer-max-width-395">
                      {loginForm.touched.password &&
                        loginForm.errors.password ? (
                        <span className="error">
                          {loginForm.errors.password}
                        </span>
                      ) : null}{" "}
                    </div>
                  </div>
                </Card>
                <div className="d-flex flex-column mer-margin-top-12">
                  {!CommonMethods.isNullOrUndefined(
                    loginFailedErrorMessage
                  ) ? (
                    <div className="mer-error">{loginFailedErrorMessage}</div>
                  ) : (
                    <div></div>
                  )}
                  <div className="d-flex  justify-content-between">
                    <Button
                      variant="text"
                      className="textcls"
                      color="primary"
                      type="button"
                      onClick={() => {
                        setForgotPassword(true);
                        forgotPasswordForm.resetForm();
                        setLoginFailedErrorMessage(null);
                      }}
                    >
                      Forgot Password?
                    </Button>
                    <Button
                      variant="contained"
                      disabled={
                        !CommonMethods.isNullOrUndefined(
                          loginFailedErrorMessage
                        )
                      }
                      color="primary"
                      type="submit"
                    >
                      {!loginLoader ? (
                        "Login"
                      ) : (
                        <Box className="d-flex">
                          <CircularProgress
                            size="1.3rem"
                          />
                        </Box>
                      )}
                    </Button>
                  </div>
                </div>
              </form> */}
            </Card>
          {/* ) : (
            <Card className="mer-theme-logincard mer-margin-0" elevation={5}>
              <div className="d-flex justify-content-center">
                <img
                  height={"20px"}
                  src={IMAGES.accordion_logo}
                  alt="Accordion"
                />
              </div>
              <div className="d-flex justify-content-center mer-margin-8">
                <div className="mer-margin-y-8">
                  <b>Recruitment Portal Login</b>
                </div>
              </div>
              <form
                onSubmit={forgotPasswordForm.handleSubmit}
                className="mer-form"
              >
                <Card className="d-flex flex-column teritary mer-padding-8 mer-rounded-8 mer-margin-0">
                  <div className="d-flex flex-column justify-content-center align-items-center mer-margin-8">
                    <div className="mer-padding-bottom-8">
                      <b>Reset Password</b>
                    </div>
                    <div className="mer-max-width-395 mer-padding-bottom-8 text-center">
                      Enter your work email address, and we will send you the
                      password through email
                    </div>
                  </div>
                  <div className="mer-margin-bottom-8">
                    <FormLabel>
                      <div
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                      >
                        Email<sup className="mer-color-red">*</sup>
                      </div>
                    </FormLabel>
                    <TextField
                      className="w-100 mer-bg-white mer-rounded-4"
                      {...TEXT_FIELD_CONFIG.OUTLINED}
                      id="outlined-size-small"
                      size="small"
                      name="email"
                      placeholder="Enter work email address"
                      value={forgotPasswordForm.values.email}
                      onChange={forgotEmailChange}
                      error={
                        forgotPasswordForm.touched.email &&
                          forgotPasswordForm.errors.email
                          ? true
                          : false
                      }
                      onBlur={forgotPasswordForm.handleBlur}
                    />
                    <div className="mer-error">
                      {forgotPasswordForm.touched.email &&
                        forgotPasswordForm.errors.email ? (
                        <span className="error">
                          {forgotPasswordForm.errors.email}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </Card>
                <div className="d-flex flex-column mer-margin-top-12">
                  {!CommonMethods.isNullOrUndefined(
                    loginFailedErrorMessage
                  ) ? (
                    <div className="mer-error">{loginFailedErrorMessage}</div>
                  ) : (
                    <div></div>
                  )}
                  <div className="d-flex  justify-content-between">
                    <Button
                      variant="text"
                      className="textcls"
                      color="primary"
                      type="button"
                      onClick={() => {
                        setForgotPassword(false);
                        loginForm.resetForm();
                      }}
                    >
                      Back to Login
                    </Button>
                    <Button
                      variant="contained"
                      disabled={
                        !CommonMethods.isNullOrUndefined(
                          loginFailedErrorMessage
                        )
                      }
                      color="primary"
                      type="submit"
                    >
                      {!loginLoader ? (
                        "Continue"
                      ) : (
                        <Box className="d-flex">
                          <CircularProgress
                            size="1.3rem"
                          />
                        </Box>
                      )}
                    </Button>
                  </div>
                </div>
              </form>
            </Card>
          )} */}
        </div>
        // </div>
      )}
    </div>
  );
}

export default Login;
