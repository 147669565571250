import { AppBar} from '@mui/material';
import IMAGES from '../../utills/constants/Images';
import React from 'react';
import useDeviceProperties from '../../utills/hooks/useDeviceProperties';

function Header() {

  let deviceProperties = useDeviceProperties();

  return (
    <div>
      <AppBar color="secondary" position="static" className='d-flex flex-row align-items-center justify-content-between mer-min-height-50'>
        <div className='mer-margin-8 mer-margin-left-16 '>
          <img height={'20px'} src={deviceProperties?.isMobileDevice || deviceProperties?.isTabletDevice ? IMAGES.accordion_icon : IMAGES.accordion_logo} alt="svg" />
        </div>
      </AppBar>
    </div>
  )

}

export default Header