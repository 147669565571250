import React, { useEffect, useState } from "react";
import { Button, Card, Typography, Tooltip } from "@mui/material";
import * as API from "../../../utills/API";
import { useParams } from "react-router-dom";
import * as CommonMethods from "../../../utills/CommonMethods";
import useFormData from "../../../utills/hooks/useFormData";
import * as Messages from "../../../utills/constants/Messages";
import { MdArrowBack } from "react-icons/md";
import { StaticRouterLinks } from "../../../utills/constants/StaticRouterLinks";
import { useNavigate } from "react-router-dom";
import Skeletonview from "../../../components/Skeletons/Skeletonview";
function PincodeData(props) {
  const params = useParams();
  const [pincodesData, setPincodesData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const formData = useFormData();
  const navigate = useNavigate();
  const fetchPincodesData = () => {
    setDataLoaded(false);
    API.GetMethod("pincodes/" + params?.pincode_id).then((res) => {
      
      setPincodesData(res.data[0]);
      setDataLoaded(true);
    });
  };

  useEffect(() => {
    fetchPincodesData();
  }, []);

  return (
    <div className="d-flex flex-column flex-grow-1 mer-overflow mer-overflow-x-hidden mer-margin-top-16">
      <Card className="d-flex flex-column teritary">
        <div className="d-flex flex-row align-items-center justify-content-between mer-margin-left-16 mer-margin-top-16 mer-margin-right-16">
          <div className="d-flex flex-row align-items-center">
            <MdArrowBack
              className="mer-width-20 mer-height-20 mer-cursor-pointer  mer-margin-right-10 mer-icon-color"
              onClick={() => navigate(StaticRouterLinks.pincodes_table)}
            />
            <Typography variant="h6" className="mer-icon-color">Pin code</Typography>
          </div>
          <Tooltip title={formData?.can_edit ? "" : Messages?.NO_EDIT}>
            <div>
              <Button
                // disabled={formData?.can_edit ? false : true}
                variant="contained"
                onClick={() => {
                  props.onChange();
                }}
              >
                Edit
              </Button>{" "}
            </div>
          </Tooltip>
        </div>
        {dataLoaded ? (
          <div className="d-flex flex-row flex-wrap mer-margin-left-16 mer-margin-top-16 mer-margin-right-16 mer-margin-bottom-16">
            <div className="d-flex flex-column col-12 col-sm-12 mer-mobile-width-percentage-100 col-md-6 col-lg-6 col-xl-6">
              <Card className="mer-margin-right-8 mer-margin-bottom-8 flex-grow-1 secondary">
                <div className="mer-font-bold  mer-margin-bottom-8">
                  Pincode Details
                </div>
                <div className="d-flex flex-row">
                  <div className="mer-width-percentage-45">
                   Pin code
                  </div>
                  <div className="mer-width-percentage-5">:</div>
                  <div className="d-flex mer-width-percentage-50 align-items-center"
                    style={{ wordWrap: "anywhere" }}
                    >
                    {pincodesData.pincode}
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div className="mer-width-percentage-45">
                    City
                  </div>
                  <div className="mer-width-percentage-5">:</div>
                  <div className="d-flex mer-width-percentage-50 align-items-center"
                    style={{ wordWrap: "anywhere" }}
                    >
                    {/* {pincodesData.city_name} */}
                    {pincodesData.division_name}
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div className="mer-width-percentage-45">
                    District
                  </div>
                  <div className="mer-width-percentage-5">:</div>
                  <div className="d-flex mer-width-percentage-50 align-items-center"
                    style={{ wordWrap: "anywhere" }}
                    >
                    {pincodesData.district_name}
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div className="mer-width-percentage-45"
                    style={{ wordWrap: "anywhere" }}
                    >
                    State
                  </div>
                  <div className="mer-width-percentage-5">:</div>
                  <div className="d-flex mer-width-percentage-50 align-items-center">
                    {pincodesData.state_name}
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div className="mer-width-percentage-45"
                    style={{ wordWrap: "anywhere" }}
                    >
                    Country
                  </div>
                  <div className="mer-width-percentage-5">:</div>
                  <div className="d-flex mer-width-percentage-50 align-items-center">
                    {pincodesData.country_name}
                  </div>
                </div>
              
              </Card>
            </div>
          </div>
        ) : (
          <Skeletonview />
        )}
      </Card>
    </div>
  );
}

export default PincodeData;
