import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import {
    confirmation_dialog,
    status_dialog,
} from "../../utills/Alerts";
import * as Messages from "../../utills/constants/Messages";
import * as API from "../../utills/API";
import * as CommonMethods from "../../utills/CommonMethods";
import * as Yup from "yup";
import { IoMdCloseCircleOutline } from "react-icons/io";
import {
    TextField,
    FormControl,
    Button,
    FormLabel,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Card,
    Tooltip,
    Box,
    CircularProgress,
} from "@mui/material";
import {
    TEXT_FIELD_CONFIG,
} from "../../utills/constants/FormFieldFormats";
import create from "zustand";
import Loading from "../../components/Loading";

export const sync_data_to_employeedb_dialog = async (open, action, candidate, callback) => {

    SyncDatatoEmployeeDBDialogStore.setState({ open, action, candidate, callback });

};

const SyncDatatoEmployeeDBDialogStore = create((set) => ({
    open: "",
    action: "",
    candidate: "",
    close: () =>
        set({
            open: false,
        }),
    callback: undefined,
}));

function SyncDatatoEmployeeDBForm(props) {
    const { open, close, candidate, action, callback, templateData } =
        SyncDatatoEmployeeDBDialogStore();

    const [dataLoaded, setDataLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    
    const extractLastWord=(input)=> {
        // Split the input string by spaces and get the last element
         const words = input.split(' ');
         return words[0];
     }
    
    const getCandidateEmailId = ()=> {
        if(CommonMethods.isNullOrUndefined(candidate.first_name) || CommonMethods.isNullOrUndefined(candidate.last_name)){
           return "";
        }
        let extractedFirstName = extractLastWord(candidate.first_name);
        extractedFirstName =extractedFirstName.toLowerCase();
        const lastName = (candidate.last_name).toLowerCase();
        return `${extractedFirstName}_${lastName}@accordion.com`;
    }

    const sync_data_to_employeedb_form = useFormik({
        initialValues: {
            work_email: getCandidateEmailId()
        },
        validationSchema: Yup.object().shape({
            work_email: Yup.string().nullable()
                .email("Invalid Email ID.")
                .matches(CommonMethods.reg_mail, CommonMethods.err_msg.mail)
                .max(64, CommonMethods.err_msg.mail_char)
                .required(Messages?.REQUIRED_FIELD_ERROR),
        }),
        onSubmit: (values) => {
            let message = `${action.confirmation_message}`;
            console.log("values", values);
            setLoading(true);
            API.GetMethod(`getEmployees/check-email-exists/${values.work_email}`).then(resp => {
                if (resp && !resp.is_employee_email_exists) {
                    confirmation_dialog(message, () => {
                        API.PostMethod(`${action?.confirmation_api}`, {
                            action: action,
                            candidate: candidate,
                            work_email: values.work_email
                        }).then(
                            (result) => {
                                if (action.success_message) {
                                    setLoading(false);
                                    let status_details = {
                                        ...CommonMethods.STATUS_POPUP_DETAILS.success,
                                        message: action.success_message,
                                    };
                                    status_dialog(status_details, () => {
                                        callback(true);
                                        return;
                                    });

                                }
                                sync_data_to_employeedb_form.resetForm();
                                close();
                            },
                            (error) => { }
                        );
                    }, null, null, () => {
                        setLoading(false);
                    });
                }
                else {
                    let status_details = {
                        ...CommonMethods.STATUS_POPUP_DETAILS.error,
                        message: Messages.error_messages.work_email_already_exist,
                    };
                    status_dialog(status_details, () => {
                        setLoading(false);
                    })
                }
            })

        },
    });


    useEffect(() => {
        setDataLoaded(false);
        if (!CommonMethods.isNullOrUndefined(action) && !CommonMethods.isNullOrUndefined(candidate)) {
            setDataLoaded(true);
            sync_data_to_employeedb_form.setFieldValue("work_email",candidate["work_email"] || getCandidateEmailId());
        }
    }, [candidate]);

    return (
        <Dialog open={Boolean(open)} className='sync-data-to-employeedb-dialog'
            onClose={() => { sync_data_to_employeedb_form.resetForm(); close() }} disableEscapeKeyDown={true}>
            {dataLoaded ?
                <form className="d-grid flex-grow-1 mer-overflow mer-form" onSubmit={sync_data_to_employeedb_form.handleSubmit}>
                    <div >
                        <DialogTitle className="mer-theme-dialog-header-bg-tables mer-padding-8">
                            <div className=" d-flex flex-row align-items-center justify-content-between">
                                <div className="mer-font-weight-150 mer-font-size-16">
                                    {action.action_name} for {CommonMethods.concateNames(candidate?.first_name, candidate?.middle_name, candidate?.last_name)}
                                </div>
                                <DialogActions>
                                    <IoMdCloseCircleOutline
                                        onClick={() => {
                                            if (!loading) {
                                                sync_data_to_employeedb_form.resetForm();
                                                close();
                                            }
                                        }}
                                        className={"mer-min-height-24 mer-min-width-24 " + (loading ? '' : 'mer-cursor-pointer')}
                                    />
                                </DialogActions>
                            </div>
                        </DialogTitle>
                    </div>
                    <DialogContent>
                        <div className="mer-margin-0 flex-grow-1 d-flex">
                            <Card className="d-flex flex-column teritary mer-margin-0  flex-grow-1 mer-padding-12">
                                <div className="d-flex flex-row flex-wrap justify-content-start align-items-start align-content-start">

                                    <div className="d-flex flex-column mer-margin-right-12 mer-margin-bottom-12 col-12">
                                        <FormLabel id="">Work Email</FormLabel>
                                        <FormControl>
                                            <TextField
                                                id={`work_email`}
                                                name={`work_email`}
                                                placeholder="Work Email"
                                                className="mer-field-bg-white"
                                                {...TEXT_FIELD_CONFIG.OUTLINED}
                                                onChange={sync_data_to_employeedb_form.handleChange}
                                                value={sync_data_to_employeedb_form.values?.work_email}
                                                error={
                                                    sync_data_to_employeedb_form.touched?.work_email &&
                                                        sync_data_to_employeedb_form.errors?.work_email
                                                        ? true
                                                        : false
                                                }
                                                onBlur={sync_data_to_employeedb_form.handleBlur}
                                            />
                                        </FormControl>
                                        <div className="mer-error">
                                            {sync_data_to_employeedb_form.touched?.work_email &&
                                                sync_data_to_employeedb_form?.errors?.work_email ? (
                                                <span className="error">
                                                    {sync_data_to_employeedb_form?.errors?.work_email}
                                                </span>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <div className='d-flex justify-content-end align-self-end'>
                            <Button disabled={loading} variant="outlined"
                                className='mer-margin-right-12 outlinedcls'
                                onClick={() => { sync_data_to_employeedb_form.resetForm(); close(); }} >
                                Cancel
                            </Button>
                            <Tooltip title={sync_data_to_employeedb_form.isValid ? '' : 'Please provide valid details'} >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    className="text-capitalize"
                                >
                                    {!loading ? (
                                        action?.button_name
                                    ) : (
                                        <Box className="d-flex">
                                            <CircularProgress size="1.3rem"/>
                                        </Box>
                                    )}
                                </Button>
                            </Tooltip>
                        </div>
                    </DialogActions>
                </form> :
                <div className="d-flex flex-column flex-grow-1">
                    <div >
                        <DialogTitle className="mer-theme-dialog-header-bg-tables mer-padding-8">
                            <div className=" d-flex flex-row align-items-center justify-content-between">
                                <div className="mer-font-weight-150 mer-font-size-20">
                                    {action.action_name} ?
                                </div>
                                <DialogActions>
                                    <IoMdCloseCircleOutline
                                        onClick={() => {
                                            sync_data_to_employeedb_form.resetForm();
                                            close();
                                        }}
                                        className="mer-min-height-24 mer-min-width-24 mer-cursor-pointer"
                                    />
                                </DialogActions>
                            </div>
                        </DialogTitle>
                    </div>
                    <div className="d-flex justify-contet-center flex-grow-1 align-items-center"><Loading /></div>
                </div>
            }

        </Dialog>
    );
}

export default SyncDatatoEmployeeDBForm;
