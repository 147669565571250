import React, { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as API from "../../utills/API";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { SHOW_OPTIONS_ONLY_HAS_ACCESS, customShowornot } from '../../utills/CommonMethods'
import {
    Button,
    TextField,
    FormLabel,
    Card,
    Typography,
    FormControl,
    Autocomplete,
    Dialog,
    Tooltip,
    Box,
    CircularProgress,
    Select,
    MenuItem,
    DialogActions,
    TextareaAutosize
} from "@mui/material";
import {
    AUTO_COMPLETE_CONFIG,
    TEXT_FIELD_CONFIG,
    SELECT_FIELD_CONFIG
} from "../../utills/constants/FormFieldFormats";
import * as CommonMethods from "../../utills/CommonMethods";
import { confirmation_dialog } from "../../utills/Alerts";
import * as Messages from "../../utills/constants/Messages";
import { MdCloudUpload } from "react-icons/md";
import { useDropzone } from "react-dropzone";
import useDeviceProperties from "../../utills/hooks/useDeviceProperties";
import { useTheme } from "@emotion/react";
import IMAGES from "../../utills/constants/Images";
import CandidateDuplicateApplicationDetails from "../candidates/CandidateDuplicateApplicationDetails";
import SubmittedDialog from "../candidate-facing/components/SubmittedDialog";
import useFormData from '../../utills/hooks/useFormData';

function CandidateReferralForm(props) {
    let deviceProperties = useDeviceProperties();
    const formData = useFormData();

    let constantFormFields = {
        first_name: "",
        middle_name: "",
        last_name: "",
        email_id: "",
        mobile_number: "",
        alt_mobile_number: "",
        domain_id: '',
        designation_id: "",
        sub_department_id: "",
        department_id: null,
        sub_department_id: null,
        designation_id: null,
        domain_name: null,
        department_name: null,
        sub_department_name: null,
        designation_name: null,
        resume: null,
        type_of_relation: null,
        candidate_fitness_id: ''
    }
    const registrationDetailsForm = useFormik({
        initialValues: constantFormFields,
        validationSchema: Yup.object().shape({
            first_name: Yup.string().nullable()
                .matches(CommonMethods.reg_name_with_space, CommonMethods.err_msg.name)
                .required(Messages?.REQUIRED_FIELD_ERROR),
            middle_name: Yup.string().nullable()
                .matches(CommonMethods.reg_name_without_space, CommonMethods.err_msg.name),
            last_name: Yup.string().nullable()
                .matches(CommonMethods.reg_name_without_space, CommonMethods.err_msg.name)
                .required(Messages?.REQUIRED_FIELD_ERROR),
            email_id: Yup.string()
                .nullable()
                .email("Invalid Email ID.")
                .matches(CommonMethods.reg_mail, CommonMethods.err_msg.mail)
                .max(64, CommonMethods.err_msg.mail_char)
                .required(Messages?.REQUIRED_FIELD_ERROR),
            mobile_number: Yup.string()
                .nullable()
                .matches(CommonMethods.reg_mob, CommonMethods.err_msg.mob)
                // .max(30)
                // .min(10)
                // .test('len', 'Mobile number is min 10 numbers and max 30 numbers', val => (val.length >=10 && val.length <= 30))
                .min(10, CommonMethods.err_msg.min_ten)
                .max(10, CommonMethods.err_msg.max_ten)
                .required(Messages?.REQUIRED_FIELD_ERROR),
            alt_mobile_number: Yup.string()
                .nullable()
                .matches(CommonMethods.reg_mob, CommonMethods.err_msg.mob)
                // .max(30)
                // .min(10)
                // .test('len', 'Mobile number is min 10 numbers and max 30 numbers', val => (val.length >=10 && val.length <= 30))
                .min(10, CommonMethods.err_msg.min_ten)
                .max(10, CommonMethods.err_msg.max_ten)
                // .required(Messages?.REQUIRED_FIELD_ERROR)
                .test({
                    name: "match",
                    exclusive: false,
                    params: {},
                    message: "Give a different number",
                    test: function (value) {
                        if (!CommonMethods.isNullOrUndefined(value)) {
                            return this.parent?.mobile_number != value;
                        }
                        else {
                            return true
                        }
                    },
                }),
            domain_id: Yup.string().nullable()
                .required(Messages?.REQUIRED_FIELD_ERROR),
            department_id: Yup.string().nullable()
                .required(Messages?.REQUIRED_FIELD_ERROR),
            sub_department_id: Yup.string().nullable()
                .required(Messages?.REQUIRED_FIELD_ERROR),
            designation_id: Yup.string().nullable()
                .required(Messages?.REQUIRED_FIELD_ERROR),
            domain_name: Yup.string().nullable(),
            department_name: Yup.string().nullable(),
            sub_department_name: Yup.string().nullable(),
            designation_name: Yup.string().nullable(),
            resume: Yup.mixed().nullable()
                .required(Messages?.REQUIRED_FIELD_ERROR),
            type_of_relation: Yup.string().nullable().required(Messages?.REQUIRED_FIELD_ERROR)
                .test('white_space_check', 'input should not contain only white spaces', function (value) {
                    if (!CommonMethods.isNullOrUndefined(value) && value.length > 0 && value.replace(/\s/g, '').length == 0) {
                        return false;
                    }
                    else {
                        return true;
                    }
                }),
            candidate_fitness_id: Yup.string().nullable().required(Messages?.REQUIRED_FIELD_ERROR)

        }),
        onSubmit: (values) => {
            let event_info = CommonMethods.getEventInfo(formData?.form_id, formData?.base_path, 'Referral-Create');
            let payLoad = { ...values };
            let resume_payload = new FormData();
            resume_payload.append('resume', payLoad.resume);

            delete payLoad.resume;
            setLoading(true);
            API.PostMethod('referral-registration/validation-check', payLoad).then((res) => {
                confirmation_dialog(Messages?.confirmation_messages?.referral_submit,
                    () => {
                        CreateProfile(payLoad, resume_payload, event_info, false);
                    }, null, null, () => {
                        setLoading(false);
                    });
            }, error => {
                setLoading(false);
            })

        },
    });
    function CreateProfile(payLoad, resume_payload, event_info, allow_duplicate) {

        // payLoad['allow_duplicate'] = allow_duplicate;

        API.PostMethod('referral-registration', payLoad, event_info).then((res) => {

            // if (!CommonMethods.isNullOrUndefined(res['data'].duplicate) && res['data'].duplicate) {
            //     let object = {
            //         payLoad: payLoad,
            //         resume_payload: resume_payload,
            //         event_info: event_info
            //     }
            //     setPayLoads(object);
            //     setDuplicatesTitle(res?.message);
            //     setPreviousApplicationDetails(res?.data?.duplicates)
            //     setOpenPreviousDialog(true);
            // }
            // else {
            resume_payload.append('candidate_id', res?.data?._id);

            if (fileChanged) {
                API.PostMethod("referral-resume-upload", resume_payload, event_info).then((response) => {
                    setOpenSubmitted(true);
                    setLoading(false);
                });
            }
            else {
                setOpenSubmitted(true);
                setLoading(false);
            }
            // }
        }, error => {
            setLoading(false);
        })
    }

    const [isValidFileType, setValidFileType] = useState(true);
    const [fileLink, setFileLink] = useState(null);
    const [fileChanged, setFileChanged] = useState(false);
    const [selectedDesignation, setSelectedDesignation] = useState(null);
    const [designationsList, setDesignationsList] = useState([]);
    const [designationCompleteList, setDesignationCompleteList] = useState([]);
    // const [title_for_duplicate_dialog, setDuplicatesTitle] = useState(null);
    // const [open_previous_profile, setOpenPreviousDialog] = useState(false);
    // const [previous_application_details, setPreviousApplicationDetails] = useState(false);
    // const [payLoad, setPayLoads] = useState(null);
    const [loading, setLoading] = useState(false);
    const [openSubmitted, setOpenSubmitted] = useState(false);
    const [departmentsList, setDepartmentsList] = useState([]);
    const [departmentCompleteList, setDepartmentCompleteList] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [domainsList, setDomainsList] = useState([]);
    // const [domainCompleteList, setDomainCompleteList] = useState([])
    const [selectedSubDepartment, setSelectedSubDepartment] = useState(null);
    const [subDepartmentsList, setSubDepartmentsList] = useState([]);
    const [subDepartmentCompleteList, setSubDepartmentCompleteList] = useState([]);
    const [fitnessList, setFitnessList] = useState([]);

    const params = useParams();
    let navigate = useNavigate();
    let theme = useTheme();

    useEffect(() => {
        // fetchDomains();
        fetchOpenDesignations();
        fetchFitnessList();
    }, [])

    function fetchFitnessList() {
        API.GetMethod('referral-candidate-fitness').then((res) => {
            if (!CommonMethods.isNullOrUndefined(res.data)) {
                setFitnessList(res.data);
            }
        });
    }

    function fetchOpenDesignations() {
        API.GetMethod('designation/open').then((resp) => {
            if (!CommonMethods.isNullOrUndefined(resp.data) && resp.data.length > 0) {
                // let designations = [];
                /*let subdepartment_pairs=new Map();
                let department_pairs=new Map();
                let domain_pairs=new Map();*/
                // let domains = new Set();
                // let departments = new Set();
                // let subDepartments = new Set();
                // resp.data?.map((desg) => {
                //     designations.push(desg);
                // })

                /*for(let obj of designations){
                    let res_sub=subdepartment_pairs.get(obj['sub_department_id'])
                    if(res_sub===undefined){
                        subdepartment_pairs.set(obj['sub_department_id'],{name:obj['sub_department_name'],designations:[{id:obj['_id'],name:obj['role_name']}]})
                    }
                    else{
                        subdepartment_pairs.set(obj['sub_department_id'],{name:res_sub['name'],designations:mergeRoleArray(res_sub['designations'],{id:obj['_id'],name:obj['role_name']})})
                    }
                    let res_dep=department_pairs.get(obj['department_id'])
                    if(res_dep===undefined){
                        department_pairs.set(obj['department_id'],{name:obj['department_name'],subDepartments:[{id:obj['sub_department_id'],name:obj['sub_department_name']}]})
                    }
                    else{
                        department_pairs.set(obj['department_id'],{name:obj['department_name'],subDepartments:mergeRoleArray(res_dep['subDepartments'],{id:obj['sub_department_id'],name:obj['sub_department_name']})})
                    }
                    let res_dom=domain_pairs.get(obj['domain_id'])
                    if(res_dom===undefined){
                        domain_pairs.set(obj['domain_id'],{name:obj['domain_name'],departments:[{id:obj['department_id'],name:obj['department_name']}]})
                    }
                    else{
                        domain_pairs.set(obj['domain_id'],{name:obj['domain_name'],departments:mergeRoleArray(res_dom['departments'],{id:obj['department_id'],name:obj['department_name']})})
                    }
                    
                    if(domains===undefined){
                        domains=[{id:obj['domain_id'],name:obj['domain_name']}]
                    }
                    else{
                        domains=mergeRoleArray(domains,{id:obj['domain_id'],name:obj['domain_name']})
                    }
                }
                setDomainPairsList(domain_pairs)
                setDepartmentPairsList(department_pairs)
                setSubDepartmentPairsList(subdepartment_pairs)
                console.log('attempt',customShowornot(domains, 'hasAccess', 'desc', SHOW_OPTIONS_ONLY_HAS_ACCESS.referralprofile))
                setDomainsList(domains)*/
                // setDesignationCompleteList(designations)
                // for (let obj of designations) {
                //     domains.add(obj['domain_id'])
                //     departments.add(obj['department_id'])
                //     subDepartments.add(obj['sub_department_id']);
                // }
                // domains = Array.from(domains);
                // departments = Array.from(departments)
                // subDepartments = Array.from(subDepartments)
                // let domainObjects = []
                // for (let id of domains) {
                //     API.GetMethod('domain/' + id).then((result) => {
                //         domainObjects.push(result.data)
                //     })
                // }
                // setDomainsList(domainObjects);
                // let departmentObjects = []
                // for (let id of departments) {
                //     API.GetMethod('department/' + id).then((result) => {
                //         departmentObjects.push(result.data)
                //     })
                // }
                // setDepartmentCompleteList(departmentObjects)
                // let subDepartmentObjects = []
                // for (let id of subDepartments) {
                //     API.GetMethod('subDepartment/' + id).then((result) => {
                //         subDepartmentObjects.push(result.data)
                //     })
                // }
                // setSubDepartmentCompleteList(subDepartmentObjects)

                setDesignationCompleteList(resp.data);
                let domains = [];
                let departments = [];
                let sub_departments = [];

                for (let desg of resp.data) {
                    let domain = {
                        domain_name: desg.domain_name,
                        _id: desg.domain_id
                    }
                    let department = {
                        domain_id: domain,
                        _id: desg.department_id,
                        department_name: desg.department_name
                    }
                    let sub_department = {
                        department_id: department,
                        domain_id: domain,
                        _id: desg.sub_department_id,
                        sub_department_name: desg.sub_department_name

                    }
                    let domain_index = domains.findIndex(element => element?._id === domain._id);
                    if (domain_index == -1) {
                        domains.push(domain);
                    }
                    let dept_index = departments.findIndex(element => element?._id === department._id);
                    if(dept_index == -1){
                        departments.push(department);
                    }
                    let sub_dept_index = sub_departments.findIndex(element => element?._id === sub_department._id);
                    if(sub_dept_index == -1){
                        sub_departments.push(sub_department);
                    }
                }
                domains = CommonMethods.customSort(domains,'domain_name','asc');
                departments =  CommonMethods.customSort(departments,'department_name','asc');
                sub_departments = CommonMethods.customSort(sub_departments,'sub_department_name','asc');
                setDomainsList(domains);
                setDepartmentCompleteList(departments);
                setSubDepartmentCompleteList(sub_departments);
            }
        }, (error) => {
            setDesignationsList([]);
        })
    }

    const onDrop = useCallback((acceptedFiles) => {
        if (!CommonMethods.isNullOrUndefined(acceptedFiles) && acceptedFiles.length > 0) {
            acceptedFiles.map((file) => {
                const reader = new FileReader();
                reader.onload = function (e) {
                    setFileLink(e.target.result);
                };
                setValidFileType(true);
                registrationDetailsForm.setFieldValue('resume', file);
                setFileChanged(true);
                reader.readAsDataURL(file);
                return file;
            });
        }
        else {
            setFileLink(null);
            setValidFileType(false);
            setFileChanged(false);
            registrationDetailsForm.setFieldValue('resume', null);
        }
    }, []);

    const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
        accept: {
            document: ['.doc', '.docx', '.pdf', '.DOC', '.DOCX', '.PDF']
        },
        onDrop,
        maxFiles: 1,
        maxSize: CommonMethods.max_file_size
    });

    function handleDepartmentsList(domain) {
        registrationDetailsForm.setFieldValue('domain_name', domain?.domain_name);
        fertchDepartmentsList(domain?._id);
        setSelectedDepartment(null);
        registrationDetailsForm.setFieldValue('department_id', null);
        registrationDetailsForm.setFieldValue('sub_department_id', null);
        registrationDetailsForm.setFieldValue('designation_id', null);
    }

    function handleSubDepartmentsList(department_id) {
        fertchSubDepartmentsList(department_id);
        setSelectedSubDepartment(null);
        registrationDetailsForm.setFieldValue('sub_department_id', null);
        registrationDetailsForm.setFieldValue('designation_id', null);
    }

    function handleRolesList(sub_department_id) {
        fetchDesignationsList(sub_department_id);
        setSelectedDesignation(null);
        registrationDetailsForm.setFieldValue('designation_id', null);
    }

    function fetchDesignationsList(sub_department_id) {
        let list = []
        for (let x of designationCompleteList) {
            if (x['sub_department_id'] === sub_department_id) {
                list.push(x);
            }
        }
        setDesignationsList(list);
    }

    function fertchSubDepartmentsList(department_id) {
        let list = []
        for (let x of subDepartmentCompleteList) {
            if (x['department_id']['_id'] === department_id) {
                list.push(x)
            }
        }
        setSubDepartmentsList(list)
    }

    function fertchDepartmentsList(domain_id) {
        let list = []
        for (let x of departmentCompleteList) {
            if (x['domain_id']['_id'].localeCompare(domain_id) === 0) {
                list.push(x);
            }
        }
        setDepartmentsList(list);
    }

    return (

        <div className="d-flex flex-grow-1 justify-content-center mer-width-percentage-100 mer-overflow-x-hidden mer-padding-top-12 mer-overflow" style={{ backgroundImage: `url(${theme.palette.mode === "light" ? IMAGES.page_background_light : IMAGES.page_background_dark})` }}>
            <div className={(deviceProperties.isMobileDevice || deviceProperties.isTabletDevice) ? 'd-flex flex-column flex-grow-1' : 'd-flex flex-column mer-width-percentage-80'}>
                <div className='flex-grow-1 d-flex flex-column mer-margin-top-0'>
                    <form className="d-grid flex-grow-1 mer-form" onSubmit={registrationDetailsForm.handleSubmit}>
                        <Card className="d-flex flex-column flex-grow-1 ">
                            <div className="d-flex flex-column mer-margin-top-16 mer-margin-left-16 mer-margin-right-16">
                                <Typography variant="h6" >Refer Candidate</Typography>
                                <small>Please enter details of candidate you refer.</small>
                            </div>
                            <div className="d-flex flex-column flex-grow-1 mer-margin-left-16 mer-margin-right-16 mer-margin-top-16">
                                <Card className="d-flex flex-column secondary mer-margin-right-0">
                                    <div className="mer-font-bold mer-margin-8">Name</div>
                                    <div className="d-flex justify-content-start align-content-start flex-wrap">
                                        <div className="d-flex col-md-4 col-lg-3 col-sm-12 col-12 col-xs-12">
                                            <div className="d-flex flex-column flex-grow-1 mer-margin-8">
                                                <FormLabel>
                                                    First Name<sup className="mer-color-red">*</sup>
                                                </FormLabel>
                                                <TextField
                                                    name="first_name"
                                                    placeholder="First Name"
                                                    {...TEXT_FIELD_CONFIG.OUTLINED}
                                                    value={registrationDetailsForm.values.first_name}
                                                    onChange={registrationDetailsForm.handleChange}
                                                    onBlur={registrationDetailsForm.handleBlur}
                                                    error={
                                                        registrationDetailsForm.touched.first_name &&
                                                            registrationDetailsForm.errors.first_name
                                                            ? true
                                                            : false
                                                    }
                                                />

                                                <div className="mer-error">
                                                    {registrationDetailsForm.touched?.first_name &&
                                                        registrationDetailsForm.errors?.first_name ?
                                                        registrationDetailsForm.errors.first_name
                                                        : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex col-12 col-md-4 col-lg-3 col-sm-12 col-xs-12">
                                            <div className="d-flex flex-column flex-grow-1 mer-margin-8">
                                                <FormLabel> Middle Name</FormLabel>
                                                <TextField
                                                    name="middle_name"
                                                    placeholder="Middle Name"
                                                    {...TEXT_FIELD_CONFIG.OUTLINED}
                                                    value={registrationDetailsForm.values.middle_name}
                                                    onChange={(e) => { registrationDetailsForm.setFieldValue('middle_name', e.target.value.trim()); }}
                                                    onBlur={registrationDetailsForm.handleBlur}
                                                    InputLabelProps={{ shrink: false }}
                                                    error={
                                                        registrationDetailsForm.touched.middle_name &&
                                                            registrationDetailsForm.errors.middle_name
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                <div className="mer-error">
                                                    {registrationDetailsForm.touched?.middle_name &&
                                                        registrationDetailsForm.errors?.middle_name ?
                                                        registrationDetailsForm.errors.middle_name
                                                        : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex col-12 col-md-4 col-lg-3 col-sm-12 col-xs-12">
                                            <div className="d-flex flex-column flex-grow-1 mer-margin-8">
                                                <FormLabel>
                                                    Last Name<sup className="mer-color-red">*</sup>
                                                </FormLabel>
                                                <TextField
                                                    name="last_name"
                                                    placeholder="Last Name"
                                                    {...TEXT_FIELD_CONFIG.OUTLINED}
                                                    value={registrationDetailsForm.values.last_name}
                                                    onChange={(e) => { registrationDetailsForm.setFieldValue('last_name', e.target.value.trim()); }}
                                                    onBlur={registrationDetailsForm.handleBlur}
                                                    error={
                                                        registrationDetailsForm.touched.last_name &&
                                                            registrationDetailsForm.errors.last_name
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                <div className="mer-error">
                                                    {registrationDetailsForm.touched?.last_name &&
                                                        registrationDetailsForm.errors?.last_name ?
                                                        registrationDetailsForm.errors.last_name
                                                        : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                                <Card className="d-flex flex-column secondary mer-margin-right-0">
                                    <div className="mer-font-bold mer-margin-8">Contact</div>
                                    <div className="d-flex justify-content-start align-content-start flex-wrap">
                                        <div className="d-flex col-12 col-md-4 col-lg-3 col-sm-12 col-xs-12">
                                            <div className="d-flex flex-column flex-grow-1 mer-margin-8">
                                                <FormLabel>
                                                    Email ID<sup className="mer-color-red">*</sup>
                                                </FormLabel>
                                                <TextField
                                                    name="email_id"
                                                    placeholder="Email ID"
                                                    {...TEXT_FIELD_CONFIG.OUTLINED}
                                                    value={registrationDetailsForm.values.email_id}
                                                    onChange={registrationDetailsForm.handleChange}
                                                    onBlur={registrationDetailsForm.handleBlur}
                                                    error={
                                                        registrationDetailsForm.touched.email_id &&
                                                            registrationDetailsForm.errors.email_id
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                <div className="mer-error">
                                                    {registrationDetailsForm.touched?.email_id &&
                                                        registrationDetailsForm.errors?.email_id ?
                                                        registrationDetailsForm.errors.email_id
                                                        : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex col-12 col-md-4 col-lg-3 col-sm-12 col-xs-12">
                                            <div className="d-flex flex-column flex-grow-1 mer-margin-8">
                                                <FormLabel>
                                                    Mobile Number<sup className="mer-color-red">*</sup>
                                                </FormLabel>
                                                <TextField
                                                    name="mobile_number"
                                                    placeholder="Mobile Number"
                                                    {...TEXT_FIELD_CONFIG.OUTLINED}
                                                    value={registrationDetailsForm.values.mobile_number}
                                                    onChange={registrationDetailsForm.handleChange}
                                                    onBlur={registrationDetailsForm.handleBlur}
                                                    error={
                                                        registrationDetailsForm.touched.mobile_number &&
                                                            registrationDetailsForm.errors.mobile_number
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                <div className="mer-error">
                                                    {registrationDetailsForm.touched?.mobile_number &&
                                                        registrationDetailsForm.errors?.mobile_number ?
                                                        registrationDetailsForm.errors.mobile_number
                                                        : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex col-12 col-md-4 col-lg-3 col-sm-12 col-xs-12">
                                            <div className="d-flex flex-column flex-grow-1 mer-margin-8">
                                                <FormLabel>
                                                    Alternate Mobile Number
                                                </FormLabel>
                                                <TextField
                                                    name="alt_mobile_number"
                                                    placeholder="Alternate Mobile Number"
                                                    {...TEXT_FIELD_CONFIG.OUTLINED}
                                                    value={registrationDetailsForm.values.alt_mobile_number}
                                                    onChange={registrationDetailsForm.handleChange}
                                                    onBlur={registrationDetailsForm.handleBlur}
                                                    error={
                                                        registrationDetailsForm.touched.alt_mobile_number &&
                                                            registrationDetailsForm.errors.alt_mobile_number
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                <div className="mer-error">
                                                    {registrationDetailsForm.touched?.alt_mobile_number &&
                                                        registrationDetailsForm.errors?.alt_mobile_number ?
                                                        registrationDetailsForm.errors.alt_mobile_number
                                                        : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                                <Card className="d-flex flex-column secondary mer-margin-right-0">
                                    <div className='mer-font-bold mer-margin-left-8'>Suggested Role</div>
                                    <div className="d-flex justify-content-start align-content-start flex-wrap">
                                        <div className="d-flex col-12 col-md-4 col-lg-3 col-sm-12 col-xs-12">
                                            <div className="d-flex flex-column flex-grow-1 mer-margin-8">
                                                <FormLabel>Domain<sup className='mer-color-red'>*</sup></FormLabel>
                                                <FormControl size={SELECT_FIELD_CONFIG.OUTLINED.size} >
                                                    <Select
                                                        name='domain_id' placeholder='Domain'
                                                        displayEmpty
                                                        {...SELECT_FIELD_CONFIG.OUTLINED}
                                                        value={registrationDetailsForm.values.domain_id}
                                                        MenuProps={SELECT_FIELD_CONFIG.MENU_PROPS}
                                                        // renderValue={candidateForm.values.domain_id !== "" ? undefined : () => "Domain"}
                                                        onChange={registrationDetailsForm.handleChange}
                                                        onBlur={registrationDetailsForm.handleBlur}
                                                        error={registrationDetailsForm.touched.domain_id && registrationDetailsForm.errors.domain_id ? true : false}>
                                                        <MenuItem value=''>
                                                            <span className='selectdisabled'>Domain</span>
                                                        </MenuItem>
                                                        {!CommonMethods.isNullOrUndefined(domainsList) && domainsList?.length > 0 && domainsList.map((domain) => {
                                                            return (
                                                                <MenuItem className='mer-select' key={domain['_id']} value={domain['_id']} onClick={() => handleDepartmentsList(domain)}>
                                                                    <div>{domain['domain_name']}</div>
                                                                </MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </FormControl>
                                                <div className='mer-error'>{registrationDetailsForm.touched.domain_id && registrationDetailsForm.errors.domain_id ?
                                                    registrationDetailsForm.errors.domain_id : null}</div>
                                            </div>
                                        </div>
                                        <div className="d-flex col-12 col-md-4 col-lg-3 col-sm-12 col-xs-12">
                                            <div className="d-flex flex-column flex-grow-1 mer-margin-8">
                                                <FormLabel>Department<sup className='mer-color-red'>*</sup></FormLabel>
                                                <Autocomplete
                                                    name='department_id'
                                                    className='mer-autoComplete'
                                                    disablePortal disableClearable
                                                    disabled={registrationDetailsForm.values.domain_id ? false : true}
                                                    {...AUTO_COMPLETE_CONFIG.OUTLINED}
                                                    options={departmentsList?.length ? departmentsList : []}
                                                    value={selectedDepartment}
                                                    getOptionLabel={(option) => option.department_name}
                                                    noOptionsText={'No departments under selected domain'}
                                                    renderOption={(props, option) => {
                                                        return (
                                                            <div>
                                                                <li {...props} key={option['department_id']}>
                                                                    {option['department_name']}
                                                                </li>
                                                            </div>
                                                        );
                                                    }}
                                                    key={option => option._id}
                                                    onChange={
                                                        async (event, value) => {
                                                            handleSubDepartmentsList(value._id);
                                                            setSelectedDepartment(value);
                                                            registrationDetailsForm.setFieldValue('department_name', value.department_name);
                                                            registrationDetailsForm.setFieldValue('department_id', value._id);
                                                            //candidateForm.setFieldTouched('department_id');
                                                            //candidateForm.setFieldTouched('department_id', true, true);

                                                        }
                                                    }
                                                    renderInput={(params) =>
                                                        <TextField name='department_id'
                                                            // value={registrationDetailsForm.values.department_id}
                                                            {...params} placeholder="Department"
                                                            error={registrationDetailsForm.values.domain_id && registrationDetailsForm.touched.department_id && registrationDetailsForm.errors.department_id ? true : false}
                                                            // onChange={registrationDetailsForm.handleChange}
                                                            onBlur={registrationDetailsForm.handleBlur}
                                                        />}
                                                />
                                                <div className='mer-error'>{registrationDetailsForm.values.domain_id && registrationDetailsForm.touched.department_id && registrationDetailsForm.errors.department_id ?
                                                    registrationDetailsForm.errors.department_id : null}</div>
                                            </div>
                                        </div>
                                        <div className="d-flex col-12 col-md-4 col-lg-3 col-sm-12 col-xs-12">
                                            <div className="d-flex flex-column flex-grow-1 mer-margin-8">
                                                <FormLabel>Sub Department<sup className='mer-color-red'>*</sup></FormLabel>
                                                <Autocomplete
                                                    name='sub_department_id'
                                                    className='mer-autoComplete'
                                                    disablePortal disableClearable
                                                    disabled={registrationDetailsForm.values.department_id ? false : true}
                                                    {...AUTO_COMPLETE_CONFIG.OUTLINED}
                                                    options={subDepartmentsList?.length ? subDepartmentsList : []}
                                                    value={selectedSubDepartment}
                                                    getOptionLabel={(option) => option.sub_department_name}
                                                    noOptionsText={'No sub departments under selected department'}
                                                    renderOption={(props, option) => {
                                                        return (
                                                            <div>
                                                                <li {...props} key={option['sub_department_id']}>
                                                                    {option['sub_department_name']}
                                                                </li>
                                                            </div>
                                                        );
                                                    }}
                                                    key={option => option._id}
                                                    onChange={
                                                        (event, value) => {
                                                            setSelectedSubDepartment(value);
                                                            handleRolesList(value._id);
                                                            registrationDetailsForm.setFieldValue('sub_department_name', value.sub_department_name);
                                                            registrationDetailsForm.setFieldValue('sub_department_id', value._id);
                                                            //candidateForm.setFieldTouched('sub_department_id', true, true);

                                                        }
                                                    }
                                                    renderInput={(params) =>
                                                        <TextField name='sub_department_id'
                                                            // value={registrationDetailsForm.values.sub_department_id}
                                                            {...params} placeholder="Sub Department"
                                                            error={registrationDetailsForm.values.department_id && registrationDetailsForm.touched.sub_department_id && registrationDetailsForm.errors.sub_department_id ? true : false}
                                                            // onChange={registrationDetailsForm.handleChange}
                                                            onBlur={registrationDetailsForm.handleBlur}
                                                        />}
                                                />
                                                <div className='mer-error'>{registrationDetailsForm.values.department_id && registrationDetailsForm.touched.sub_department_id && registrationDetailsForm.errors.sub_department_id ?
                                                    registrationDetailsForm.errors.sub_department_id : null}</div>
                                            </div>
                                        </div>
                                        <div className="d-flex col-12 col-md-4 col-lg-3 col-sm-12 col-xs-12">
                                            <div className="d-flex flex-column flex-grow-1 mer-margin-8">
                                                <FormLabel>Designation<sup className='mer-color-red'>*</sup></FormLabel>
                                                <Autocomplete
                                                    name='designation_id'
                                                    className='mer-autoComplete'
                                                    disablePortal disableClearable
                                                    disabled={registrationDetailsForm.values.sub_department_id ? false : true}
                                                    {...AUTO_COMPLETE_CONFIG.OUTLINED}
                                                    options={designationsList?.length ? designationsList : []}
                                                    value={selectedDesignation}
                                                    // groupBy={(option) => option.department_name}
                                                    getOptionLabel={(option) => option.designation_name}
                                                    noOptionsText={'No designations under selected sub department'}
                                                    renderOption={(props, option) => {
                                                        return (
                                                            <div >
                                                                <li {...props} key={option['designation_id']}>
                                                                    {option['designation_name']}
                                                                </li>
                                                            </div>
                                                        );
                                                    }}
                                                    key={option => option._id}
                                                    onChange={
                                                        (event, value) => {
                                                            setSelectedDesignation(value);
                                                            registrationDetailsForm.setFieldValue('designation_name', value.designation_name);
                                                            registrationDetailsForm.setFieldTouched('designation_id', true, true);
                                                            registrationDetailsForm.setFieldValue('designation_id', value._id);
                                                        }
                                                    }
                                                    renderInput={(params) =>
                                                        <TextField name='designation_id'
                                                            // value={registrationDetailsForm.values.designation_id}
                                                            {...params} placeholder="Designation"
                                                            error={registrationDetailsForm.values.sub_department_id && registrationDetailsForm.touched.designation_id && registrationDetailsForm.errors.designation_id ? true : false}
                                                            // onChange={registrationDetailsForm.handleChange}
                                                            onBlur={registrationDetailsForm.handleBlur}
                                                        />}
                                                />
                                                <div className='mer-error'>{registrationDetailsForm.values.sub_department_id && registrationDetailsForm.touched.designation_id && registrationDetailsForm.errors.designation_id ?
                                                    registrationDetailsForm.errors.designation_id : null}</div>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                                <Card className="d-flex flex-column secondary mer-margin-right-0">
                                    <div className='d-flex flex-column col-md-6 col-lg-6 col-sm-12 col-xs-12 mer-margin-left-8'>
                                        <FormLabel id="type_of_relation">How do you know the person you are referring for this role? <sup className='mer-color-red'>*</sup></FormLabel>
                                        <FormControl className="mer-margin-top-12">
                                            <TextareaAutosize
                                                minRows={3}
                                                id={`type_of_relation`}
                                                name={`type_of_relation`}
                                                value={registrationDetailsForm.values.type_of_relation}
                                                label="Type of Relation with the person being referred"
                                                className="mer-theme-text-area-bg"
                                                {...TEXT_FIELD_CONFIG.OUTLINED_LABEL}
                                                onChange={registrationDetailsForm.handleChange}
                                                onBlur={registrationDetailsForm.handleBlur}
                                                error={
                                                    registrationDetailsForm.touched.type_of_relation &&
                                                        registrationDetailsForm.errors.type_of_relation
                                                        ? true
                                                        : false
                                                }
                                            />
                                        </FormControl>
                                        <div className="mer-error">
                                            {registrationDetailsForm.touched?.type_of_relation &&
                                                registrationDetailsForm.errors?.type_of_relation ?
                                                registrationDetailsForm.errors.type_of_relation
                                                : null}
                                        </div>
                                    </div>
                                </Card>
                                <Card className="d-flex flex-column secondary mer-margin-right-0">
                                    <div className='d-flex flex-column col-md-6 col-lg-6 col-sm-12 col-xs-12 mer-margin-left-8'>
                                        <FormLabel id="candidate_fitness_id">Rate the fit of the candidate for the role you are referring them to <sup className='mer-color-red'>*</sup></FormLabel>
                                        <FormControl className="mer-margin-top-12 col-12 col-md-4 col-lg-5 col-sm-12 col-xs-12">
                                            <Select
                                                name='candidate_fitness_id' placeholder='Fitness'
                                                displayEmpty
                                                {...SELECT_FIELD_CONFIG.OUTLINED}
                                                value={registrationDetailsForm.values.candidate_fitness_id}
                                                MenuProps={SELECT_FIELD_CONFIG.MENU_PROPS}
                                                // renderValue={candidateForm.values.domain_id !== "" ? undefined : () => "Domain"}
                                                onChange={registrationDetailsForm.handleChange}
                                                onBlur={registrationDetailsForm.handleBlur}
                                                error={registrationDetailsForm.touched.candidate_fitness_id && registrationDetailsForm.errors.candidate_fitness_id ? true : false}>
                                                <MenuItem value=''>
                                                    <span className='selectdisabled'>Candidate Suitability</span>
                                                </MenuItem>
                                                {!CommonMethods.isNullOrUndefined(fitnessList) && fitnessList?.length > 0 && fitnessList.map((fitness) => {
                                                    return (
                                                        <MenuItem className='mer-select' key={fitness['_id']} value={fitness['_id']}>
                                                            <div>{fitness['fitness_name']}</div>
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                        <div className="mer-error">
                                            {registrationDetailsForm.touched?.candidate_fitness_id &&
                                                registrationDetailsForm.errors?.candidate_fitness_id ?
                                                registrationDetailsForm.errors.candidate_fitness_id
                                                : null}
                                        </div>
                                    </div>
                                </Card>
                                <Card className="d-flex flex-column secondary mer-margin-right-0">
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className='mer-font-bold'>Resume<sup className='mer-color-red'>*</sup></div>
                                    </div>
                                    <div className='d-flex flex-column mer-margin-top-16'>
                                        <Card onDrop={onDrop} className='secondary mer-margin-right-0 d-flex flex-wrap justify-content-center upload-field flex-grow-1 mer-margin-bottom-8'>
                                            <div {...getRootProps({ className: "dropzone" })}
                                                className='d-flex flex-grow-1 mer-padding-8 mer-cursor-pointer'>
                                                <input name='upload_file' className="input-zone" {...getInputProps()} />
                                                <div className='d-flex flex-column align-items-center justify-content-center flex-grow-1'>
                                                    <MdCloudUpload className='mer-icon-color mer-width-30 mer-height-30 mer-margin-bottom-8 mer-cursor-pointer' />
                                                    {isDragActive ?
                                                        <div className="">
                                                            Release to drop the files here
                                                        </div> :
                                                        <div className="">
                                                            Drag and drop  or <a className="mer-color2" href='javascript:void(0);'>browse</a>
                                                            {(!CommonMethods.isNullOrUndefined(registrationDetailsForm.values.resume) && acceptedFiles.length > 0) ?
                                                                ' to change your file' : ' your file'}

                                                        </div>}
                                                </div>
                                            </div>
                                        </Card>
                                        {(!CommonMethods.isNullOrUndefined(registrationDetailsForm.values.resume)) ?
                                            <div className="d-flex justify-content-center align-items-center">File name:&nbsp;
                                                <a className="mer-color2" href={fileLink} target="_blank">{registrationDetailsForm.values.resume?.name ? registrationDetailsForm.values.resume?.name : registrationDetailsForm.values.resume?.resume_original}</a>
                                            </div> : null}
                                        <div className=''>
                                            {!isValidFileType ?
                                                <ul className='mer-error mer-margin-bottom-8'>
                                                    <li className='mer-font-size-12'>Please upload only in .pdf or .doc or .docx format.</li>
                                                    <li className='mer-font-size-12'>Maximum number of files allowed is 1</li>
                                                </ul> :
                                                (registrationDetailsForm.touched.resume && registrationDetailsForm.errors.resume) ?
                                                    <span className='mer-error mer-margin-bottom-8'>{registrationDetailsForm.errors.resume}</span> : null}
                                        </div>
                                        <div className='mer-font-size-12'>Note : Please upload only in .pdf or .doc or .docx format.(size should be less than {CommonMethods.max_file_size_string})</div>
                                    </div>
                                </Card>
                                {/* <Card className="d-flex flex-column secondary mer-margin-right-0">
                                        <div>{JSON.stringify(registrationDetailsForm, null, 4)}</div>
                                    </Card> */}
                            </div>
                        </Card >
                        <div className={deviceProperties.isMobileDevice ? 'd-flex align-self-end flex-column mer-padding-16'
                            : 'd-flex justify-content-between align-self-end align-items-center mer-padding-16'}>
                            <div className="mer-color-red mer-margin-8">
                                Note: All the fields marked as ‘*’ are mandatory fields.
                            </div>
                            <div className="d-flex justify-content-end">
                                {/* <Button
                                        variant="text"
                                        className="mer-margin-right-8 textcls"
                                    >
                                        Discard
                                    </Button> */}
                                <Tooltip title={registrationDetailsForm.isValid ? '' : 'Please provide valid details'} >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                    >
                                        {!loading ? (
                                            'Submit'
                                        ) : (
                                            <Box className="d-flex">
                                                <CircularProgress size="1.3rem"/>
                                            </Box>
                                        )}
                                    </Button>
                                </Tooltip>
                            </div>
                        </div>
                    </form >
                </div>
            </div>
            {/* <Dialog open={Boolean(open_previous_profile)}
                className={deviceProperties?.isMobileDevice || deviceProperties.isTabletDevice ? '' : "previous-profile-view-dialog"}>
                <CandidateDuplicateApplicationDetails previous_application_details={previous_application_details} title={title_for_duplicate_dialog}
                    onChange={(status) => {
                        if (status) {
                            CreateProfile(payLoad?.payLoad, payLoad?.resume_payload, payLoad?.event_info, true)
                        }
                        setOpenPreviousDialog(false);
                        setLoading(false);
                    }}
                />
            </Dialog> */}

            <Dialog
                className="dialog-box"
                open={openSubmitted}
                // onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <SubmittedDialog />
                <DialogActions>
                    {/* <Button onClick={() => window.location.reload()}>Refer Another</Button> */}
                    <Button onClick={() => {
                        // debugger
                        // var customWindow = window.open('', '_blank', '');
                        // customWindow.close();
                        window.location.reload();
                    }}>
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default CandidateReferralForm;
