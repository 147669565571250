export const TEXT_FIELD_CONFIG = {
  OUTLINED: {
    variant: "outlined",
    size: "small",
    id: "outlined-size-small",
    sx: {
      "& legend": { display: "none" },
      "& fieldset": { top: 0 },
    },
  },
  OUTLINED_LABEL: {
    variant: "outlined",
    size: "small",
    id: "outlined-size-small",
  },
};

export const SELECT_FIELD_CONFIG = {
  OUTLINED: {
    size: "small",
    labelId: "demo-select-small",
    id: "demo-select-small",
    sx: {
      "& legend": { display: "none" },
      "& fieldset": { top: 0 },
    },
  },
  OUTLINED_LABEL: {
    size: "small",
    labelId: "demo-select-small",
    id: "demo-select-small",
  },
  MENU_PROPS: { PaperProps: { sx: { maxHeight: "40%" } } },
};
export const AUTO_COMPLETE_CONFIG = {
  OUTLINED: {
    id: "combo-box-demo",
    size: "small",
    clearIcon: false,
    sx: {
      "& legend": { display: "none" },
      "& fieldset": { top: 0 },
    },
  },
  OUTLINED_LABEL: {
    id: "combo-box-demo",
    size: "small",
    clearIcon: false,
  },
};

export const CHECK_BOX_CONFIG = {
  size: "small",
  label: { inputProps: { "aria-label": "Checkbox demo" } },
};
export const DATE_PICKER_CONFIG = {
  OUTLINED: {
    variant: "outlined",
    size: "small",
    id: "outlined-size-small",
    sx: {
      "& legend": { display: "none" },
      "& fieldset": { top: 0 },
    },
  },
  OUTLINED_LABEL: {
    variant: "outlined",
    size: "small",
    id: "outlined-size-small",
  },
};
