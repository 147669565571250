import { useContext, useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import * as API from "../../utills/API";
import { LoggedInUserContext } from "../../App";
import { isNullOrUndefined } from "../../utills/CommonMethods";
import IMAGES from '../../utills/constants/Images';
import { Paper,useTheme } from '@mui/material';
import { ThemeContext } from "../../App";




const useAuth = () =>{
    const [isAuth, setIsAuth] = useState(null);
    const { loggedInUser, setLoggedInUser } = useContext(LoggedInUserContext);
    
    const location = useLocation();
    

    useEffect(()=>{
        const isExists = async ()=>{

            if(!isNullOrUndefined(loggedInUser['screens'])){
                let forms = !isNullOrUndefined(loggedInUser['screens']) ? loggedInUser['screens']['forms'] : [];
                let results = forms.filter(form => location.pathname.toString().startsWith(form.base_path) || form.base_path.startsWith('/recruitee/admin'));                
                
                let form_view_access = results.length > 0 ?  results[0].can_view : false;

                let hasPageAccess = results.length > 0 & form_view_access ? true : false;
                
                setIsAuth(hasPageAccess);
            }
            else{
                API.GetMethod("getUserContext").then((user_context_response) => {
                    let user_data = !isNullOrUndefined(user_context_response['data']) ? user_context_response['data'] : {}
                 
                    let forms = !isNullOrUndefined(user_data['screens']) ? user_data['screens']['forms'] : [];
                    let results = forms.filter(form => location.pathname.toString().startsWith(form.base_path) || (location.pathname.startsWith('/recruitee/admin') && form.base_path.startsWith('/recruitee/admin')));
                    let form_view_access = results.length > 0 ?  results[0].can_view : false;
                    let hasPageAccess = results.length > 0 & form_view_access ? true : false;
                    setIsAuth(hasPageAccess);
                });
            }
        }
        isExists();
    },[location]);
    
    return isAuth;
}


const PageAccessGuard = () =>{
    const Themeobj = useContext(ThemeContext);
    const isAuth = useAuth();
    if(isNullOrUndefined(isAuth))
    return null;
    return isAuth ? <Paper className="d-flex" sx={{ backgroundImage: Themeobj.currentTheme==='light' ?'url(' + IMAGES?.page_background_light + ')' :'url(' + IMAGES?.page_background_dark + ')', height: '100%', width: '100%'}}><Outlet /> </Paper> : <Navigate  to="/recruitee/access-denied" />
}

export default PageAccessGuard