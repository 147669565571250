import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import {
    confirmation_dialog,
    simple_dialog,
    status_dialog,
} from "../../utills/Alerts";
import * as Messages from "../../utills/constants/Messages";
import * as API from "../../utills/API";
import * as CommonMethods from "../../utills/CommonMethods";
import RichTextEditor from "react-rte";
import * as Yup from "yup";
import { IoMdCloseCircleOutline } from "react-icons/io";
import {
    TextField,
    FormControl,
    Button,
    FormLabel,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Autocomplete,
    Card,
    Tooltip,
    Box,
    CircularProgress,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import {
    AUTO_COMPLETE_CONFIG,
    CHECK_BOX_CONFIG,
    TEXT_FIELD_CONFIG,
} from "../../utills/constants/FormFieldFormats";
import create from "zustand";
import Loading from "../../components/Loading";
import { useDropzone } from "react-dropzone";
import { MdCloudUpload } from "react-icons/md";
import { FiMinusCircle } from "react-icons/fi";

export const offer_letter_dialog = async (open, action, candidate, callback) => {

    OfferLetterFormDialogStore.setState({ open, action, candidate, callback });

};

const OfferLetterFormDialogStore = create((set) => ({
    open: "",
    action: "",
    candidate: "",
    close: () =>
        set({
            open: false,
        }),
    callback: undefined,
}));

function OfferLetterForm(props) {
    const { open, close, candidate, action, callback } =
        OfferLetterFormDialogStore();

    const [mailData, setTemplateData] = useState(null);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [employeeList, setEmployeeList] = useState([]);
    const [static_attachments_list, setStaticAttachments] = useState([]);
    // [
    //     {
    //         label: 'BGV Authorisation From',
    //         _id: 1,
    //         size: 2
    //     }, {
    //         label: 'Merilytics MAP View',
    //         _id: 2,
    //         size: 3
    //     }, {
    //         label: 'Merilytics Employee Benefits',
    //         _id: 3,
    //         size: 2.5

    //     }, {
    //         label: 'Merilytics Pre-Onboarding FAQs',
    //         _id: 4,
    //         size: 4
    //     }
    // ]

    const markup = "";
    const [loading, setLoading] = useState(false);

    const [value, setValue] = useState(
        RichTextEditor.createValueFromString(markup, "html")
    );

    const offer_letter_form = useFormik({
        initialValues: {
            candidate_id: "",
            stage_id: "",
            status_id: "",
            subject: "",
            body: "",
            saved_ccs: [],
            selected_cc: [],
            recommended_attachments: [],
            optional_attachments: []
        },
        validationSchema: Yup.object().shape({
            saved_ccs: Yup.array()
                .min(1, "Please select at least 1 recipient")
                .required(Messages?.REQUIRED_FIELD_ERROR)
                .typeError("Please select proper recipient"),
            subject: Yup.string().required(Messages?.REQUIRED_FIELD_ERROR),
            body: Yup.string().required(Messages?.REQUIRED_FIELD_ERROR),
            candidate_id: Yup.string().nullable(),
            stage_id: Yup.string(),
            status_id: Yup.string(),
            recommended_attachments: Yup.array().nullable()
                .required(Messages?.REQUIRED_FIELD_ERROR)
            // .min(1, "Please select at least one")
        }),
        onSubmit: (values) => {
            let message = `Do you want to ${action?.button_name} the mail ?`;
            setLoading(true);
            if (!checkAttachmentsSize()) {
                let status_details = {
                    ...CommonMethods.STATUS_POPUP_DETAILS.error,
                    message: Messages.error_messages.attachments_size_error,
                };
                status_dialog(status_details, () => {
                    setLoading(false);
                }
                );
                return;
            }
            confirmation_dialog(message, () => {
                if (uploadedFiles?.length > 0 || values?.recommended_attachments?.length > 0) {
                    let upload_payload = new FormData();
                    // console.log("uploadedFiles",uploadedFiles)
                    for (const file of uploadedFiles) {
                        upload_payload.append("optional_documents", file, file.name);
                    }
                    upload_payload.append("candidate_id", candidate?._id);
                    for (const attachment of values?.recommended_attachments) {
                        upload_payload.append("recommended_attachments", attachment);
                    }
                    // upload_payload.append("recommended_attachments", values?.recommended_attachments);
                    API.PostMethod('offerLetter/upload', upload_payload).then(resp => {
                        gridStatusChange();
                    }, error => {
                        setLoading(false);
                    })
                }
                else {
                    gridStatusChange();
                }
            }, null, null, () => {
                setLoading(false);
            });
        },
    });

    function gridStatusChange() {
        API.PostMethod(`${action?.confirmation_api}`, {
            action: action,
            candidate: candidate,
            subject: offer_letter_form.values.subject,
            body: offer_letter_form.values.body,
            saved_ccs: offer_letter_form.values.saved_ccs.join(','),
        }).then(
            (result) => {
                if (action.success_message) {
                    setLoading(false);
                    let status_details = {
                        ...CommonMethods.STATUS_POPUP_DETAILS.success,
                        message: action.success_message,
                    };
                    status_dialog(status_details, () => {
                        callback(true);
                        return;
                    });
                    // simple_dialog(action.success_message, () => {
                    // });
                }
                handleClose();
            },
            (error) => { }
        );
    }

    function checkAttachmentsSize() {
        let sizeofAttachments = 0;
        for (let [index, rec_file] of offer_letter_form.values?.recommended_attachments?.entries()) {
            let file = static_attachments_list.filter((option) => option._id === rec_file);
            if (!CommonMethods.isNullOrUndefined(file) && !CommonMethods.isNullOrUndefined(file[0]?.attachment_size)) {
                sizeofAttachments = sizeofAttachments + file[0]?.attachment_size;
            }
        }
        if (uploadedFiles?.length > 0) {
            for (let [index, opt_file] of uploadedFiles?.entries()) {
                sizeofAttachments = sizeofAttachments + (opt_file?.size / 1048576);
            }
        }
        return (sizeofAttachments <= 20);
    }

    useEffect(() => {
        if (open && localStorage.getItem("mer-auth-key") && (CommonMethods.isNullOrUndefined(employeeList) && employeeList.length == 0)) {
            getEmployeesList();
        }
        // if (!CommonMethods.isNullOrUndefined(action) && !CommonMethods.isNullOrUndefined(candidate)) {
        setDataLoaded(false);
        //     getTemplate();
        // }
        Promise.all([
            getAttachmentsInfo(),
            getTemplate()
        ]).then(function (values) {
            setDataLoaded(true);
        });

    }, [candidate]);

    async function getEmployeesList() {
        API.GetMethod(`getEmployees`)
            .then(async (res) => {
                setEmployeeList(res?.data ? res?.data : []);
            }, error => {
                setEmployeeList([]);
            })
    }

    async function getAttachmentsInfo() {
        return new Promise((resolve, reject) => {
            if (localStorage.getItem("mer-auth-key") && !CommonMethods.isNullOrUndefined(action) && !CommonMethods.isNullOrUndefined(candidate)) {
                API.GetMethod(`offerLetter/attachmentsinfo/${candidate?._id}`)
                    .then(async (res) => {
                        setStaticAttachments(res?.data ? res?.data : []);
                        if (!CommonMethods.isNullOrUndefined(res?.data) && res?.data?.length > 0) {
                            let selected_attachmets = [];
                            for (let [index, element] of res?.data?.entries()) {
                                if (element?.is_active && !element?.is_bgv_details_available) {
                                    selected_attachmets.push(element?._id)
                                }
                            }
                            offer_letter_form.setFieldValue("recommended_attachments", selected_attachmets);
                        }
                        resolve();
                    }, error => {
                        setStaticAttachments([]);
                        reject(error)
                    })
            }
            else {
                resolve()
            }
        });
    }

    function getTemplate() {
        return new Promise((resolve, reject) => {
            if (!CommonMethods.isNullOrUndefined(action) && !CommonMethods.isNullOrUndefined(candidate)) {

                API.PostMethod(`getEmailTemplateData/` + action?.stage_conditions[0]?.email_template_ids[0],
                    {
                        action: action,
                        candidate: candidate,
                    })
                    .then(async (res) => {

                        if (!CommonMethods.isNullOrUndefined(res?.data)) {
                            let templateData = res?.data;
                            setTemplateData(res.data);
                            const cc_mail_ids = templateData.cc.toString().split(",");
                            const foundObjects = [];
                            cc_mail_ids.forEach((searchId) => {
                                const foundObject = employeeList?.find(
                                    (obj) => obj.email_id === searchId
                                );
                                if (foundObject) {
                                    foundObjects.push(foundObject);
                                }
                            });
                            // offer_letter_form.setValues({
                            //     ...offer_letter_form.values,
                            //     subject: templateData.subject || "",
                            //     saved_ccs: templateData.cc.toString().split(",") || [],
                            //     selected_cc: foundObjects || [],
                            // });
                            offer_letter_form.setFieldValue("subject", templateData.subject || "");
                            offer_letter_form.setFieldValue("saved_ccs", templateData.cc.toString().split(",") || []);
                            offer_letter_form.setFieldValue("selected_cc", foundObjects || []);

                            setValue(RichTextEditor.createValueFromString(templateData.body || "", "html"));
                            let content = RichTextEditor.createValueFromString(templateData.body || "", "html");
                            offer_letter_form.setFieldTouched("body", true, true);
                            offer_letter_form.setFieldValue("body", content.toString("html"));
                        }

                        // setDataLoaded(true);
                        resolve();
                    }, error => {
                        // setDataLoaded(true);
                        reject(error);
                    }).catch((error) => {
                        reject(error);
                    });
            }
            else {
                resolve()
            }
        });
    }
    function handleRecipients(value) {
        let recipients_ids = [];

        for (let recipient of value) {
            recipients_ids.push(recipient.email_id);
        }
        offer_letter_form.setFieldValue("selected_cc", value);
        offer_letter_form.setFieldValue("saved_ccs", recipients_ids);
    }

    function mailContentChange(value) {
        setValue(value);
        offer_letter_form.setFieldTouched("body", true, true);
        offer_letter_form.setFieldValue("body", value.toString("html"));
    }
    function handleOptionChange(event) {
        let staticDocsArray = offer_letter_form.values?.recommended_attachments;
        const index = staticDocsArray?.indexOf(event?.target?.value);
        if (index === -1) {
            staticDocsArray.push(event?.target?.value);
        }
        else {
            staticDocsArray = staticDocsArray.filter((option) => option !== event?.target?.value)
        }
        offer_letter_form.setFieldValue(`recommended_attachments`, staticDocsArray);
    }

    const [isValidFileType, setValidFileType] = useState(true);
    const [uploadedFiles, setUploadedFiles] = useState([]);

    const onDrop = useCallback((acceptedFiles) => {
        if (
            !CommonMethods.isNullOrUndefined(acceptedFiles) &&
            acceptedFiles.length > 0
        ) {
            for (var file of acceptedFiles) {
                setValidFileType(true);
                let newObj = file;
                setUploadedFiles((currentFiles) => [...currentFiles, newObj]);
            }
        } else {
            setValidFileType(false);
        }
    }, []);

    function removeFile(index) {
        let files = [...uploadedFiles];
        // let files = JSON.parse(JSON.stringify(uploadedFiles));
        files.splice(index, 1);
        setUploadedFiles(files);
    }


    function handleClose() {
        offer_letter_form.resetForm();
        setUploadedFiles([]);
        // setStaticAttachments([]);
        // setTimeout(() => {
        close();
        // }, 1000);
    }



    const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
        accept: {
            document: [
                ".pdf",
                ".PDF",
                ".doc",
                ".DOC",
                ".docx",
                ".DOCX"
            ]
        },
        onDrop,
        maxSize: CommonMethods.max_file_size
    });

    return (
        <Dialog
            open={Boolean(open)} className='offer-letter-dialog'
            // onClose={handleClose}
            disableEscapeKeyDown={true}>
            {dataLoaded ?
                <form className="d-grid flex-grow-1 mer-overflow mer-form" onSubmit={offer_letter_form.handleSubmit}>
                    <div >
                        <DialogTitle className="mer-theme-dialog-header-bg-tables mer-padding-8">
                            <div className=" d-flex flex-row align-items-center justify-content-between">
                                <div className="mer-font-weight-150 mer-font-size-20">
                                    {action.action_name} ?
                                </div>
                                <DialogActions>
                                    <IoMdCloseCircleOutline
                                        onClick={() => {
                                            if (!loading) {
                                                handleClose()
                                            }
                                        }}
                                        className={"mer-min-height-24 mer-min-width-24" + (loading ? '' : ' mer-cursor-pointer')}
                                    />
                                </DialogActions>
                            </div>
                        </DialogTitle>
                    </div>
                    <DialogContent>
                        <div className='d-flex flex-column flex-wrap'>
                            <Card className="d-flex flex-grow-1 flex-column teritary  mer-margin-0 mer-padding-8">
                                <div className="d-flex  justify-content-between">
                                    <div
                                        style={{ flexShrink: 0, whiteSpace: "nowrap", marginRight: "8px", }}
                                    >
                                        <b>Name:&nbsp;</b>
                                        {CommonMethods.concateNames(candidate?.first_name, candidate?.middle_name, candidate?.last_name)}
                                    </div>
                                    <div
                                        style={{ flexShrink: 0, whiteSpace: "nowrap", marginRight: "8px", }}
                                    >
                                        <b>Applied domain:&nbsp;</b>
                                        {candidate.domain_name}
                                    </div>
                                    {/* <div style={{ flexShrink: 0, whiteSpace: 'nowrap', marginRight: '8px' }}><b>Role:</b>{candidate.department_name}</div> */}
                                    <div
                                        style={{
                                            flexShrink: 0, whiteSpace: "nowrap",
                                            marginRight: "8px",
                                        }}
                                    >
                                        <b>Designation:&nbsp;</b>
                                        {candidate.designation_name}
                                    </div>
                                </div>
                            </Card>
                            <div className='d-flex flex-row col-12 flex-wrap'>
                                <div className="d-flex flex-column col-lg-8 col-md-8 col-sm-12 col-xs-12 col-12">
                                    <div className="mer-margin-0 flex-grow-1 mer-padding-top-12 mer-padding-bottom">
                                        <Card className="d-flex flex-grow-1 flex-column teritary mer-margin-0  flex-grow-1 mer-padding-12">
                                            <div className="d-flex flex-row flex-wrap justify-content-start align-items-start align-content-start">
                                                <div className="d-flex flex-column mer-margin-right-12 mer-margin-bottom-12 col-12">
                                                    <div>
                                                        <b className="mer-margin-right-4">To:</b>
                                                        {candidate.email_id}
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-column mer-margin-right-12 mer-margin-bottom-12 col-12">
                                                    <FormLabel id="">Subject</FormLabel>
                                                    <FormControl>
                                                        <TextField
                                                            id={`subject`}
                                                            name={`subject`}
                                                            placeholder="Subject"
                                                            className="mer-field-bg-white"
                                                            {...TEXT_FIELD_CONFIG.OUTLINED}
                                                            onChange={offer_letter_form.handleChange}
                                                            value={offer_letter_form.values?.subject}
                                                            // value={ mailData?.subject                      }
                                                            error={
                                                                offer_letter_form.touched?.subject &&
                                                                    offer_letter_form.errors?.subject
                                                                    ? true
                                                                    : false
                                                            }
                                                            onBlur={offer_letter_form.handleBlur}
                                                        />
                                                    </FormControl>
                                                    <div className="mer-error">
                                                        {offer_letter_form.touched?.subject &&
                                                            offer_letter_form?.errors?.subject ? (
                                                            <span className="error">
                                                                {offer_letter_form?.errors?.subject}
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                </div>

                                                <div className="d-flex flex-column mer-margin-right-12 mer-margin-bottom-12 col-12">
                                                    <FormControl>
                                                        <FormLabel id="">
                                                            Add Cc<sup className="mer-color-red">*</sup>
                                                        </FormLabel>
                                                        <Autocomplete
                                                            name="saved_ccs"
                                                            className="mer-autoComplete"
                                                            //onPaste={handlePaste}
                                                            multiple
                                                            filterSelectedOptions
                                                            disableClearable
                                                            {...AUTO_COMPLETE_CONFIG.OUTLINED}
                                                            options={
                                                                !CommonMethods.isNullOrUndefined(employeeList) &&
                                                                    employeeList?.length
                                                                    ? employeeList
                                                                    : []
                                                            }
                                                            value={offer_letter_form.values?.selected_cc}
                                                            getOptionLabel={(option) => option.employee_name}
                                                            getOptionDisabled={(option) => !option.is_active}
                                                            renderOption={(props, option) => {
                                                                return (
                                                                    <li {...props} key={option["_id"]}>
                                                                        <div className="d-flex flex-column">
                                                                            <div>{option["employee_name"]}</div>
                                                                            <div className="mer-font-size-12">
                                                                                {option["email_id"]}
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                );
                                                            }}
                                                            key={(option) => option._id}
                                                            onChange={async (event, value) => {
                                                                handleRecipients(value);
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    name="saved_ccs"
                                                                    className="mer-field-bg-white"
                                                                    // onPaste={handlePaste}
                                                                    value={offer_letter_form.values.saved_ccs}
                                                                    {...params}
                                                                    placeholder="Type in or select employee"
                                                                    error={
                                                                        offer_letter_form.touched.saved_ccs &&
                                                                            offer_letter_form.errors.saved_ccs
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    onChange={offer_letter_form.handleChange}
                                                                    onBlur={offer_letter_form.handleBlur}
                                                                />
                                                            )}
                                                        />
                                                    </FormControl>
                                                    {offer_letter_form.touched.saved_ccs &&
                                                        offer_letter_form.errors.saved_ccs ? (
                                                        <span className="mer-error">
                                                            {offer_letter_form.errors.saved_ccs}
                                                        </span>
                                                    ) : null}
                                                </div>

                                                <div className="d-flex flex-column mer-margin-right-12 mer-margin-bottom-12 col-12">
                                                    <FormControl>
                                                        <FormLabel id="demo-select-small">
                                                            Body<sup className="mer-color-red">*</sup>
                                                        </FormLabel>
                                                        <RichTextEditor
                                                            id="body"
                                                            placeholder="Enter here..."
                                                            className="mer-field-bg-white"
                                                            value={value}
                                                            name={offer_letter_form.values.body}
                                                            toolbarConfig={CommonMethods.toolbarConfig}
                                                            onChange={mailContentChange}
                                                            onBlur={offer_letter_form.handleBlur}
                                                            error={
                                                                offer_letter_form.touched.body &&
                                                                    offer_letter_form.errors.body
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    </FormControl>
                                                    {offer_letter_form.touched.body &&
                                                        offer_letter_form.errors.body ? (
                                                        <span className="mer-error">
                                                            {offer_letter_form.errors.body}
                                                        </span>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </Card>
                                        {/* </DialogContent> */}
                                    </div>
                                </div>
                                <div className="d-flex flex-column flex-grow-1 col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                                    <div className="d-flex flex-column mer-margin-0 flex-grow-1 mer-padding-left-12 mer-padding-top-12 mer-padding-bottom">
                                        <Card className="d-flex flex-column teritary mer-margin-bottom-12 mer-margin-left-0 mer-margin-right-0 mer-margin-top-0 mer-padding-12">
                                            <div className='d-flex flex-column'>
                                                <FormLabel id="">Recommended Attachments</FormLabel>
                                                <FormGroup className='d-flex flex-row mer-margin-bottom-8'>
                                                    {static_attachments_list.map((option) => {
                                                        return (
                                                            <div className='d-flex flex-row' key={option._id}>
                                                                <FormControlLabel
                                                                    name='recommended_attachments'
                                                                    control={<Checkbox name='recommended_attachments'
                                                                        checked={offer_letter_form.values.recommended_attachments?.includes(option._id)}
                                                                        {...CHECK_BOX_CONFIG}
                                                                        onChange={(event) => { handleOptionChange(event); }}
                                                                        value={option._id} />}
                                                                    label={CommonMethods.replaceUnderScore(option?.attachment_name)}
                                                                    value={option._id}
                                                                />
                                                            </div>
                                                        )
                                                    })}
                                                </FormGroup>
                                                <div className='mer-error mer-padding-0'>
                                                    {offer_letter_form.touched.recommended_attachments
                                                        && (offer_letter_form.errors.recommended_attachments ?
                                                            offer_letter_form.errors.recommended_attachments
                                                            // : offer_letter_form.values.recommended_attachments?.length === 0
                                                            // ? Messages.REQUIRED_FIELD_ERROR 
                                                            : null)
                                                    }</div>
                                            </div>
                                        </Card>
                                        <Card className="d-flex flex-grow-1 flex-column teritary mer-margin-0 mer-padding-12">
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <div className='mer-font-bold'>Optional Attachments</div>
                                            </div>
                                            <div className='d-flex flex-column mer-margin-top-16'>
                                                <Card onDrop={onDrop} className='mer-theme-whitecard d-flex flex-wrap justify-content-center upload-field flex-grow-1 mer-margin-bottom-8'>
                                                    <div {...getRootProps({ className: "dropzone" })}
                                                        className='d-flex flex-grow-1 mer-padding-8 mer-cursor-pointer'>
                                                        <input name='upload_file' className="input-zone" {...getInputProps()} />
                                                        <div className='d-flex flex-column align-items-center justify-content-center flex-grow-1'>
                                                            <MdCloudUpload className='mer-width-30 mer-height-30 mer-margin-bottom-8 mer-icon-color mer-cursor-pointer' />
                                                            {isDragActive ?
                                                                <div className="">
                                                                    Release to drop the files here
                                                                </div> :
                                                                <div className="">
                                                                    Drag and drop  or <a className='mer-color2' href='javascript:void(0);'>browse</a> your files
                                                                </div>}
                                                        </div>
                                                    </div>
                                                </Card>
                                                <div className=''>
                                                    {!isValidFileType ?
                                                        <div className='mer-error mer-margin-bottom-8'>
                                                            Please upload only in 'pdf', 'docx' or 'doc' format with max size of 8MB.
                                                        </div> : null}
                                                </div>
                                                <div className='mer-font-size-12'>Note : Attachments should be either 'pdf', 'docx' or 'doc' format.(size shouldn't be greater than {CommonMethods.max_file_size_string})</div>
                                                <div className="d-flex flex-row flex-wrap mer-margin-top-10">
                                                    {!CommonMethods.isNullOrUndefined(uploadedFiles) &&
                                                        uploadedFiles?.length > 0 &&
                                                        uploadedFiles?.map((doc, index) => (
                                                            // <div
                                                            //     key={index}
                                                            //     className="d-flex flex-column col-md-6 col-lg-6 col-sm-12 col-12 "
                                                            // >
                                                            <div className="d-flex flex-grow-1 align-items-center justify-content-between mer-padding-10 mer-margin-right-6 mer-margin-bottom-6 mer-rounded-8 mer-light-purple-background">
                                                                <div className="mer-width-percentage-85 mer-break-word">
                                                                    {doc.name}
                                                                </div>
                                                                <div className="d-flex  align-items-center justify-content-end">
                                                                    <FiMinusCircle
                                                                        className="mer-width-20 mer-height-20 mer-margin-left-10 mer-cursor-pointer mer-color-red"
                                                                        onClick={() => {
                                                                            removeFile(index);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            /* </div> */
                                                        ))}
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <div className='d-flex justify-content-end align-self-end'>
                            <Button disabled={loading} variant="outlined"
                                className='mer-margin-right-12 outlinedcls'
                                onClick={handleClose} >
                                Cancel
                            </Button>
                            <Tooltip title={offer_letter_form.isValid ? '' : 'Please provide valid details'} >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    className="text-capitalize"
                                >
                                    {!loading ? (
                                        action?.button_name
                                    ) : (
                                        <Box className="d-flex">
                                            <CircularProgress size="1.3rem"/>
                                        </Box>
                                    )}
                                </Button>
                            </Tooltip>
                        </div>
                    </DialogActions>
                </form> :
                <div className="d-flex flex-column flex-grow-1">
                    <div >
                        <DialogTitle className="mer-theme-dialog-header-bg-tables mer-padding-8">
                            <div className=" d-flex flex-row align-items-center justify-content-between">
                                <div className="mer-font-weight-150 mer-font-size-20">
                                    {action.action_name} ?
                                </div>
                                <DialogActions>
                                    <IoMdCloseCircleOutline
                                        onClick={handleClose}
                                        className="mer-min-height-24 mer-min-width-24 mer-cursor-pointer"
                                    />
                                </DialogActions>
                            </div>
                        </DialogTitle>
                    </div>
                    <div className="d-flex justify-contet-center flex-grow-1 align-items-center"><Loading /></div>
                </div>
            }

        </Dialog>
    );
}

export default OfferLetterForm;
