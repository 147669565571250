import { Skeleton } from '@mui/material';
import React from 'react';


function SkeletonAddpopup() {
 

  return (
    <>
    <div className='d-flex flex-column '>
       <div className='d-flex flex-column  col-12  mer-margin-top-30 mer-margin-left-12 '>
            <Skeleton variant="rounded" height={88} className='mer-skel-view mer-theme-skel-view mer-margin-right-12'></Skeleton>
        </div>
      <div className='d-flex flex-row flex-wrap  mer-margin-top-20 mer-margin-left-12'>
         <div className='d-flex flex-column col-md-6 col-lg-4 col-sm-12 col-12 col-xs-12 '>
            <Skeleton variant="rounded" height={118} className='mer-skel-view mer-theme-skel-view'></Skeleton>
          </div>
          <div className='d-flex flex-column col-md-6 col-lg-4 col-sm-12 col-12 col-xs-12 '>
            <Skeleton variant="rounded" height={118} className='mer-skel-view mer-theme-skel-view'></Skeleton>
          </div>
          <div className='d-flex flex-column col-md-6 col-lg-4 col-sm-12 col-12 col-xs-12 '>
            <Skeleton variant="rounded" height={118} className='mer-skel-view mer-theme-skel-view'></Skeleton>
          </div>
      </div>
    </div>
    </>
    
  )
}

export default SkeletonAddpopup