import {
  Autocomplete,
  Badge,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  InputAdornment,
  InputBase,
  Menu,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  TableSortLabel,
  Card,
  Checkbox,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useContext } from "react";
import { BiSearch } from "react-icons/bi";
import { BsArrowCounterclockwise } from "react-icons/bs";
import { MdClear } from "react-icons/md";
import { RiFilter2Line } from "react-icons/ri";
import * as Yup from "yup";
import { LoadingContext } from "../../App";

import * as API from "../../utills/API";
import * as CommonMethods from "../../utills/CommonMethods";
import {
  AUTO_COMPLETE_CONFIG,
  TEXT_FIELD_CONFIG,
} from "../../utills/constants/FormFieldFormats";
import useDeviceProperties from "../../utills/hooks/useDeviceProperties";
import SourcedProfileRegistrationDetails from "./sourced-profile-view-screens/SourcedProfileRegistrationDetails";
// import SourcedProfileView from './sourced-profile-view-screens/SourcedProfileView';
import SourcedProfileBasicDetails from "./sourced-profile-view-screens/SourcedProfileBasicDetails";
import SourcedProfileAdditionalDetails from "./sourced-profile-view-screens/SourcedProfileAdditionalDetails";
import useFormData from "../../utills/hooks/useFormData";
import {
  confirmation_dialog,
  simple_dialog,
  status_dialog,
} from "../../utills/Alerts";
// import EventEmitter from '../../utills/EventEmitter';
import {
  SHOW_OPTIONS_ONLY_HAS_ACCESS,
  customShowornot,
} from "../../utills/CommonMethods";
import { HiBadgeCheck } from "react-icons/hi";
import { IoMdCloseCircleOutline } from "react-icons/io";
import CandidateRejectForm from "./CandidateRejectForm";
import SkeletonTableBody from "../../components/Skeletons/SkeletonTableBody";
import * as Messages from "../../utills/constants/Messages";
import * as DateTimeFormats from "../../utills/DateTimeFormats";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { format, isAfter } from "date-fns";
import useSelect from "../../utills/hooks/useSelect";
import MultiCandidateSelectMenu from "./MultiCandidateSelectMenu";

const Highlight = require("react-highlighter");

function SourcedProfilesTable(props) {
  let deviceProperties = useDeviceProperties();
  const [activeStep, setActiveStep] = React.useState(0);

  const [steps, setSteps] = useState([
    {
      label: "Registration Details",
      mobile_label: "Registration",
      isCompleted: false,
    },
    { label: "Basic Details", mobile_label: "Basic", isCompleted: false },
    {
      label: "Additional Details",
      mobile_label: "Additional",
      isCompleted: false,
    },
  ]);

  const handleStep = (step) => {
    setActiveStep(step);
  };

  const [dataLoaded, setDataLoaded] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);

  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    CommonMethods.DEFAULT_ROWS_PER_PAGE
  );

  const [encodedSearch, setEncodedSearch] = useState(null);

  const [sourcedProfilesTableLoaded, setSourcedProfilesTableLoaded] =
    useState(false);
  const [sourcedProfilesList, setSourcedProfilesList] = useState([]);
  const {
    handleCheckboxClick,
    handleSelectAllClick,
    resetSelected,
    selectAll,
    selected,
  } = useSelect({
    candidatesData: sourcedProfilesList,
  });
  const [highlightText, setHighlightText] = useState("");

  const [filterEl, setFilterEl] = useState(null);
  const [searched, setSearched] = useState("");
  const [searchLoader, setSearchLoader] = useState(false);
  const { showLoader, setShowLoader } = useContext(LoadingContext);
  const [domains_list, setDomainsList] = useState([]);
  const [departments_list, setDepartmentsList] = useState([]);
  const [sub_departments_list, setSubDepartmentsList] = useState([]);
  const [designations_list, setDesignationsList] = useState([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [ope_reject_dialog, setOpenRejectDialog] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);

  const formData = useFormData();

  let REJECT_BUTTON_VALUE = "Reject";
  let ACCEPT_BUTTON_VALUE = "Approve";

  const timeout = useRef();
  // const [loader, setLoader] = useState(false);
  const [filter_form_values, setFilterFormValues] = useState();
  const [action, setAction] = useState(null);

  const [order, setOrder] = useState("asc");
  let sortDataDetails = ["full_name", "asc"];

  const handleSort = (columnName) => {
    let newArr = [columnName, order === "asc" ? "desc" : "asc"];

    if (order === "asc") {
      fetchSourcedProfilesList(
        page,
        rowsPerPage,
        encodedSearch,
        searched,
        newArr
      );
      setOrder("desc");
    } else {
      fetchSourcedProfilesList(
        page,
        rowsPerPage,
        encodedSearch,
        searched,
        newArr
      );
      setOrder("asc");
    }
    resetSelected();
  };

  const filterForm = useFormik({
    initialValues: {
      start_date: null,
      end_date: null,
      domains: [],
      departments: [],
      sub_departments: [],
      designations: [],
      selected_domains: [],
      selected_departments: [],
      selected_sub_departments: [],
      selected_designations: [],
    },
    validationSchema: Yup.object().shape({
      start_date: Yup.string().nullable(),
      end_date: Yup.string().nullable(),
      domains: Yup.array(),
      departments: Yup.array(),
      sub_departments: Yup.array(),
      designations: Yup.array(),
    }),
    onSubmit: (values) => {
      setShowLoader(true);
      setFilterEl(null);
      fetchSourcedProfilesList(
        page,
        rowsPerPage,
        encodedSearch,
        searched,
        sortDataDetails
      );
    },
  });

  useEffect(() => {
    setPage(0);
    setTotalCount(0);
    setRowsPerPage(CommonMethods.DEFAULT_ROWS_PER_PAGE);
    filterForm.setFieldValue("start_date", null);
    filterForm.setFieldValue("end_date", null);
    fetchSourcedProfilesList(
      page,
      rowsPerPage,
      encodedSearch,
      "",
      sortDataDetails
    );
  }, []);

  function openFilterPanel(event) {
    setFilterEl(event.currentTarget);
    setFilterFormValues(filterForm.values);
    if (CommonMethods.isNullOrUndefined(domains_list) && !domains_list.length) {
      fetchDomainsList();
    }
  }

  async function fetchSourcedProfilesList(
    page,
    rowsPerPage,
    encodedSearch,
    lowerCase,
    sortDetails,
    next
  ) {
    let values = JSON.parse(JSON.stringify(filterForm.values));
    values.start_date =
      values.start_date == null || values.start_date == undefined
        ? null
        : format(new Date(values.start_date), "yyyy-MM-dd");
    values.end_date =
      values.end_date == null || values.end_date == undefined
        ? null
        : format(new Date(values.end_date), "yyyy-MM-dd");
    let payLoad = {
      page: page,
      offset: rowsPerPage,
      filter: values,
      search: encodedSearch,
      sort: sortDetails,
    };
    await updatefilterIcon();
    API.PostMethod("webRegistration", payLoad)
      .then((res) => {
        let sourcedProfilesList = res?.data;
        setSourcedProfilesList(
          sourcedProfilesList["candidate_list"]
            ? sourcedProfilesList["candidate_list"]
            : []
        );
        setTotalCount(
          sourcedProfilesList["total_count"]
            ? sourcedProfilesList["total_count"]
            : sourcedProfilesList["candidate_list"]
            ? sourcedProfilesList["candidate_list"]?.length
            : 0
        );
        setShowLoader(false);
        setHighlightText(lowerCase);
        setSearchLoader(false);
        setSourcedProfilesTableLoaded(true);
        if (!CommonMethods.isNullOrUndefined(next)) {
          next();
        }
      })
      .finally(() => {
        resetSelected();
      });
  }

  function handleDepartmentsList(domain_ids) {
    fertchDepartmentsList(domain_ids);
    filterForm.setFieldValue("selected_departments", []);
    filterForm.setFieldValue("selected_sub_departments", []);
    filterForm.setFieldValue("selected_designations", []);
    filterForm.setFieldValue("departments", []);
    filterForm.setFieldValue("sub_departments", []);
    filterForm.setFieldValue("designations", []);
    filterForm.setFieldValue("domains", domain_ids);
  }
  function handleSubDepartmentsList(department_ids) {
    fertchSubDepartmentsList(department_ids);

    filterForm.setFieldValue("selected_sub_departments", []);
    filterForm.setFieldValue("selected_designations", []);
    filterForm.setFieldValue("sub_departments", []);
    filterForm.setFieldValue("designations", []);
    filterForm.setFieldValue("departments", department_ids);
  }
  function handleDesignationsList(sub_department_ids) {
    fetchDesignationsList(sub_department_ids);

    filterForm.setFieldValue("selected_designations", []);
    filterForm.setFieldValue("designations", []);
    filterForm.setFieldValue("sub_departments", sub_department_ids);
  }

  function fetchDomainsList() {
    API.GetMethod("domain").then(
      (res) => {
        setDomainsList(
          customShowornot(
            res.data.result,
            "hasAccess",
            "desc",
            SHOW_OPTIONS_ONLY_HAS_ACCESS.sourcedprofile
          )
        );
      },
      (error) => setDomainsList([])
    );
  }

  function fertchDepartmentsList(domain_ids) {
    if (domain_ids.length > 0) {
      API.GetMethod("department?domain=" + domain_ids).then(
        (resp) => {
          let departments = [];
          resp.data.forEach((dept) => {
            departments = departments.concat(dept["departments"]);
          });
          setDepartmentsList(
            customShowornot(
              departments,
              "hasAccess",
              "desc",
              SHOW_OPTIONS_ONLY_HAS_ACCESS.sourcedprofile
            )
          );
        },
        (error) => {
          setDepartmentsList([]);
        }
      );
    }
  }
  function fertchSubDepartmentsList(department_ids) {
    if (department_ids.length > 0) {
      API.GetMethod("subDepartment?department=" + department_ids).then(
        (resp) => {
          let subDepartments = [];
          resp.data.forEach((subDept) => {
            subDepartments = subDepartments.concat(subDept["sub_departments"]);
          });
          setSubDepartmentsList(
            customShowornot(
              subDepartments,
              "hasAccess",
              "desc",
              SHOW_OPTIONS_ONLY_HAS_ACCESS.sourcedprofile
            )
          );
        },
        (error) => {
          setSubDepartmentsList([]);
        }
      );
    }
  }

  function fetchDesignationsList(sub_department_ids) {
    if (sub_department_ids.length > 0) {
      API.GetMethod("designation?sub_department=" + sub_department_ids).then(
        (resp) => {
          let designations = [];
          resp.data.forEach((designation) => {
            designations = designations.concat(designation["designations"]);
          });
          setDesignationsList(
            customShowornot(
              designations,
              "hasAccess",
              "desc",
              SHOW_OPTIONS_ONLY_HAS_ACCESS.sourcedprofile
            )
          );
        },
        (error) => {
          setDesignationsList([]);
        }
      );
    }
  }

  function handleChangePage(event, newPage) {
    setDataLoaded(false);
    setPage(newPage);
    fetchSourcedProfilesList(
      newPage + 1,
      rowsPerPage,
      encodedSearch,
      searched,
      sortDataDetails
    );
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
    //setDataLoaded(false);
    setRowsPerPage(+event.target.value);
    fetchSourcedProfilesList(
      0,
      event.target.value,
      encodedSearch,
      searched,
      sortDataDetails
    );
  }

  function updatefilterIcon() {
    if (
      (filterForm.values.start_date == null ||
        filterForm.values.start_date == undefined) &&
      (filterForm.values.end_date == null ||
        filterForm.values.end_date == undefined) &&
      filterForm.values.domains.length === 0 &&
      filterForm.values.departments.length === 0 &&
      filterForm.values.sub_departments.length === 0 &&
      filterForm.values.designations.length === 0
    ) {
      setIsFilterApplied(false);
    } else {
      setIsFilterApplied(true);
    }
  }

  async function resetFilters() {
    // filterForm.resetForm();

    filterForm.setFieldValue("selected_domains", []);
    filterForm.setFieldValue("selected_departments", []);
    filterForm.setFieldValue("selected_sub_departments", []);
    filterForm.setFieldValue("selected_designations", []);
    filterForm.setFieldValue("domains", []);
    filterForm.setFieldValue("departments", []);
    filterForm.setFieldValue("sub_departments", []);
    filterForm.setFieldValue("designations", []);
    filterForm.setFieldValue("start_date", null);
    filterForm.setFieldValue("end_date", null);
  }

  const requestSearch = (e) => {
    let lowerCase = CommonMethods.isNullOrUndefined(e.target)
      ? ""
      : e.target.value.toLowerCase();
    setSearched(lowerCase);
    clearTimeout(timeout.current);
    setEncodedSearch(lowerCase);
    setSearchLoader(true);
    timeout.current = setTimeout(() => {
      fetchSourcedProfilesList(
        0,
        rowsPerPage,
        lowerCase,
        lowerCase,
        sortDataDetails,
        () => {
          setPage(0);
        }
      );
    }, 1000);
  };

  async function cancelFilter() {
    setFilterEl(null);
    await filterForm.setValues(filter_form_values);
    // await fetchSourcedProfilesList(0, CommonMethods.DEFAULT_ROWS_PER_PAGE, encodedSearch);
  }

  function cancelSearch() {
    setEncodedSearch(null);
    setSearched("");
    requestSearch("");
  }

  async function clearFilters() {
    //setIsFilterApplied(false);
    await resetFilters();
    setIsFilterApplied(false);
    updatefilterIcon();
    let payLoad = {
      page: 0,
      offset: CommonMethods.DEFAULT_ROWS_PER_PAGE,
      filter: {},
      search: encodedSearch,
    };

    API.PostMethod("webRegistration", payLoad)
      .then((res) => {
        let sourcedProfilesList = res?.data;
        setSourcedProfilesList(
          sourcedProfilesList["candidate_list"]
            ? sourcedProfilesList["candidate_list"]
            : []
        );
        setTotalCount(
          sourcedProfilesList["total_count"]
            ? sourcedProfilesList["total_count"]
            : sourcedProfilesList["candidate_list"]
            ? sourcedProfilesList["candidate_list"]?.length
            : 0
        );
        setIsFilterApplied(false);
      })
      .finally(() => {
        resetSelected();
      });
  }

  function acceptOrRejectProfile(acceptOrRejectProfile) {
    let event_info = CommonMethods.getEventInfo(
      formData.form_id,
      formData.base_path,
      acceptOrRejectProfile ? ACCEPT_BUTTON_VALUE : REJECT_BUTTON_VALUE
    );
    let payLoad = {
      _id: selectedProfile?._id,
      acceptOrRejectProfile: acceptOrRejectProfile,
    };

    API.PostMethod("webRegistration/acceptOrReject", payLoad, event_info).then(
      (res) => {
        setOpenDialog(false);
        let status_details = {
          ...CommonMethods.STATUS_POPUP_DETAILS.success,
          message: res.message,
        };
        status_dialog(status_details, async () => {
          props.onChange();
          await fetchSourcedProfilesList(
            page,
            rowsPerPage,
            encodedSearch,
            "",
            sortDataDetails
          );
          setShowLoader(false);
          window.location.reload();
        });
        // simple_dialog(res.message, () => {
        //   props.onChange();
        //   fetchSourcedProfilesList(page, rowsPerPage, encodedSearch);
        // }
        // );
      }
    );
  }
  // function RejectCandidateComponent(action, candidate) {
  //   setAction(null); setOpenDialog(false); handleStep(0);
  //   // reject_candidate_dialog('true', action, candidate, 0, props);
  // }

  return (
    <div className="mer-width-percentage-100 mer-height-percentage-100">
      <div className="d-flex justify-content-between flex-column mer-height-percentage-100">
        <div>
          <div className="d-flex justify-content-between mer-margin-12 mer-height-35">
            {deviceProperties.isMobileDevice ||
            deviceProperties.isTabletDevice ? (
              ""
            ) : (
              <Typography
                variant="h6"
                className="d-flex align-items-center mer-margin-right-8"
              >
                Sourced Profiles
              </Typography>
            )}
            <div
              className={
                deviceProperties.isMobileDevice ||
                deviceProperties.isTabletDevice
                  ? "mer-width-percentage-100 d-flex justify-content-end"
                  : "d-flex"
              }
            >
              <MultiCandidateSelectMenu
                selectedList={selected}
                type="source"
                onChange={() => {
                  props.onChange();
                  fetchSourcedProfilesList(
                    page,
                    rowsPerPage,
                    encodedSearch,
                    "",
                    sortDataDetails
                  );
                }}
              />
              <IconButton
                title="Clear filters"
                disabled={isFilterApplied ? false : true}
                onClick={clearFilters}
              >
                <BsArrowCounterclockwise className="mer-width-24 mer-height-24" />
              </IconButton>
              {isFilterApplied ? (
                <Badge variant="dot" color="error">
                  <IconButton
                    className="mer-padding-0"
                    title="Filter"
                    onClick={openFilterPanel}
                  >
                    <RiFilter2Line className="mer-width-24 mer-cursor-pointer mer-height-24" />
                  </IconButton>
                </Badge>
              ) : (
                <IconButton
                  className="mer-padding-0"
                  title="Filter"
                  onClick={openFilterPanel}
                >
                  <RiFilter2Line className="mer-cursor-pointer mer-width-24 mer-height-24" />
                </IconButton>
              )}

              <Menu
                id="simple-menu"
                keepMounted
                className={
                  deviceProperties?.isMobileDevice
                    ? "mer-filter-mobile"
                    : deviceProperties?.isTabletDevice
                    ? "mer-filter-tablet"
                    : "mer-filter-desktop"
                }
                anchorEl={filterEl}
                open={Boolean(filterEl)}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
              >
                <div className="mer-min-width-300 max-heght-percentage-100 mer-padding-16">
                  <div className="mer-font-size-12 mer-margin-bottom-8">
                    Apply Filter
                  </div>
                  <form
                    className="d-flex flex-column mer-form"
                    onSubmit={filterForm.handleSubmit}
                  >
                    <div className="d-flex flex-column">
                      <div className="d-flex flex-row mer-margin-bottom-4">
                        <div className="d-flex flex-column mer-margin-right-8">
                          <FormLabel id="demo-select-small">
                            Applied from
                          </FormLabel>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              name="start_date"
                              placeholder="Date"
                              // views={['day']}
                              inputFormat="MM/dd/yyyy"
                              inputVariant="outlined"
                              value={filterForm.values.start_date}
                              //maxDate={new Date()}
                              maxDate={
                                filterForm?.values?.end_date ?? new Date()
                              }
                              onChange={(newValue) => {
                                filterForm.setFieldValue(
                                  `start_date`,
                                  newValue
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  name="start_date"
                                  {...params}
                                  {...TEXT_FIELD_CONFIG.OUTLINED}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                        <div className="d-flex flex-column mer-margin-left-8">
                          <FormLabel id="demo-select-small">
                            Applied to
                          </FormLabel>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              name="end_date"
                              placeholder="Date"
                              // views={['day']}
                              inputFormat="MM/dd/yyyy"
                              inputVariant="outlined"
                              value={filterForm.values.end_date}
                              minDate={filterForm.values.start_date}
                              maxDate={new Date()}
                              onChange={(newValue) => {
                                filterForm.setFieldValue(`end_date`, newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  name="end_date"
                                  {...params}
                                  {...TEXT_FIELD_CONFIG.OUTLINED}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                      </div>
                      <div className="d-flex flex-column mer-margin-bottom-4">
                        <FormControl size="small">
                          <FormLabel id="demo-select-small">Domain</FormLabel>
                          <Autocomplete
                            name="domains"
                            className="mer-autoComplete"
                            multiple={true}
                            filterSelectedOptions
                            {...AUTO_COMPLETE_CONFIG.OUTLINED}
                            options={
                              !CommonMethods.isNullOrUndefined(domains_list) &&
                              domains_list?.length
                                ? domains_list
                                : []
                            }
                            value={filterForm.values?.selected_domains}
                            getOptionLabel={(option) => option.domain_name}
                            getOptionDisabled={(option) => !option.hasAccess}
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option["_id"]}>
                                  {option["domain_name"]}
                                </li>
                              );
                            }}
                            key={(option) => option._id}
                            onChange={async (event, value) => {
                              let domain_ids = [];
                              value.forEach((domain) => {
                                domain_ids.push(domain._id);
                              });
                              filterForm.setFieldValue(
                                "selected_domains",
                                value
                              );
                              handleDepartmentsList(domain_ids);
                            }}
                            renderInput={(params) => (
                              <TextField
                                name="domains"
                                // value={filterForm.values.domains}
                                {...params}
                                placeholder="Type in or select domains"
                                error={
                                  filterForm.touched.domains &&
                                  filterForm.errors.domains
                                    ? true
                                    : false
                                }
                                // onChange={filterForm.handleChange}
                                onBlur={filterForm.handleBlur}
                              />
                            )}
                          />
                        </FormControl>
                      </div>
                      <div className="d-flex flex-column mer-margin-bottom-4">
                        <FormControl size="small">
                          <FormLabel id="demo-select-small">
                            Department
                          </FormLabel>
                          <Autocomplete
                            name="departments"
                            className="mer-autoComplete"
                            // disablePortal disableClearable
                            disabled={
                              filterForm.values.domains?.length > 0
                                ? false
                                : true
                            }
                            multiple
                            filterSelectedOptions
                            {...AUTO_COMPLETE_CONFIG.OUTLINED}
                            options={
                              !CommonMethods.isNullOrUndefined(
                                departments_list
                              ) && departments_list?.length
                                ? departments_list
                                : []
                            }
                            value={filterForm.values?.selected_departments}
                            groupBy={(option) => option.domain_name}
                            // menuprops={{ PaperProps: { sx: { maxHeight: 200 } } }}
                            getOptionLabel={(option) => option.department_name}
                            getOptionDisabled={(option) => !option.hasAccess}
                            noOptionsText={
                              "No departments under selected domains"
                            }
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option["department_id"]}>
                                  {option["department_name"]}
                                </li>
                              );
                            }}
                            key={(option) => option.department_id}
                            onChange={async (event, value) => {
                              let department_ids = [];
                              value.forEach((department) => {
                                department_ids.push(department.department_id);
                              });
                              filterForm.setFieldValue(
                                "selected_departments",
                                value
                              );
                              handleSubDepartmentsList(department_ids);
                              event.preventDefault();
                            }}
                            renderInput={(params) => (
                              <TextField
                                name="departments"
                                // value={filterForm.values.departments}
                                {...params}
                                placeholder="Type in or select departments"
                                error={
                                  filterForm.touched.departments &&
                                  filterForm.errors.departments
                                    ? true
                                    : false
                                }
                                // onChange={filterForm.handleChange}
                                onBlur={filterForm.handleBlur}
                              />
                            )}
                          />
                        </FormControl>
                      </div>
                      <div className="d-flex flex-column mer-margin-bottom-4">
                        <FormControl size="small">
                          <FormLabel id="demo-select-small">
                            Sub Department
                          </FormLabel>
                          <Autocomplete
                            name="sub_departments"
                            className="mer-autoComplete"
                            // disablePortal disableClearable
                            disabled={
                              filterForm.values.departments?.length > 0
                                ? false
                                : true
                            }
                            multiple
                            filterSelectedOptions
                            {...AUTO_COMPLETE_CONFIG.OUTLINED}
                            // menuprops={{ PaperProps: { sx: { maxHeight: 200 } } }}
                            options={
                              !CommonMethods.isNullOrUndefined(
                                sub_departments_list
                              ) && sub_departments_list?.length
                                ? sub_departments_list
                                : []
                            }
                            value={filterForm.values?.selected_sub_departments}
                            groupBy={(option) => option.department_name}
                            getOptionLabel={(option) =>
                              option.sub_department_name
                            }
                            getOptionDisabled={(option) => !option.hasAccess}
                            noOptionsText={
                              "No sub departments under selected departments"
                            }
                            renderOption={(props, option) => {
                              return (
                                <li
                                  {...props}
                                  key={option["sub_department_id"]}
                                >
                                  {option["sub_department_name"]}
                                </li>
                              );
                            }}
                            key={(option) => option.sub_department_id}
                            onChange={async (event, value) => {
                              let sub_department_ids = [];
                              value.forEach((sub_department) => {
                                sub_department_ids.push(
                                  sub_department.sub_department_id
                                );
                              });
                              filterForm.setFieldValue(
                                "selected_sub_departments",
                                value
                              );
                              handleDesignationsList(sub_department_ids);
                            }}
                            renderInput={(params) => (
                              <TextField
                                name="sub_departments"
                                // value={filterForm.values.sub_departments}
                                {...params}
                                placeholder="Type in or select sub departments"
                                error={
                                  filterForm.touched.sub_departments &&
                                  filterForm.errors.sub_departments
                                    ? true
                                    : false
                                }
                                // onChange={filterForm.handleChange}
                                onBlur={filterForm.handleBlur}
                              />
                            )}
                          />
                        </FormControl>
                      </div>
                      <div className="d-flex flex-column mer-margin-bottom-4">
                        <FormControl size="small">
                          <FormLabel id="demo-select-small">
                            Designation
                          </FormLabel>
                          <Autocomplete
                            name="designations"
                            className="mer-autoComplete"
                            disabled={
                              filterForm.values.sub_departments?.length > 0
                                ? false
                                : true
                            }
                            // disablePortal disableClearable
                            multiple
                            filterSelectedOptions
                            {...AUTO_COMPLETE_CONFIG.OUTLINED}
                            // menuprops={{ PaperProps: { sx: { maxHeight: 200 } } }}
                            options={
                              !CommonMethods.isNullOrUndefined(
                                designations_list
                              ) && designations_list?.length
                                ? designations_list
                                : []
                            }
                            value={filterForm.values?.selected_designations}
                            groupBy={(option) => option.sub_department_name}
                            getOptionLabel={(option) => option.designation_name}
                            getOptionDisabled={(option) => !option.hasAccess}
                            noOptionsText={
                              "No designations under selected sub department"
                            }
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option["designation_id"]}>
                                  {option["designation_name"]}
                                </li>
                              );
                            }}
                            key={(option) => option.designation_id}
                            onChange={async (event, value) => {
                              let designation_ids = [];
                              value.forEach((designation) => {
                                designation_ids.push(
                                  designation.designation_id
                                );
                              });
                              filterForm.setFieldValue(
                                "selected_designations",
                                value
                              );
                              filterForm.setFieldValue(
                                "designations",
                                designation_ids
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                name="designations"
                                // value={filterForm.values.designations}
                                {...params}
                                placeholder="Type in or select designations"
                                error={
                                  filterForm.touched.designations &&
                                  filterForm.errors.designations
                                    ? true
                                    : false
                                }
                                // onChange={filterForm.handleChange}
                                onBlur={filterForm.handleBlur}
                              />
                            )}
                          />
                        </FormControl>
                      </div>
                    </div>
                    <div className="d-flex mer-margin-top-16 align-items-center justify-content-end">
                      <Button
                        variant="text"
                        className="textcls"
                        onClick={cancelFilter}
                      >
                        Close
                      </Button>
                      <Button
                        variant="text"
                        className="textcls"
                        onClick={resetFilters}
                      >
                        Reset
                      </Button>
                      <Button variant="text" className="textcls" type="submit">
                        Apply
                      </Button>
                    </div>
                  </form>
                </div>
              </Menu>

              <InputBase
                className="tablesearch mer-margin-left-12 mer-padding-0"
                onChange={requestSearch}
                placeholder="Search"
                value={searched}
                endAdornment={
                  <InputAdornment position="end">
                    {searched.length === 0 ? (
                      <div></div>
                    ) : searchLoader ? (
                      <Box className="d-flex mer-margin-right-10">
                        <CircularProgress size="1.3rem" />
                      </Box>
                    ) : (
                      <MdClear
                        className="mer-height-23 mer-width-23 mer-cursor-pointer iconclr mer-margin-right-10"
                        onClick={cancelSearch}
                      />
                    )}
                  </InputAdornment>
                }
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton className="mer-margin-top-0">
                      <BiSearch className="mer-height-20 mer-width-20" />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </div>
          </div>
          {sourcedProfilesTableLoaded ? (
            <TableContainer
              className={
                sourcedProfilesList?.length === 0 ? "mer-overflow-x-hidden" : ""
              }
              sx={{
                height: `${
                  deviceProperties.height -
                  (deviceProperties.isMobileDevice ? 346 : 180)
                }px`,
                maxHeight: `${
                  deviceProperties.height -
                  (deviceProperties.isMobileDevice ? 346 : 180)
                }px`,
                width: `${
                  deviceProperties.isMobileDevice ||
                  deviceProperties.isTabletDevice
                    ? deviceProperties.width
                    : deviceProperties.width - 290
                }px`,
                maxWidth: `${
                  deviceProperties.isMobileDevice ||
                  deviceProperties.isTabletDevice
                    ? deviceProperties.width
                    : deviceProperties.width - 290
                }px`,
              }}
            >
              {/* sx={{ maxHeight: `${deviceProperties.height - 180}px`}}> */}
              <Table
                sx={{ padding: "0px 10px" }}
                stickyHeader
                aria-label="sticky table"
                size="small"
                className="mer-table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      padding="checkbox"
                      className={
                        deviceProperties?.isMobileDevice ||
                        deviceProperties?.isTabletDevice
                          ? "mer-text-ellipsis"
                          : "mer-text-ellipsis mer-styletheme-table-sticky-header-left-0"
                      }
                    >
                      <Checkbox
                        className="table-header-checkbox"
                        checked={selectAll}
                        indeterminate={
                          selected.length > 0 &&
                          selected.length < sourcedProfilesList.length
                        }
                        onChange={handleSelectAllClick}
                      />
                    </TableCell>
                    <TableCell
                      className={
                        deviceProperties?.isMobileDevice ||
                        deviceProperties?.isTabletDevice
                          ? "mer-text-ellipsis"
                          : "mer-text-ellipsis mer-styletheme-table-sticky-header-left-50"
                      }
                      title="Candidate Name"
                    >
                      <TableSortLabel
                        direction={order}
                        onClick={() => {
                          handleSort("full_name");
                        }}
                      >
                        <span className="mer-text-ellipsis">
                          Candidate Name
                        </span>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className="mer-text-ellipsis" title="Email ID">
                      <TableSortLabel
                        direction={order}
                        onClick={() => {
                          handleSort("email_id");
                        }}
                      >
                        <span className="mer-text-ellipsis">Email ID</span>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      className="mer-text-ellipsi"
                      title="Mobile Number"
                    >
                      <TableSortLabel
                        direction={order}
                        onClick={() => {
                          handleSort("mobile_number");
                        }}
                      >
                        <span className="mer-text-ellipsis">Mobile Number</span>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className="mer-text-ellipsis" title="Domain">
                      <TableSortLabel
                        direction={order}
                        onClick={() => {
                          handleSort("domain_name");
                        }}
                      >
                        <span className="mer-text-ellipsis">Domain</span>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className="mer-text-ellipsis">
                      <TableSortLabel
                        direction={order}
                        onClick={() => {
                          handleSort("department_name");
                        }}
                      >
                        <span className="mer-text-ellipsis">Department</span>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className="mer-text-ellipsis">
                      <TableSortLabel
                        direction={order}
                        onClick={() => {
                          handleSort("sub_department_name");
                        }}
                      >
                        <span className="mer-text-ellipsis">
                          Sub Department
                        </span>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      className="mer-text-ellipsis"
                      title="Designation"
                    >
                      <TableSortLabel
                        direction={order}
                        onClick={() => {
                          handleSort("designation_name");
                        }}
                      >
                        <span className="mer-text-ellipsis">Designation</span>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className="mer-text-ellipsis" title="Applied On">
                      <TableSortLabel
                        direction={order}
                        onClick={() => {
                          handleSort("created_on");
                        }}
                      >
                        <span className="mer-text-ellipsis">Applied On</span>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      className="mer-text-ellipsis mer-styletheme-table-sticky-header-right--10"
                      title="Actions"
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!CommonMethods.isNullOrUndefined(sourcedProfilesList) &&
                    sourcedProfilesList.length > 0 &&
                    sourcedProfilesList.map((sourcedProfile, key) => {
                      return (
                        <TableRow key={key}>
                          <TableCell
                            padding="checkbox"
                            className={
                              deviceProperties?.isMobileDevice ||
                              deviceProperties?.isTabletDevice
                                ? "mer-text-ellipsis"
                                : "mer-text-ellipsis mer-styletheme-table-sticky-body-left-0"
                            }
                          >
                            <Checkbox
                              checked={selected.includes(sourcedProfile._id)}
                              onChange={() =>
                                handleCheckboxClick(sourcedProfile._id)
                              }
                            />
                          </TableCell>
                          <TableCell
                            className={
                              deviceProperties?.isMobileDevice ||
                              deviceProperties?.isTabletDevice
                                ? "mer-text-ellipsis"
                                : "mer-text-ellipsis mer-styletheme-table-sticky-body-left-50"
                            }
                            title={sourcedProfile.full_name}
                          >
                            <div
                              className="sourced-profile-name-highlight"
                              onClick={() => {
                                setSelectedProfile(sourcedProfile);
                                setOpenDialog(true);
                              }}
                            >
                              <Highlight
                                search={highlightText}
                                className="sourced-profile-name-highlight"
                                matchStyle={{ backgroundColor: "#FCF55F" }}
                              >
                                {sourcedProfile.full_name}
                              </Highlight>
                            </div>
                          </TableCell>
                          <TableCell
                            className="mer-text-ellipsis"
                            title={sourcedProfile.email_id}
                          >
                            <Highlight
                              search={highlightText}
                              matchStyle={{ backgroundColor: "#FCF55F" }}
                            >
                              {sourcedProfile.email_id}
                            </Highlight>
                          </TableCell>
                          <TableCell
                            className="mer-text-ellipsis "
                            title={sourcedProfile.mobile_number}
                          >
                            <Highlight
                              search={highlightText}
                              matchStyle={{ backgroundColor: "#FCF55F" }}
                            >
                              {sourcedProfile.mobile_number}
                            </Highlight>
                          </TableCell>
                          <TableCell
                            className="mer-text-ellipsis "
                            title={sourcedProfile.domain_name}
                          >
                            <Highlight
                              search={highlightText}
                              matchStyle={{ backgroundColor: "#FCF55F" }}
                            >
                              {sourcedProfile.domain_name}
                            </Highlight>
                          </TableCell>
                          <TableCell
                            className="mer-text-ellipsis"
                            title={sourcedProfile.department_name}
                          >
                            <Highlight
                              search={highlightText}
                              matchStyle={{ backgroundColor: "#FCF55F" }}
                            >
                              {sourcedProfile.department_name}
                            </Highlight>
                          </TableCell>
                          <TableCell
                            className="mer-text-ellipsis"
                            title={sourcedProfile.sub_department_name}
                          >
                            <Highlight
                              search={highlightText}
                              matchStyle={{ backgroundColor: "#FCF55F" }}
                            >
                              {sourcedProfile.sub_department_name}
                            </Highlight>
                          </TableCell>
                          <TableCell
                            className="mer-text-ellipsis"
                            title={sourcedProfile.designation_name}
                          >
                            <Highlight
                              search={highlightText}
                              matchStyle={{ backgroundColor: "#FCF55F" }}
                            >
                              {sourcedProfile.designation_name}
                            </Highlight>
                          </TableCell>
                          <TableCell
                            className="mer-text-ellipsis"
                            title={DateTimeFormats.DateTimeFormat(
                              sourcedProfile.created_on
                            )}
                          >
                            <Highlight
                              search={highlightText}
                              matchStyle={{ backgroundColor: "#FCF55F" }}
                            >
                              {DateTimeFormats.DateTimeFormat(
                                sourcedProfile.created_on
                              )}
                            </Highlight>
                          </TableCell>
                          <TableCell className="mer-text-ellipsis mer-styletheme-table-sticky-body-right--10">
                            <HiBadgeCheck
                              title="Accept"
                              onClick={() => {
                                setSelectedProfile(sourcedProfile);
                                setAction(1);
                                setOpenDialog(true);
                              }}
                              className="mer-min-height-24 mer-color2 mer-min-width-24 mer-margin-right-8 mer-cursor-pointer"
                            />
                            <IoMdCloseCircleOutline
                              title="Reject"
                              onClick={() => {
                                setSelectedProfile(sourcedProfile);
                                setAction(0);
                                setOpenDialog(true);
                              }}
                              className="mer-min-height-24 mer-color-red mer-cursor-pointer mer-min-width-24"
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              {sourcedProfilesList?.length === 0 ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    height: `${
                      deviceProperties.height -
                      (deviceProperties.isMobileDevice ? 385 : 219)
                    }px`,
                  }}
                >
                  No data to display
                </div>
              ) : (
                <></>
              )}
            </TableContainer>
          ) : (
            // <div className="d-flex mer-fill align-items-center justify-content-center">
            //   Data is loading....
            // </div>
            <SkeletonTableBody />
          )}
        </div>

        <div className="d-flex flex-row mer-width-percentage-100 align-self-end">
          <TablePagination
            labelRowsPerPage={CommonMethods.PAGINATION_ROW_LABEL}
            rowsPerPageOptions={CommonMethods.ROWS_PER_PAGE}
            component="div"
            className="mer-pagination mer-width-percentage-100"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>

        <Dialog
          className="sourced-profile-view-dialog"
          open={openDialog}
          onClose={() => {
            setAction(null);
            setOpenDialog(false);
          }}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle className="mer-padding-0">
            <Card className="mer-theme-dialog-header-bg-tables d-flex flex-row align-items-center justify-content-between mer-margin-bottom-8 mer-padding-left-24 mer-padding-right-24 mer-padding-top-16 mer-padding-bottom-16 ">
              {/* <Typography
                variant="h6"
                className="d-flex align-items-center mer-margin-right-8"
              > */}
                Sourced Profile
              {/* </Typography> */}
              <IoMdCloseCircleOutline
                onClick={() => {
                  setAction(null);
                  setOpenDialog(false);
                  handleStep(0);
                }}
                className="mer-min-height-24 mer-min-width-24 mer-cursor-pointer"
              />
            </Card>
            <div className="d-flex justify-content-center">
              {steps.map((step, index) => (
                <Chip
                  key={index}
                  label={
                    deviceProperties?.isMobileDevice ||
                    deviceProperties?.isTabletDevice
                      ? step?.mobile_label
                      : step?.label
                  }
                  onClick={() => handleStep(index)}
                  title={step?.label}
                  className={activeStep === index ? "active" : ""}
                />
              ))}
            </div>
          </DialogTitle>
          <Divider className="mer-margin-bottom-0"></Divider>
          {/* <DialogContent> */}
          <div className="mer-overflow d-flex flex-grow-1">
            {activeStep === 0 ? (
              <SourcedProfileRegistrationDetails _id={selectedProfile?._id} />
            ) : activeStep === 1 ? (
              <SourcedProfileBasicDetails _id={selectedProfile?._id} />
            ) : activeStep === 2 ? (
              <SourcedProfileAdditionalDetails _id={selectedProfile?._id} />
            ) : (
              <></>
            )}

            {/* {activeStep === 1 ? <SourcedProfileBasicDetails _id={selectedProfileID} /> : <></> }
                  {activeStep === 2 ? <SourcedProfileAdditionalDetails _id={selectedProfileID} /> : <></> } */}
          </div>
          {/* </DialogContent> */}
          <DialogActions>
            {CommonMethods.isNullOrUndefined(action) ? (
              <div className="d-flex justify-content-end align-items-end ">
                <Button
                  variant="contained"
                  color="primary"
                  className="mer-margin-right-12"
                  onClick={() => {
                    confirmation_dialog(
                      Messages?.confirmation_messages?.approve_candidate,
                      () => {
                        acceptOrRejectProfile(true);
                      }
                    );
                  }}
                >
                  {ACCEPT_BUTTON_VALUE}
                </Button>
                <Button
                  variant="contained"
                  className="mer-theme-danger-button"
                  onClick={() => {
                    // confirmation_dialog(Messages?.confirmation_messages?.reject_candidate, () => {
                    //   acceptOrRejectProfile(false);
                    // })
                    // RejectCandidateComponent(null, selectedProfile);
                    setOpenRejectDialog(true);
                  }}
                >
                  {REJECT_BUTTON_VALUE}
                </Button>
              </div>
            ) : action === 1 ? (
              <Button
                variant="contained"
                color="primary"
                className="mer-margin-right-12"
                onClick={() => {
                  confirmation_dialog(
                    Messages?.confirmation_messages?.approve_candidate,
                    () => {
                      acceptOrRejectProfile(true);
                    }
                  );
                }}
              >
                {ACCEPT_BUTTON_VALUE}
              </Button>
            ) : (
              <Button
                variant="contained"
                className="mer-theme-danger-button"
                onClick={() => {
                  // confirmation_dialog(Messages?.confirmation_messages?.reject_candidate, () => {
                  //   acceptOrRejectProfile(false);
                  // })
                  // RejectCandidateComponent(null, selectedProfile);
                  setOpenRejectDialog(true);
                }}
              >
                {REJECT_BUTTON_VALUE}
              </Button>
            )}
          </DialogActions>

          {/* <SourcedProfileRegistrationDetails _id={selectedProfileID} /> */}
        </Dialog>
        <Dialog open={Boolean(ope_reject_dialog)}>
          <DialogTitle className="mer-padding-top-16 mer-padding-left-16 mer-padding-bottom-0">
            Reject candidate -{" "}
            <span className="mer-color2">{selectedProfile?.full_name}</span>
          </DialogTitle>
          <CandidateRejectForm
            _id={selectedProfile?._id}
            type={0}
            onChange={(value) => {
              if (value === true) {
                props.onChange();
                fetchSourcedProfilesList(
                  page,
                  rowsPerPage,
                  encodedSearch,
                  "",
                  sortDataDetails
                );
                setOpenRejectDialog(false);
                setOpenDialog(false);
              } else {
                setOpenRejectDialog(false);
              }
            }}
          />
        </Dialog>
      </div>
    </div>
  );
}

export default SourcedProfilesTable;
