import { useContext, useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useParams, useNavigate } from "react-router-dom";
import * as API from "../../utills/API";
import { LoggedInUserContext } from "../../App";
import { isNullOrUndefined, removeEmptyObjectFromArray } from "../../utills/CommonMethods";
import { SideMenuContext } from '../../App';
import useSideMenu from '../../utills/hooks/useSideMenu';
import CandidateRouting from "../../pages/candidates/CandidateRouting";
import * as sideMenuService from "../../utills/constants/SideMenu";
import useDeviceProperties from "../../utills/hooks/useDeviceProperties";


const useAuth = () => {
    const [isAuth, setIsAuth] = useState(null);
    const { loggedInUser, setLoggedInUser } = useContext(LoggedInUserContext);
    const { sideMenu, setSideMenu } = useContext(SideMenuContext);


    // const updateSideMenu = useSideMenu();

    const location = useLocation();
    const params = useParams();
    let deviceProperties = useDeviceProperties();
 

    const updateSideMenu = (side_menu_api_params, url_params, is_candidate_details, callback) => {
        updateSMenu(side_menu_api_params, is_candidate_details);

        async function updateSMenu(side_menu_api_params, is_candidate_details) {
            let side_menu_response = await sideMenuService.GetForms(side_menu_api_params);
            let disabled_forms = []
            if (is_candidate_details) {
                disabled_forms = await sideMenuService.GetDisabledForms(url_params)
            }

            if (isNullOrUndefined(disabled_forms)) {
                disabled_forms = []
            }

            let disabled_form_ids = disabled_forms.map(form => form['_id']);

            let side_menu_data = side_menu_response['data'].length > 0 ? side_menu_response['data'] : [];

            let forms = removeEmptyObjectFromArray(side_menu_data)

            for (let form of forms) {

                let disabled_form_index = disabled_form_ids.indexOf(form['form_id'])
                form['isDisabled'] = disabled_form_index > -1 ? true : false;

                if (!isNullOrUndefined(form.children)) {
                    for (let sub_form of form.children) {
                        let disabled_subform_index = disabled_form_ids.indexOf(sub_form['form_id'])
                        sub_form['isDisabled'] = disabled_subform_index > -1 ? true : false;
                        for (let key in url_params) {
                            if (!isNullOrUndefined(sub_form.path)) {
                                sub_form.path = sub_form.path.replace(`:${key}`, url_params[key]);

                                if (location.pathname.toString().startsWith(sub_form.base_path)) {
                                    form['expanded'] = true;
                                }
                            }
                            else {
                                sub_form.path = '';
                            }
                        }
                    }
                }

                for (let key in url_params) {
                    if (!isNullOrUndefined(form.path)) {
                        form.path = form.path.replace(`:${key}`, url_params[key])
                    }
                    else {
                        form.path = '';
                    }
                }
            }

            let sideMenuItems = forms.length > 0 ? forms : [];

            setSideMenu({ open: sideMenuItems.length > 0 && !deviceProperties.isMobileDevice ? true : false, sideMenuItems: sideMenuItems });
            if (!isNullOrUndefined(callback)) {
                callback({ open: sideMenuItems.length > 0 && !deviceProperties.isMobileDevice ? true : false, sideMenuItems: sideMenuItems });
            }
        }



    }

    useEffect(() => {
        const isExists = async () => {
            await updateSideMenu(`menu_id=62d7e4aaf938fa0c37551709`, params, true, (sideMenu) => {
                if (sideMenu.sideMenuItems.length) {
                    for (let menuItem of sideMenu.sideMenuItems) {
                        
                        if(!isNullOrUndefined(menuItem.children) && menuItem.children.length) {
                            for(let subMenuItem of menuItem.children) {
                                if (location.pathname.includes(subMenuItem.base_path)) {
                                    setIsAuth(!menuItem.isDisabled);
                                }
                            }
                        }
                        else if (location.pathname.includes(menuItem.base_path)) {
                            setIsAuth(!menuItem.isDisabled);
                        }
                    }
                }
            });
        }
        isExists();
    }, [location]);

    return isAuth;
}

const CandidateAccessGaurd = () => {

    const isAuth = useAuth();
    const navigate = useNavigate();

    if (isNullOrUndefined(isAuth))
        return null;

    return isAuth ? <CandidateRouting/> : navigate(-1);
}

export default CandidateAccessGaurd