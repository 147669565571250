import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";

const CandidateReferral = () => {

    return (
        <div className="d-flex mer-fill flex-column">
            <div className="mer-z-index-1300">
                <Header />
            </div>
            <div className="d-flex flex-column flex-grow-1 mer-overflow-hidden">
                <Outlet />
            </div>
        </div>
    );
};

export default CandidateReferral;
