import React from "react";
import { useState } from "react";
import GlobalSettings from "./GlobalSettings";
import GlobalSettingsData from "./GlobalSettingsData";
function GlobalSettingsView() {
  const [isEdit, setIsEdit] = useState(false);

  return (
    <div className="d-flex flex-grow-1 flex-column mer-overflow mer-overflow-x-hidden">
      {!isEdit ? (
        <GlobalSettingsData onChange={() => setIsEdit(true)} />
      ) : (
        <GlobalSettings onChange={() => setIsEdit(false)} />
      )}
    </div>
  );
}

export default GlobalSettingsView;
