import React, { useState, useRef } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Box,
  CircularProgress,
  IconButton,
} from "@mui/material";

import OtpInput from "react-otp-input";
import * as API from "../../utills/API";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";


const OtpDialog = ({ open, handleClose, metadata, refresh, message }) => {
  const otpForm = useFormik({
    initialValues: {
      otp: "",
    },
    // Not using validation schema as the OTP component it self handles most of them
    validateOnChange: true,
    onSubmit: async (values, helpers) => {
      await handleSubmitOtp();
    },
  });

  const [otherMessage, setMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [inputError, setInputError] = useState(false);
  const timeOutRef = useRef();

  async function handleOtp(value) {
    await otpForm.setFieldValue("otp", value);
    if (errorMessage?.length > 0 || inputError) {
      setErrorMessage(null);
      setInputError(false);
    }
  }

  function generateOTP() {
    resetStates();
    const { email, token } = metadata;
    API.PostWoIMethod(
      "otp/generate",
      {
        email,
      },
      {
        headers: {
          trigger_at: "login",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((data) => {
        // Data recieved so, status  >=200 < 400
        setMessage("OTP re-generated");
        timeOutRef.current = setTimeout(() => {
          setMessage(null);
        }, 4000);
      })
      .catch((error) => {
        setErrorMessage(
          error?.response?.data?.message ?? "Failed to re-generate OTP"
        );
      });
  }

  function handleDialogClose() {
    resetStates();
    !refresh && handleClose();
  }

  function handleSubmitOtp() {
    setLoading(true);
    API.PostWoIMethod(
      "otp/verify",
      {
        otp: otpForm?.values?.otp,
      },
      {
        headers: { Authorization: `Bearer ${metadata?.token}` },
      }
    )
      .then((data) => {
        if (data?.success) {
          window.localStorage.setItem("access_time", new Date().getTime());
          if (!refresh) {
            localStorage.setItem("mer-auth-key", metadata?.token);
            if (metadata?.is_interviewer) {
              navigate("/recruitee/interviewer/candidates");
            } else {
              navigate("/recruitee");
            }
          }
          handleDialogClose();
        }
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data?.message);
        setInputError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function resetStates() {
    otpForm.resetForm();
    setErrorMessage(null);
    setMessage(null);
    clearTimeout(timeOutRef.current);
    setInputError(false);
    setLoading(false);
  }

  return (
    <Dialog open={open} onClose={handleDialogClose}>
      <DialogTitle>
        <div className="d-flex justify-content-between align-items-center gap-2">
          <div>{message}</div>
          <IconButton onClick={handleDialogClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <form onSubmit={otpForm.handleSubmit}>
        <DialogContent sx={{ paddingY: "0px" }}>
          <OtpInput
            value={otpForm?.values?.otp}
            onChange={handleOtp}
            numInputs={6}
            renderSeparator={<span>-</span>}
            skipDefaultStyles={true}
            containerStyle={{
              gap: "4px",
              marginTop: "4px",
              justifyContent: "center",
            }}
            inputType="number"
            renderInput={(props) => (
              <TextField
                variant="filled"
                placeholder="-"
                error={inputError}
                sx={{ width: "40px", height: "40px" }}
                InputProps={{
                  style: {
                    padding: "0px",
                    height: "100%",
                  },
                }}
                inputProps={{
                  ...props,
                  style: { padding: "0px", textAlign: "center" },
                }}
              />
            )}
          />
          <div id="otp-error" className="mer-note my-2">
            {errorMessage}
          </div>
          <div id="regeneration-message" className="my-2 regenerate">
            {otherMessage}
          </div>
        </DialogContent>
        <DialogActions
          sx={{
            paddingY: "16px",
            paddingX: "24px",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="text"
            color="primary"
            type="button"
            className="textcls"
            style={{ textDecoration: "underline", padding: "0px" }}
            onClick={() => {
              resetStates();
              generateOTP();
            }}
          >
            Re-generate OTP
          </Button>

          <Button disabled={loading} type="submit">
            {!loading ? (
              "Validate"
            ) : (
              <Box className="d-flex">
                <CircularProgress size="1.3rem"/>
              </Box>
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default OtpDialog;
