import React from 'react';
import IMAGES from "../../../utills/constants/Images";


function SubmittedDialog() {
    return (
        <div className='d-flex flex-column align-items-center justify-content-center flex-grow-1 mer-padding-16'>
            <img height={"80px"} src={IMAGES.thumbs_up} alt="svg" />
            <div>You have successfully submitted the form. We will get back to you soon.</div>
        </div>
    )
}

export default SubmittedDialog
